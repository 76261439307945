<template>
    <div class="home-banner-user">
        <div class="user-title">
            WELCOME BACK
        </div>
        <div v-if="authUser.user !== null" class="user-info">
            <AvatarImage v-bind:image="authUser.user.avatar" />
            <div class="info-rank" v-bind:class="[ 'rank-' + authUser.user.rank]">
                <IconStar v-if="['system', 'admin', 'mod'].includes(authUser.user.rank) !== true" />
                {{ ['admin', 'mod'].includes(authUser.user.rank) === true ? authUser.user.rank.toUpperCase() : homeGetUserLevel }}
            </div>
            <span v-html="authUser.user.username"></span>
        </div>
        <AuthButton v-else />
        <div class="user-level">
            <div class="level-text">
                To the next level
                <div class="text-value">
                    <span>{{ homeGetUserLevelProgress }}</span>/{{ homeGetLevelXp(homeGetUserLevel + 1) - homeGetLevelXp(homeGetUserLevel) }} XP
                </div>
            </div>
            <div class="level-info">
                <div class="info-current">
                    <IconStar />
                    {{ homeGetUserLevel }}
                </div>
                <div class="info-progress">
                    <div class="progress-inner" v-bind:style="{ 'width': homeGetLevelPercentage + '%' }"></div>
                </div>
                <div class="info-next">
                    <IconStar />
                    {{ homeGetUserLevel >= 100 ? 100 : homeGetUserLevel + 1  }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import IconStar from '@/components/icons/IconStar';
    import AuthButton from '@/components/AuthButton';
    import AvatarImage from '@/components/AvatarImage';

    export default {
        name: 'HomeBannerUser',
        components: {
            IconStar,
            AuthButton,
            AvatarImage
        },
        methods: {
            homeGetLevelXp(level) {
                return Math.pow(level * 4, 2) * 100;
            }
        },
        computed: {
            ...mapGetters([
                'authUser'
            ]),
            homeGetUserLevel() {
                let level = 0;

                if(this.authUser.user !== null) { level = Math.floor(Math.sqrt(this.authUser.user.xp / 1000) / 4) }

                return level >= 100 ? 100 : level;
            },
            homeGetUserLevelProgress() {
                let progress = 0;

                if(this.authUser.user !== null) { 
                    progress = this.homeGetUserLevel === 100 ? this.homeGetLevelXp(this.homeGetUserLevel) : Math.floor(this.authUser.user.xp / 10) - this.homeGetLevelXp(this.homeGetUserLevel); 
                }

                return progress;
            },
            homeGetLevelPercentage() {
                let percentage = 100 / (this.homeGetLevelXp(this.homeGetUserLevel + 1) - this.homeGetLevelXp(this.homeGetUserLevel)) * this.homeGetUserLevelProgress;

                if(percentage >= 100) { percentage = 100; }

                return percentage;
            }
        }
    }
</script>

<style scoped>
    .home-banner-user {
        width: calc(50% - 10px);
        height: 190px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-right: 20px;
        padding: 20px;
        border-radius: 8px;
        background: #212732;
    }

    .home-banner-user .user-title {
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
    }

    .home-banner-user .user-title span {
        color: #fd3b31;
    }

    .home-banner-user .user-info {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
    }

    .home-banner-user .user-info .avatar-image {
        height: 33px;
        width: 33px;
        margin-right: 12px;
        border-radius: 5px;
    }

    .home-banner-user .info-rank {
        height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        padding: 0 5px;
        border-radius: 3px;
        font-size: 10px;
        font-weight: 700;
        color: #ffffff;
        background: #a1aeb5;
    }

    .home-banner-user .info-rank.rank-mod  {
        background: #00c74d;
    }

    .home-banner-user .info-rank.rank-admin {
        background: #fd3b31;
    }

    .home-banner-user .info-rank svg {
        width: 11px;
        margin-right: 5px;
        margin-bottom: 1px;
        fill: #ffffff;
    }

    .home-banner-user .user-level {
        width: 100%;
        padding: 8px 10px 10px 10px;
        border-radius: 8px;
        background: #191e27;
    }

    .home-banner-user .level-text {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #767c8b;
    }

    .home-banner-user .text-value span {
        color: #ffffff;
    }

    .home-banner-user .level-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
    }

    .home-banner-user .info-current,
    .home-banner-user .info-next {
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        border-radius: 3px;
        font-size: 11px;
        font-weight: 700;
        color: #ffffff;
        background: #a1aeb5;
    }

    .home-banner-user .info-next {
        background: #1fb1ff;
    }

    .home-banner-user .info-current svg,
    .home-banner-user .info-next svg {
        width: 11px;
        margin-right: 5px;
        margin-bottom: 1px;
        fill: #ffffff;
    }

    .home-banner-user .info-progress {
        width: calc(100% - 98px);
        height: 8px;
        border-radius: 3px;
        background: #212732;
    }

    .home-banner-user .progress-inner {
        height: 100%;
        border-radius: 3px;
        background: #fd3b31;
    }

    @media only screen and (max-width: 850px) {

        .home-banner-user {
            width: 100%;
            margin-right: 0;
        }

    }
</style>