var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['filter-item-price', { 
    'price-open': _vm.filterDropdown === true 
}]},[_c('div',{staticClass:"price-title"},[_vm._v("PRICE RANGE:")]),_c('div',{staticClass:"price-dropdown"},[_c('button',{staticClass:"dropdown-toggle",on:{"click":function($event){return _vm.filterSetDropdown(!_vm.filterDropdown)}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),(_vm.filterValue === 'all')?_c('div',{staticClass:"toggle-all"},[_vm._v(" "+_vm._s(_vm.filterValue)+" ")]):(_vm.filterValue === '100.00+')?_c('div',{staticClass:"toggle-amount"},[_c('span',[_vm._v(_vm._s(_vm.filterValue.split('.')[0]))]),_vm._v("."+_vm._s(_vm.filterValue.split('.')[1])+" ")]):_c('div',{staticClass:"toggle-amount"},[_c('span',[_vm._v(_vm._s(_vm.filterValue.split('-')[0].split('.')[0]))]),_vm._v("."+_vm._s(_vm.filterValue.split('-')[0].split('.')[1])+" "),_c('span',[_vm._v("- "+_vm._s(_vm.filterValue.split('-')[1].split('.')[0]))]),_vm._v("."+_vm._s(_vm.filterValue.split('-')[1].split('.')[1])+" ")]),_c('IconCaretDown')],1),_c('div',{staticClass:"dropdown-menu"},[_c('div',{staticClass:"menu-inner"},[_c('button',{on:{"click":function($event){return _vm.filterSetValue('all')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_vm._m(0)]),_c('button',{on:{"click":function($event){return _vm.filterSetValue('0.00 - 5.00')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_vm._m(1)]),_c('button',{on:{"click":function($event){return _vm.filterSetValue('5.00 - 25.00')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_vm._m(2)]),_c('button',{on:{"click":function($event){return _vm.filterSetValue('25.00 - 100.00')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_vm._m(3)]),_c('button',{on:{"click":function($event){return _vm.filterSetValue('100.00+')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_vm._m(4)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-value"},[_c('span',[_vm._v("ALL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-value"},[_c('span',[_vm._v("0")]),_vm._v(".00 "),_c('span',[_vm._v(" - 5")]),_vm._v(".00 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-value"},[_c('span',[_vm._v("5")]),_vm._v(".00 "),_c('span',[_vm._v(" - 25")]),_vm._v(".00 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-value"},[_c('span',[_vm._v("25")]),_vm._v(".00 "),_c('span',[_vm._v(" - 100")]),_vm._v(".00 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-value"},[_c('span',[_vm._v("100")]),_vm._v(".00+ ")])
}]

export { render, staticRenderFns }