<template>
    <div class="pagination">
        <button v-on:click="$emit('setPage', 1)" v-bind:disabled="page <= 1">
            <IconChevronLeft />
            <IconChevronLeft />
        </button>
        <button v-on:click="$emit('setPage', page - 1)" v-bind:disabled="page <= 1">
            <IconChevronLeft />
        </button>
        <button>{{page}}</button>
        <button v-on:click="$emit('setPage', page + 1)" v-bind:disabled="page >= Math.ceil(count / countPage)">
            <IconChevronRight />
        </button>
        <button v-on:click="$emit('setPage', Math.ceil(count / countPage)) " v-bind:disabled="page >= Math.ceil(count / countPage)">
            <IconChevronRight />
            <IconChevronRight />
        </button>
    </div>
</template>

<script>
    import IconChevronLeft from '@/components/icons/IconChevronLeft';
    import IconChevronRight from '@/components/icons/IconChevronRight'

    export default {
        name: 'Pagination',
        components: {
            IconChevronLeft,
            IconChevronRight
        },
        props: [
            'page',
            'count',
            'countPage'
        ]
    }
</script>

<style scoped>
    .pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 18px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .pagination button {
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    .pagination button:not([disabled]):hover {
        color: #ffffff;
    }

    .pagination button:last-of-type {
        margin-right: 0;
    }

    .pagination button svg {
        width: 8px;
        fill: #767c8b;
        transition: fill 0.3s ease;
    }

    .pagination button:not([disabled]):hover svg {
        fill: #ffffff;
    }
</style>