<template>
    <div id="app">
        <transition name="fade">
            <AppLoader v-if="socketClient.connected === false || generalSettings === null || (authToken.token !== null && authUser.user === null)" key="loading" />
            <div class="app-page" v-else-if="generalSettings.general.maintenance.enabled === false || (authUser.user !== null && authUser.user.rank === 'admin')" v-bind:class="{ 
                'page-full': chatSidebar === false,
                'page-modals': modalsShow !== null
            }" key="page">
                <Navbar />
                <Chat />
                <main>
                    <transition name="slide-fade" mode="out-in">
                        <router-view />
                    </transition>
                    <Footer />
                </main>

                <Modals />
                <Notifications />
            </div>
            <AppMaintenance v-else />
        </transition>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AppLoader from '@/components/AppLoader';
    import AppMaintenance from '@/components/AppMaintenance';
    import Navbar from '@/components/navbar/Navbar';
    import Chat from '@/components/chat/Chat';
    import Modals from '@/components/modals/Modals';
    import Notifications from '@/components/notifications/Notifications';
    import Footer from '@/components/footer/Footer';

    export default {
        components: {
            AppLoader,
            AppMaintenance,
            Navbar,
            Chat,
            Modals,
            Notifications,
            Footer
        },
        methods: {
            ...mapActions([
                'socketConnectClient'
            ])
        },
        computed: {
            ...mapGetters([
                'chatSidebar', 
                'socketClient', 
                'generalSettings', 
                'authToken', 
                'authUser', 
                'modalsShow'
            ])
        },
        created() {
            //this.socketConnectClient();
        },
        mounted() {
            window.$crisp = [];
            window.CRISP_WEBSITE_ID = '90ab508a-72cb-4875-8697-221c694668dd';
            
            (function() {
                let d = document;
                let s = d.createElement('script');

                s.src = 'https://client.crisp.chat/l.js';
                s.async = 1;
                d.getElementsByTagName('head')[0].appendChild(s);

                window.$crisp.push(['do', 'chat:hide']);
            })();
        }
    }
</script>

<style>
    body .app-loader.fade-leave-active {
        transition: opacity 0.5s ease;
    }

    body .app-loader.fade-leave-active {
        opacity: 0;
    }

    body .app-page {
        width: 100%;
        height: 100%;
    }

    body .app-page.page-modals {
        height: 100%;
        width: 100%;
        position: fixed;
        overflow: hidden;
    }

    body .app-page.fade-enter-active,
    body .app-maintenance.fade-enter-active {
        transition: opacity 0.5s;
    }

    body .app-page.fade-enter-from,
    body .app-maintenance.fade-enter-from {
        opacity: 0;
    }

    body .app-page main {
        width: calc(100% - 300px);
        position: absolute;
        top: 112px;
        left: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    body .app-page.page-full main {
        width: 100%;
        left: 0;
    }

     body .app-page main .slide-fade-enter-active {
        transition: all .3s ease-out;
    }

     body .app-page main .slide-fade-enter {
        opacity: 0;
    }

    @media only screen and (max-width: 1650px) {

        body .app-page main {
            width: 100%;
            left: 0;
        }

    }

    @media only screen and (max-width: 1300px) {

        body .app-page main {
            top: 67px;
        }

    }
</style>
