const state = {
    creditFilterCurrency: 'usd',
    creditDepositData: {
        transactions: null,
        loading: false
    }
}

const getters = {
    creditFilterCurrency: state => state.creditFilterCurrency,
    creditDepositData: state => state.creditDepositData
}

const mutations = {
    credit_set_filter_currency(state, value) {
        state.creditFilterCurrency = value;
    },
    credit_set_deposit_data_transactions(state, transactions) {
        state.creditDepositData.transactions = transactions;
    },
   credit_add_deposit_transaction(state, transaction) {
        state.creditDepositData.transactions.unshift(transaction);
        state.creditDepositData.transactions.sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt); });
    },
    credit_remove_deposit_transaction_last(state) {
        state.creditDepositData.transactions.pop();
    },
    credit_set_deposit_data_loading(state, status) {
        state.creditDepositData.loading = status;
    }
}

const actions = {
    creditSetFilterCurrency({ commit }, value) {
        commit('credit_set_filter_currency', value);
    },
    creditGetDepositDataSocket({ getters, commit, dispatch }, data) {
        if(getters.creditDepositData.loading === true) { return; }
        commit('credit_set_deposit_data_loading', true);

        getters.socketClient.emit('cashier:credit:getDepositData', data, (res) => {
            if(res.success === true) {
                commit('credit_set_deposit_data_transactions', res.transactions);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('credit_set_deposit_data_loading', false);
        });
    },
    creditSendDepositSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'CreditDeposit');

        getters.socketClient.emit('cashier:credit:sendDeposit', data, (res) => {
            if(res.success === true) {
                window.location.href = res.transaction.data.providerUrl;
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const credit = {
    state,
    mutations,
    actions,
    getters
}

export default credit;