<template>
    <div class="leaderboard">
        <div class="leaderboard-countdown">
            <div class="countdown-title">LEADERBOARD ENDS</div>
            <div class="countdown-content">
                <span>{{leaderboardCountdownText.split(':')[0]}}</span>D
                <span>{{leaderboardCountdownText.split(':')[1]}}</span>H
                <span>{{leaderboardCountdownText.split(':')[2]}}</span>M
                <span>{{leaderboardCountdownText.split(':')[3]}}</span>S
            </div>
        </div>
        <div class="leaderboard-podium">

            <LeaderboardPodiumElement v-bind:winner="leaderboardData.loading === true ? null : leaderboardData.leaderboard !== null ? leaderboardData.leaderboard.winners[1] : { user: null, points: 0, prize: 0 }" />
            <LeaderboardPodiumElement v-bind:winner="leaderboardData.loading === true ? null : leaderboardData.leaderboard !== null ? leaderboardData.leaderboard.winners[1] : { user: null, points: 0, prize: 0 }" />
            <LeaderboardPodiumElement v-bind:winner="leaderboardData.loading === true ? null : leaderboardData.leaderboard !== null ? leaderboardData.leaderboard.winners[1] : { user: null, points: 0, prize: 0 }" />

        </div>
        <div class="leaderboard-users">
            <div class="users-header">
                <div class="header-element element-rank">RANK</div>
                <div class="header-element element-user">USER</div>
                <div class="header-element element-wagered">WAGERED</div>
                <div class="header-element element-prize">PRIZE</div>
            </div>
            <div class="users-list">
                <transition name="fade" mode="out-in">
                    <div v-if="leaderboardData.loading === true" class="list-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="leaderboardData.leaderboard !== null" class="list-data" key="data">

                        <LeaderboardUserElement v-for="(winner, index) of leaderboardData.leaderboard.winners.slice(3)" v-bind:key="index" v-bind:pos="index + 4" v-bind:winner="winner" />

                    </div>
                    <div v-else class="list-empty" key="empty">No leaderboard found.</div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import LeaderboardPodiumElement from '@/components/leaderboard/LeaderboardPodiumElement';
    import LeaderboardUserElement from '@/components/leaderboard/LeaderboardUserElement';

    export default {
        name: 'Leaderboard',
        metaInfo: {
            title: 'Leaderboard - RustyRocket.GG'
        },
        data() {
            return {
                leaderboardCountdownRepeater: null,
                leaderboardCountdownText: '00:00:00:00'
            }
        },
        components: {
            LoadingAnimation,
            LeaderboardPodiumElement,
            LeaderboardUserElement
        },
        methods: {
            ...mapActions([
                'leaderboardGetDataSocket'
            ]),
            leaderboardStartCountdown() {
                const endingTime = new Date(new Date(this.leaderboardData.leaderboard.updatedAt).setHours(0,0,0,0)).getTime() + (1000 * 60 * 60 * 24 * Number(this.leaderboardData.leaderboard.duration));
                let timeLeft = Math.floor((endingTime - (new Date().getTime() + this.generalTimeDiff)) / 1000);
                timeLeft = timeLeft <= 0 ? 0 : timeLeft;

                let timeDays = Math.floor(timeLeft / (60 * 60 * 24));
                timeDays = timeDays < 10 ? '0' + timeDays : timeDays;
                let timeHours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
                timeHours = timeHours < 10 ? '0' + timeHours : timeHours;
                let timeMinutes = Math.floor((timeLeft % (60 * 60)) / 60);
                timeMinutes = timeMinutes < 10 ? '0' + timeMinutes : timeMinutes;
                let timeSeconds = Math.floor(timeLeft % 60);
                timeSeconds = timeSeconds < 10 ? '0' + timeSeconds : timeSeconds;

                this.leaderboardCountdownText = timeDays.toString() + ':' + timeHours.toString() + ':' + timeMinutes.toString() + ':' + timeSeconds.toString();

                if(timeLeft <= 0) { this.leaderboardCountdownText = '00:00:00:00'; } 
                else { this.leaderboardCountdownRepeater = requestAnimationFrame(this.leaderboardStartCountdown); }
           }
        },
        computed: {
            ...mapGetters([
                'generalTimeDiff', 
                'leaderboardData'
            ])
        },
        watch: {
            'leaderboardData.leaderboard': {
                deep: true,
                handler() {
                    if(this.leaderboardData.leaderboard !== null) { 
                        cancelAnimationFrame(this.leaderboardCountdownRepeater);
                        this.leaderboardStartCountdown();
                    }
                }
            }
        },
        created() {
            if(this.leaderboardData.loading === false) { this.leaderboardGetDataSocket({}); }
        }
    }
</script>

<style scoped>
    .leaderboard {
        width: 1300px;
        min-height: calc(100vh - 112px);
        padding: 40px;
    }

    .leaderboard .leaderboard-countdown {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .leaderboard .countdown-title {
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
    }

    .leaderboard .countdown-content {
        margin-top: 3px;
        font-size: 20px;
        font-weight: 600;
        color: #fd3b31;
    }

    .leaderboard .leaderboard-podium {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 25px;
    }

    .leaderboard  .leaderboard-users {
        width: 100%;
        margin-top: 40px;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .leaderboard .users-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .leaderboard .header-element {
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .leaderboard .header-element.element-rank {
        width: 10%;
    }

    .leaderboard .header-element.element-user {
        width: 50%;
    }

    .leaderboard .header-element.element-wagered,
    .leaderboard .header-element.element-prize {
        width: 20%;
    }

    .leaderboard .header-element.element-prize {
        display: flex;
        justify-content: flex-end;
    }

    .leaderboard .users-list {
        width: 100%;
        margin-top: 8px;
    }

    .leaderboard .list-loading {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .leaderboard .list-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .leaderboard .list-loading.fade-leave-to {
        opacity: 0;
    }

    .leaderboard .list-data {
        width: 100%;
    }

    .leaderboard .list-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .leaderboard .list-data.fade-enter-active,
    .leaderboard .list-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .leaderboard .list-data.fade-enter-from,
    .leaderboard .list-empty.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1330px) {

        .leaderboard {
            width: 100%;
            padding: 20px 10px;
        }

    }

    @media only screen and (max-width: 900px) {

        .leaderboard  .leaderboard-users {
            padding: 10px;
        }

        .leaderboard  .users-header {
            display: none;
        }

        .leaderboard  .users-list {
            width: 100%;
            margin-top: 0;
        }

    }

    @media only screen and (max-width: 650px) {

        .leaderboard .leaderboard-podium {
            display: grid;            
            grid-template-columns: 50% 50%;
            grid-template-rows: auto auto;
            grid-gap: 15px;
        }

    }
</style>