<template>
  <div ref="battles-spinner" class="battles-spinner">
    <div v-if="['created', 'countdown', 'fairness'].includes(battlesGameData.game.state) === true" class="spinner-info">
      <div v-if="battlesGameData.game.state === 'created'" class="info-waiting">
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
          <path class="fa-secondary" opacity=".4" d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z"/>
          <path class="fa-primary" d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z"/>
        </svg>
        Waiting for Players
      </div>
      <div v-else-if="battlesGameData.game.state === 'countdown'" class="info-countdown">
        Game starting in
        <span>{{ battlesCountdown }}</span>
      </div>
      <div v-else class="info-fairness">
        Waiting for eos block
        <span>#{{ battlesGameData.game.fair.blockNum }}</span>
      </div>
    </div>
    <div v-else class="spinner-game">
      <div v-for="(bet, index) in battlesGetBets" :key="index" class="game-element">
        <transition name="fade" mode="out-in">
          <div v-if="['rolling'].includes(battlesGameData.game.state) === true" class="element-wheel">

            <BattlesReel :ref="'reel-' + (index + 1)" :style="battlesReelStyle" :reel="battlesReels[index + 1]" :pos="battlesReelsPos" :running="battlesRunning" />

          </div>
          <div v-else-if="['completed'].includes(battlesGameData.game.state) === true" :class="['element-completed', { 
            'completed-winner': bet.payout > 0 
          }]">
            <div class="completed-text">{{ bet.payout > 0 ? 'WINNER' : 'LOST' }}</div>
            <div class="completed-avatar">
              <AvatarImage :image="bet.bot ? null : bet.user.avatar" />
            </div>
            <div class="completed-amount">
              <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
              <div class="amount-value">
                <span>{{ generalFormatAmount(bet.payout).split('.')[0] }}</span>.
                {{ generalFormatAmount(bet.payout).split('.')[1] }}
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>        
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import { mapGetters, mapActions } from 'vuex';
  import AvatarImage from '@/components/AvatarImage';
  import BattlesReel from '@/components/battles/BattlesReel';

  export default {
    components: {
      AvatarImage,
      BattlesReel
    },
    mixins: [
      mixins
    ],
    data() {
      return {
        battlesRunning: false,
        battlesCountdownRepeater: null,
        battlesReelsPosRepeater: null,
        battlesCountdown: 0,
        battlesReelsPos: 20,
        battlesReels: {
          1: [],
          2: [],
          3: [],
          4: []
        },
        battlesReelStyle: { transform: 'translateX(0px) translateY(-2416.5px)', transition: 'none' }
      }
    },
    methods: {
      ...mapActions([
        'battlesSendCreateSocket'
      ]),
      battlesGetItemsFormated(items) {
        let pos = 0;

        for(let item of items) {
          pos = pos + item.tickets;

          if(pos < 1000 - 1) { item.color = 'yellow'; }
          else if(pos < 5000 - 1) { item.color = 'red'; }
          else if(pos < 20000 - 1) { item.color = 'green'; }
          else if(pos < 50000 - 1) { item.color = 'purple'; }
          else { item.color = 'blue'; }
        }

        return items;
      },
      battlesGetBoxItems(box) {
        let items = [];

        for(let item of this.battlesGetItemsFormated(box.items)) {
          const count = Math.floor(item.tickets / 1000);
          for(let i = 0; i < (count <= 0 ? 1 : count); i++) { items.push(item); }
        }

        return items;
      },
      battlesGetOutcomeItem(outcome, items) {
        let outcomeItem = null;
        let pos = 0;

        for(const item of this.battlesGetItemsFormated(items)) {
          pos = pos + item.tickets;
          if(outcome <= pos) { outcomeItem = item; break; }
        }

        return outcomeItem;
      },
      battlesStartCountdown() {
        const timeEnding = new Date(this.battlesGameData.game.updatedAt).getTime() + (1000 * 3);
        this.battlesCountdown = Math.round((timeEnding - (new Date().getTime() + this.generalTimeDiff)) / 1000);

        if(this.battlesCountdown <= 0) { this.battlesCountdown = 0; } 
        else { this.battlesCountdownRepeater = requestAnimationFrame(this.battlesStartCountdown); }
      },
      battlesAddReels() {
        let items = this.battlesGetBoxItems(this.battlesGetBoxes[this.battlesGameData.game.bets[0].outcomes.length - 1]);
        this.battlesReels = { 1: [], 2: [], 3: [], 4: [] };

        for(const reel of Object.keys(this.battlesReels)) {
          for(let i = 0; i < 80; i++) { this.battlesReels[reel].push(items[Math.floor(Math.random() * items.length)]); }
        }
      },
      battlesGetReelsPos() {
        const offset = this.$refs['reel-1'][0].$el.getBoundingClientRect().top + (this.$refs['reel-1'][0].$el.getBoundingClientRect().height / 2) - (this.$refs['battles-spinner'].getBoundingClientRect().height / 2) - this.$refs['battles-spinner'].getBoundingClientRect().top;
        const pos = Math.round(Math.abs(offset + 2416.5) / 125) + 20; 

        if(this.battlesReelsPos !== pos) {
          this.battlesReelsPos = pos;
          if(this.battlesRunning === true) { this.soundPlay(this.soundVolume, this.soundTick); }
        }

        this.battlesReelsPosRepeater = requestAnimationFrame(this.battlesGetReelsPos);
      }
    },
    computed: {
      ...mapGetters([
        'soundVolume',
        'soundTick',
        'soundCash',
        'generalTimeDiff',
        'socketSendLoading',
        'authUser',
        'battlesGameData'
      ]),
      battlesGetBets() {
        let bets = [];

        for(let bet = 0; bet < this.battlesGameData.game.playerCount; bet++) {
          const index = this.battlesGameData.game.bets.findIndex((element) => element.slot === bet);

          bets.push(index !== -1 ? this.battlesGameData.game.bets[index] : null);
        }

        return bets;
      },
      battlesGetBoxes() {
        let boxes = [];

        if(this.battlesGameData.game !== null) {
          for(const box of this.battlesGameData.game.boxes) {
            for(let i = 0; i < box.count; i++) { boxes.push(box.box); }
          }
        }

        return boxes;
      }
    },
    watch: {
      'battlesGameData': {
        deep: true,
        handler() {
          if(this.battlesGameData.game.state === 'countdown') {
            this.battlesStartCountdown();
          } else if(this.battlesGameData.game.state === 'rolling') {
            this.battlesAddReels();

            this.$nextTick(() => {
              this.battlesGetReelsPos();
              this.battlesRunning = true;

              this.battlesReelStyle = { transform: 'translateX(0px) translateY(-2416.5px)', transition: 'none' };

              for(const [index, bet] of this.battlesGameData.game.bets.entries()) {
                this.battlesReels[index + 1][60] = this.battlesGetOutcomeItem(bet.outcomes[bet.outcomes.length - 1], this.battlesGetBoxes[bet.outcomes.length - 1].items);
              }

              setTimeout(() => {
                const timeEnding = new Date(this.battlesGameData.game.updatedAt).getTime() + 5000;
                let timeLeft = timeEnding - (new Date().getTime() + this.generalTimeDiff);
                timeLeft = timeLeft > 0 ? timeLeft : 0;

                this.battlesReelStyle = { transform: 'translateX(0px) translateY(-' + (7364 + (105 / 8) * Math.floor(Math.random() * (7 - 1 + 1)) + 1) + 'px)', transition: 'transform ' + timeLeft / 1000 + 's cubic-bezier(0.1, 0, 0.2, 1)' };

                this.battlesReelsSpinTimeout = setTimeout(() => {
                  this.battlesReelStyle = { transform: 'translateX(0px) translateY(-7416.5px)', transition: 'transform 0.25s cubic-bezier(0.1, 0, 0.2, 1)' };
                  
                  cancelAnimationFrame(this.battlesReelsPosRepeater);
                  setTimeout(() => { this.battlesRunning = false; }, 250);
                }, timeLeft + 100);
              }, 250);
            });
          } else if(this.battlesGameData.game.state === 'completed') { 
            this.soundPlay(this.soundVolume, this.soundCash); 
          }
        }
      }
    },
    beforeDestroy() {
      clearTimeout(this.battlesReelsSpinTimeout);
      cancelAnimationFrame(this.battlesReelsPosRepeater);
    }

  }
</script>

<style scoped>
  .battles-spinner {
    width: 100%;
    height: 274px;
    position: relative;
    border-radius: 18px;
    background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    border: 3px solid #212732;
  }

  .battles-spinner:before,
  .battles-spinner:after {
    content: '';
    width: 0; 
    height: 0; 
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 5;
  }

  .battles-spinner:before {
    left: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 11px solid #212732;
  }

  .battles-spinner:after {
    right: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 11px solid #212732;
  }

  .battles-spinner .spinner-info,
  .battles-spinner .spinner-game {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .battles-spinner .info-waiting,
  .battles-spinner .info-countdown,
  .battles-spinner .info-fairness {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
  }

  .battles-spinner .info-waiting svg {
    width: 22px;
    height: 22px;
    margin-bottom: 12px;
    fill: #596076;
    animation: bet_loading_animation 1.5s infinite linear both;
  }

  .battles-spinner .info-countdown span,
  .battles-spinner .info-fairness span {
    font-size: 22px;
    color: #fd3b31;
  }

  .battles-spinner .game-element {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    border-right: 1px solid #212732;
    overflow: hidden;
  }

  .battles-spinner .game-element:first-child {
    border-radius: 15px 0 0 15px;
  }

  .battles-spinner .game-element:last-child {
    border-right: none;
  }

  .battles-game.game-3 .battles-spinner .game-element {
    width: 33.33%;
  }

  .battles-game.game-4 .battles-spinner .game-element {
    width: 25%;
  }

  .battles-game .element-waiting,
  .battles-game .element-ready,
  .battles-game .element-completed {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .battles-game .element-completed {
    background: radial-gradient(50% 50% at 50% 50%, rgba(253, 59, 49, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
  }

  .battles-game .element-completed.completed-winner {
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 199, 77, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
  }

  .battles-game .element-waiting svg {
    width: 20px;
    height: 20px;
    fill: #596076;
    animation: bet_loading_animation 1.5s infinite linear both;
  }


  .battles-game .waiting-text,
  .battles-game .ready-text {
    display: flex;
    align-items: center;
    margin-top: 9px;
    font-size: 14px;
    font-weight: 700;
    color: #596076;
  }

  .battles-game .ready-text {
    color: #00c74d;
  }

  .battles-game .completed-text {
    color: #fd3b31;
  }

  .battles-game .element-completed.completed-winner .completed-text {
    color: #00c74d; 
  }

  .battles-game .avatar-image {
    width: 32px;
    height: 32px;
    margin-top: 6px;
    border-radius: 5px;
  }

  .battles-game .completed-amount {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }

  .battles-game .completed-amount img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .battles-game .amount-value {
    font-size: 12px;
    font-weight: 600;
    color: #bbbfd0;
  }

  .battles-game .amount-value span {
    font-size: 15px;
    font-weight: 800;
    color: #ffffff;
  }

  .battles-game .completed-action {
    height: 35px;
    margin-top: 35px;
  }

  .battles-game button.button-recreate {
    width: 200px;
    height: 100%;
    position: relative;
    padding: 1px;
  }

  .battles-game button.button-recreate:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 170, 109, 0) 0%, #00ffc2 100%);
    clip-path: polygon(7px 0, calc(100% - 7px) 0, 100% 25%, 100% 75%, calc(100% - 7px) 100%, 7px 100%, 0 75%, 0 25%);
  }

  .battles-game button.button-recreate .button-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
    clip-path: polygon(7px 0, calc(100% - 7px) 0, 100% 25%, 100% 75%, calc(100% - 7px) 100%, 7px 100%, 0 75%, 0 25%);
  }

  .battles-game button.button-recreate .button-loading.fade-leave-active {
    transition: opacity 0.1s;
  }

  .battles-game button.button-recreate .button-loading.fade-leave-to {
    opacity: 0;
  }

  .battles-game button.button-recreate .inner-content.fade-enter-active {
    transition: opacity 0.1s;
  }

  .battles-game button.button-recreate .inner-content.fade-enter {
    opacity: 0;
  }

  .battles-game button.button-recreate .inner-content {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 800;
    color: #ffffff;
  }

  .battles-game button.button-recreate .inner-content svg {
    margin-right: 8px;
    fill: #ffffff;
  }

  .battles-spinner .element-wheel {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .battles-spinner .element-seperator {
    width: 1px;
    height: 100%;
    position: relative;
    background: linear-gradient(146deg, rgba(4, 28, 48, 0.35) 0%, rgba(1, 213, 152, 0.35) 100%);
  }

  .battles-game.game-created .battles-spinner .element-seperator {
    background: linear-gradient(146deg, rgba(4, 28, 48, 0.35) 0%, #173246 100%);
  }

  .battles-spinner .inner-element:last-child .element-seperator {
    display: none;
  }

  .battles-spinner .seperator-box {
    width: 35px;
    height: 28px;
    position: absolute;
    top: 50%;
    left: -17px;
    transform: translate(0, -50%);
    padding: 1px;
  }

  .battles-spinner .seperator-box:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 255, 194, 0) 0%, #00ffc2 100%);
    clip-path: polygon(5px 0, calc(100% - 5px) 0, 100% 25%, 100% 75%, calc(100% - 5px) 100%, 5px 100%, 0 75%, 0 25%);
  }

  .battles-game.game-created .battles-spinner .seperator-box:before {
    background: linear-gradient(180deg, rgba(0, 255, 194, 0) 0%, #4c667c 100%);
  }

  .battles-spinner .box-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(50% 50% at 50% 50%, rgba(10, 238, 179, 0.2) 0%, rgba(0, 0, 0, 0) 100%), #051f33;
    clip-path: polygon(5px 0, calc(100% - 5px) 0, 100% 25%, 100% 75%, calc(100% - 5px) 100%, 5px 100%, 0 75%, 0 25%);
  }

  .battles-game.game-created .battles-spinner .box-inner {
    mix-blend-mode: luminosity;
  }

  .battles-spinner .box-inner svg {
    width: 16px;
    height: 16px;
  }

  .battles-game.game-created .battles-spinner .box-inner svg {
    mix-blend-mode: luminosity;
    opacity: 0.8;
  }

  @keyframes bet_loading_animation {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>