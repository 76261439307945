<template>
  <div class="battles-reel">
    <div v-for="(item, index) in reel" :key="index" :class="['reel-element', { 
      'element-active': index === pos 
    }]">
      <img :src="item.item.image" />
      <Transition name="slide-fade">
        <div v-if="index === 60 && running === false" class="element-info">
          <span>{{item.item.name}}</span>
          <div class="info-amount">
            <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
            <div class="amount-value">
              <span>{{ generalFormatAmount(item.item.amountFixed).split('.')[0] }}</span>.
              {{ generalFormatAmount(item.item.amountFixed).split('.')[1] }}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';

  export default {
    mixins: [
      mixins
    ],
    props: {
      reel: Array,
      pos: Number,
      running: Boolean
    }
  }
</script>

<style scoped>
  .battles-reel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .battles-reel .reel-element {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    opacity: 0.25;
  }

  .battles-reel .reel-element:last-child {
    margin-bottom: 0;
  }

  .battles-reel .reel-element.element-active {
    opacity: 1;
  }

  .battles-reel .reel-element img {
    width: 105px;
    transition: all 0.2s ease;
  }

  .battles-reel .reel-element.element-active img {
    transform: scale(1.15);
  }

  .battles-reel .element-info {
    max-width: calc(100% - 115px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
  }

  .battles-reel .element-info.slide-fade-enter-active {
    transition: all 0.3s ease;
  }

  .battles-reel .element-info.slide-fade-enter-from {
    transform: translateX(-15px);
    opacity: 0;
  }

  .battles-reel .element-info span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: #596076;
  }

  .battles-reel .info-amount {
    display: flex;
    align-items: center;
    margin-top: 3px;
  }

  .battles-reel .info-amount img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .battles-reel .amount-value {
    font-size: 11px;
    font-weight: 600;
    color: #bbbfd0;
  }

  .battles-reel .amount-value span {
    font-size: 14px;
    font-weight: 800;
    color: #ffffff;
  }
</style>