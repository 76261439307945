var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"battles-create"},[_c('div',{staticClass:"create-header"},[_c('router-link',{staticClass:"link-back",attrs:{"to":"/battles"}},[_vm._v("GO BACK")]),_c('button',{staticClass:"button-create",attrs:{"disabled":_vm.socketSendLoading !== null},on:{"click":function($event){return _vm.battlesCreateButton()}}},[_vm._v(" CREATE BATTLE "),_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"icons-image"}}),_c('div',{staticClass:"button-value"},[_c('span',[_vm._v(_vm._s(_vm.generalFormatAmount(_vm.battlesGetCost).split('.')[0]))]),_vm._v("."+_vm._s(_vm.generalFormatAmount(_vm.battlesGetCost).split('.')[1])+" ")])])],1),_c('div',{staticClass:"create-filters"},[_c('BattlesFilterMode',{attrs:{"mode":_vm.battlesMode},on:{"setMode":_vm.battlesSetMode}}),_c('div',{staticClass:"filters-actions"},[_c('button',{class:['button-type', { 
        'button-active': _vm.battlesType === 'standard' 
      }],on:{"click":function($event){return _vm.battlesSetType('standard')}}},[_vm._v("STANDARD")]),_c('button',{class:['button-type', { 
        'button-active': _vm.battlesType === 'group' 
      }],on:{"click":function($event){return _vm.battlesSetType('group')}}},[_vm._v("GROUP")])]),_c('div',{staticClass:"filters-cases"},[_vm._v(" TOTAL CASES "),_c('span',[_vm._v(_vm._s(this.battlesSelected.length))])])],1),_c('div',{staticClass:"create-cases"},[_c('button',{staticClass:"button-add",on:{"click":function($event){return _vm.modalsSetShow('BattlesSelect')}}},[_c('div',{staticClass:"button-inner"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","height":"1em","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"d":"M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"}})])]),_c('span',[_vm._v("ADD CASE")])]),_vm._l((_vm.battlesGetBoxes),function(box){return _c('BattlesBoxElement',{key:box._id,attrs:{"box":box}})})],2),_c('div',{staticClass:"create-options"},[_c('div',{staticClass:"options-element element-crazy"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","height":"1em","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M416 398.9c58.5-41.1 96-104.1 96-174.9C512 100.3 397.4 0 256 0S0 100.3 0 224c0 70.7 37.5 133.8 96 174.9c0 .4 0 .7 0 1.1v64c0 26.5 21.5 48 48 48h48V464c0-8.8 7.2-16 16-16s16 7.2 16 16v48h64V464c0-8.8 7.2-16 16-16s16 7.2 16 16v48h48c26.5 0 48-21.5 48-48V400c0-.4 0-.7 0-1.1zM96 256a64 64 0 1 1 128 0A64 64 0 1 1 96 256zm256-64a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"}})]),_vm._m(0),_c('button',{class:['button-toggle', { 
          'button-active': _vm.battlesCrazy === true 
        }],on:{"click":function($event){return _vm.battlesSetCrazy(!_vm.battlesCrazy)}}},[_c('span',{staticClass:"toggle-slider"})])]),_c('div',{staticClass:"options-element element-funding"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","height":"1em","viewBox":"0 0 640 512"}},[_c('path',{attrs:{"d":"M344 24V34.5c6.4 1.2 12.6 2.7 18.2 4.2c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17c-10.9-2.9-21.1-4.9-30.2-5c-7.3-.1-14.7 1.7-19.4 4.4c-2.1 1.3-3.1 2.4-3.5 3c-.3 .5-.7 1.2-.7 2.8c0 .3 0 .5 0 .6c.2 .2 .9 1.2 3.3 2.6c5.8 3.5 14.4 6.2 27.4 10.1l.9 .3c11.1 3.3 25.9 7.8 37.9 15.3c13.7 8.6 26.1 22.9 26.4 44.9c.3 22.5-11.4 38.9-26.7 48.5c-6.7 4.1-13.9 7-21.3 8.8V232c0 13.3-10.7 24-24 24s-24-10.7-24-24V220.6c-9.5-2.3-18.2-5.3-25.6-7.8l0 0c-2.1-.7-4.1-1.4-5.9-2c-12.6-4.2-19.4-17.8-15.2-30.4s17.8-19.4 30.4-15.2c2.6 .9 5 1.7 7.3 2.5c13.6 4.6 23.4 7.9 33.9 8.3c8 .3 15.1-1.6 19.2-4.1c1.9-1.2 2.8-2.2 3.2-2.9c.4-.6 .9-1.8 .8-4.1l0-.2c0-1 0-2.1-4-4.6c-5.7-3.6-14.3-6.4-27.1-10.3l-1.9-.6c-10.8-3.2-25-7.5-36.4-14.4c-13.5-8.1-26.5-22-26.6-44.1c-.1-22.9 12.9-38.6 27.7-47.4c6.4-3.8 13.3-6.4 20.2-8.2V24c0-13.3 10.7-24 24-24s24 10.7 24 24zM40 64c22.1 0 40 17.9 40 40v40 80 40.2c0 17 6.7 33.3 18.7 45.3l51.1 51.1c8.3 8.3 21.3 9.6 31 3.1c12.9-8.6 14.7-26.9 3.7-37.8l-15.2-15.2-32-32c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l32 32 15.2 15.2 0 0 25.3 25.3c21 21 32.8 49.5 32.8 79.2V464c0 26.5-21.5 48-48 48H173.3c-17 0-33.3-6.7-45.3-18.7L28.1 393.4C10.1 375.4 0 351 0 325.5V224 160 104C0 81.9 17.9 64 40 64zm560 0c22.1 0 40 17.9 40 40v56 64V325.5c0 25.5-10.1 49.9-28.1 67.9L512 493.3c-12 12-28.3 18.7-45.3 18.7H400c-26.5 0-48-21.5-48-48V385.1c0-29.7 11.8-58.2 32.8-79.2l25.3-25.3 0 0 15.2-15.2 32-32c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-32 32-15.2 15.2c-11 11-9.2 29.2 3.7 37.8c9.7 6.5 22.7 5.2 31-3.1l51.1-51.1c12-12 18.7-28.3 18.7-45.3V224 144 104c0-22.1 17.9-40 40-40z"}})]),_vm._m(1),_c('button',{class:['button-toggle', { 
          'button-active': _vm.battlesFunding === true 
        }],on:{"click":function($event){return _vm.battlesSetFunding(!_vm.battlesFunding)}}},[_c('span',{staticClass:"toggle-slider"})])]),_c('div',{staticClass:"options-element element-private"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","height":"1em","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"d":"M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"}})]),_vm._m(2),_c('button',{class:['button-toggle', { 
          'button-active': _vm.battlesPrivate === true 
        }],on:{"click":function($event){return _vm.battlesSetPrivate(!_vm.battlesPrivate)}}},[_c('span',{staticClass:"toggle-slider"})])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.battlesFunding === true),expression:"battlesFunding === true"}],staticClass:"create-funding"},[_vm._v(" Set Funding Percentage "),_c('div',{staticClass:"funding-content"},[_vm._v(" "+_vm._s(_vm.battlesFundingPercentage)+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.battlesFundingPercentage),expression:"battlesFundingPercentage"}],style:({ 'background-image': '-webkit-gradient(linear, left top, right top, color-stop('+_vm.battlesFundingPercentage / 100+', #00c74d), color-stop('+_vm.battlesFundingPercentage / 100+', #191e27))' }),attrs:{"type":"range","min":"1","max":"100","step":"1"},domProps:{"value":(_vm.battlesFundingPercentage)},on:{"__r":function($event){_vm.battlesFundingPercentage=$event.target.value}}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element-text"},[_c('span',[_vm._v("Crazy Mode")]),_vm._v(" The team with the lowest amount opened wins. Loser wins! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element-text"},[_c('span',[_vm._v("Partial Slot Funding")]),_vm._v(" Choose to pay a percent of other players slots. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element-text"},[_c('span',[_vm._v("Private Mode")]),_vm._v(" Hide this game from the battles page. Only joinable via link. ")])
}]

export { render, staticRenderFns }