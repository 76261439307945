<template>
  <div :class="['battles-bet-element', { 
    'element-winner': battlesGameData.game.state === 'completed' && bet.payout > 0, 
    'element-lost': battlesGameData.game.state === 'completed' && bet.payout <= 0 
  }]">
    <div class="element-top">

      <div v-if="bet !== null" class="inner-info">
          <div class="info-user">
            <div class="user-avatar">
              <AvatarImage :image="bet.bot ? null : bet.user.avatar" />
            </div>
            <span v-html="bet.bot === true ? 'BOT#' + pos : bet.user.username"></span>
          </div>
          <div class="info-amount">
            <img src="@/assets/img/icons/coins.webp" alt="icons-image" />
            <div class="amount-value">
              <span>{{ generalFormatAmount(battlesGetOutcomeAmount).split('.')[0] }}</span>.{{ generalFormatAmount(battlesGetOutcomeAmount).split('.')[1] }}
            </div>
          </div>
      </div>
      <div v-else class="inner-actions">
        <button @click="battlesIsUserGame === true ? battlesBotButton() : battlesJoinButton()" class="button-join" :disabled="socketSendLoading !== null">
          {{ battlesIsUserGame === true ? 'CALL BOT' : 'JOIN BATTLE' }}
          <div v-if="battlesGameData.game.options.funding > 0 && battlesIsUserGame === false" class="button-bonus">-{{ battlesGameData.game.options.funding }}%</div>
        </button>
      </div>

    </div>
    <div class="element-items">
      <div class="items-list">

        <BattlesItemElement v-for="(item, index) in battlesGetOutcomeItems" :key="index" :item="item" />
          
      </div>
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import { mapGetters, mapActions } from 'vuex';
  import AvatarImage from '@/components/AvatarImage';
  import BattlesItemElement from '@/components/battles/BattlesItemElement';

  export default {
    components: {
      AvatarImage,
      BattlesItemElement
    },
    mixins: [
      mixins
    ],
    props: {
      pos: Number,
      bet: Object
    },
    methods: {
      ...mapActions([
          'notificationShow',
          'battlesSendBotSocket',
          'battlesSendJoinSocket'
      ]),
      battlesGetItemsFormated(items) {
        let pos = 0;

        for(let item of items) {
          pos = pos + item.tickets;

          if(pos < 1000 - 1) { item.color = 'yellow'; }
          else if(pos < 5000 - 1) { item.color = 'red'; }
          else if(pos < 20000 - 1) { item.color = 'green'; }
          else if(pos < 50000 - 1) { item.color = 'purple'; }
          else { item.color = 'blue'; }
        }

        return items;
      },
      battlesBotButton() {
        this.battlesSendBotSocket({ gameId: this.battlesGameData.game._id });
      },
      battlesJoinButton() {
        if(this.authUser.user === null) {
          this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
          return;
        }

        this.battlesSendJoinSocket({ gameId: this.battlesGameData.game._id, slot: this.pos });
      }
    },
    computed: {
      ...mapGetters([
        'socketSendLoading',
        'authUser',
        'battlesGameData'
      ]),
      battlesIsUserGame() {
        return this.authUser.user !== null && this.authUser.user._id === this.battlesGameData.game.bets[0].user._id;
      },
      battlesGetBoxes() {
        let boxes = [];

        if(this.battlesGameData.game !== null) {
          for(const box of this.battlesGameData.game.boxes) {
            for(let i = 0; i < box.count; i++) { boxes.push(box.box); }
          }
        }

        return boxes;
      },
      battlesGetOutcomeItems() {
        let items = [];

        for(const [index, box] of this.battlesGetBoxes.entries()) {
          if(this.bet !== null && (this.battlesGameData.game.state === 'completed' ? this.bet.outcomes : this.bet.outcomes.slice(0, -1))[index] !== undefined) {
            let pos = 0;

            for(const item of this.battlesGetItemsFormated(box.items)) {
              pos = pos + item.tickets;
              if(this.bet.outcomes[index] <= pos) { items.push(item); break; }
            }
          } else { items.push(null); }
        }

        return items;
      },
      battlesGetOutcomeAmount() {
        let amount = 0;

        for(const item of this.battlesGetOutcomeItems) { if(item !== null) { amount = amount + item.item.amountFixed; } }

        return amount;
      }
    }
  }
</script>

<style scoped>
  .battles-bet-element {
    width: calc(50% - 9px);
  }

  .battles-game.game-3 .battles-bet-element {
    width: calc(33.33% - 10px);
  }

  .battles-game.game-4 .battles-bet-element {
    width: calc(25% - 4.5px);
  }

  .battles-bet-element .element-top {
    width: 100%;
    height: 65px;
    margin-bottom: 15px;
    border-radius: 8px;
    background: #1c202c;
  }

  .battles-bet-element .inner-info {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }

  .battles-bet-element .info-user {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
  }

  .battles-bet-element .info-user span {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .battles-bet-element.element-winner .info-user span {
    color: #00c74d;
  }

  .battles-bet-element.element-lost .info-user span {
    color: #fd3b31;
  }

  .battles-bet-element .user-avatar .avatar-image {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 5px;
  }

  .battles-bet-element .info-amount {
    display: flex;
    align-items: center;
  }

  .battles-bet-element .info-amount img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .battles-bet-element .amount-value {
    font-size: 11px;
    font-weight: 600;
    color: #bbbfd0;
  }

  .battles-bet-element .amount-value span {
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
  }

  .battles-bet-element .inner-actions {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .battles-bet-element button.button-join {
    width: 160px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 800;
    color: #ffffff;
    background: #00c74d;
    box-shadow: 0px 2px 0px 0px #00732c;
  }

  .battles-bet-element button.button-join .button-bonus {
    height: 21px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    padding: 0 4px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 700;
    color: #ffffff;
    background: #fd3b31;
  }

  .battles-bet-element .element-items {
    width: 100%;
    padding: 4px 4px 0 4px;
    border-radius: 8px;
    background: #12161d;
  }

  .battles-bet-element .items-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 1250px) {

    .battles-game.game-4 .battles-bet-element {
      width: calc(50% - 3px);
    }

    .battles-game.game-4 .battles-bet-element .element-items {
      display: none;
    }

  }

  @media only screen and (max-width: 1150px) {

    .battles-game.game-3 .battles-bet-element {
      width: calc(50% - 3px);
    }

    .battles-game.game-3 .battles-bet-element .element-items {
      display: none;
    }

  }

  @media only screen and (max-width: 850px) {

    .battles-bet-element {
      width: 100%!important;
    }

    .battles-bet-element .element-items {
      display: none;
    }

  }
</style>