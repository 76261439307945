<template>
    <div ref="cases-spinner" class="cases-spinner" v-bind:class="[ 'spinner-' + casesCount ]">

        <div v-for="i in casesCount" v-bind:key="i" v-bind:ref="'spinner-' + i" class="spinner-element">

            <CasesReel v-bind:ref="'reel-' + i" v-bind:style="casesReelStyle" v-bind:reel="casesReels[i]" v-bind:pos="casesReelsPos" v-bind:running="casesRunning" />

        </div>

    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';
    import CasesReel from '@/components/cases/CasesReel';

    export default {
        name: 'CasesSpinner',
        components: {
            CasesReel
        },
        mixins: [
            mixins
        ],
        data() {
            return {
                casesReelsSpinTimeout: null,
                casesReelsPosRepeater: null,
                casesReelsPos: 20,
                casesReels: {
                    1: [],
                    2: [],
                    3: [],
                    4: []
                },
                casesReelStyle: { transform: 'translateX(2535px) translateY(0px)', transition: 'none' }
            }
        },
        methods: {
            ...mapActions([
                'casesSetRunnning'
            ]),
            casesFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            casesGetOutcomeItem(game) {
                let pos = 0;
                let outcomeItem = null;

                for(const item of this.casesBoxData.box.items) {
                    pos = pos + item.tickets;
                    if(game.outcome <= pos) { outcomeItem = item.item; break; }
                }

                return outcomeItem;
            },
            casesGetReelsPos() {
                const offset = this.casesCount === 1 ?
                                    this.$refs['reel-1'][0].$el.getBoundingClientRect().left + (this.$refs['reel-1'][0].$el.getBoundingClientRect().width / 2) - (this.$refs['spinner-1'][0].getBoundingClientRect().width / 2) - this.$refs['spinner-1'][0].getBoundingClientRect().left :
                                    this.$refs['reel-1'][0].$el.getBoundingClientRect().top + (this.$refs['reel-1'][0].$el.getBoundingClientRect().height / 2) - (this.$refs['spinner-1'][0].getBoundingClientRect().height / 2) - this.$refs['spinner-1'][0].getBoundingClientRect().top;
                const pos = this.casesCount === 1 ? 
                                    Math.round(Math.abs(offset - 2535) / 130) + 20 : 
                                    Math.round(Math.abs(offset + 2450.5) / 125) + 20; 

                if(this.casesReelsPos !== pos) {
                    this.casesReelsPos = pos;
                    if(this.casesRunning === true) { this.soundPlay(this.soundVolume, this.soundTick); }
                }

                this.casesReelsPosRepeater = requestAnimationFrame(this.casesGetReelsPos);
            },
            casesAddReels() {
                let items = this.casesGetItems;
                this.casesReels = { 1: [], 2: [], 3: [], 4: [] };

                for(const reel of Object.keys(this.casesReels)) {
                    for(let i = 0; i < 80; i++) { this.casesReels[reel].push({ item: items[Math.floor(Math.random() * items.length)] }); }
                }
            }
        },
        computed: {
            ...mapGetters([
                'soundVolume',
                'soundTick',
                'soundCash',
                'generalTimeDiff',
                'casesCount',
                'casesRunning',
                'casesGames',
                'casesBoxData'
            ]),
            casesGetItems() {
                let items = [];

                for(let item of this.casesBoxData.box.items) {
                    const count = Math.floor(item.tickets / 1000);

                    for(let i = 0; i < (count <= 0 ? 1 : count); i++) { items.push(item.item); }
                }

                return items;
            }
        },
        watch: {
            'casesCount': {
                handler() {
                    this.casesReelsPos = 20;

                    if(this.casesCount === 1) { this.casesReelStyle = { transform: 'translateX(2535px) translateY(0px)', transition: 'none' }; }
                    else { this.casesReelStyle = { transform: 'translateX(0px) translateY(-2450.5px)', transition: 'none' }; }
                }
            },
            'casesGames': {
                deep: true,
                handler(data, dataOld) {
                    if(this.casesGames.length >= 1) {
                        if(dataOld.length !== 0) { this.casesAddReels(); }
                        this.casesGetReelsPos();

                        for(const [index, game] of this.casesGames.entries()) { 
                            if(this.casesCount === 1) { this.casesReelStyle = { transform: 'translateX(2535px) translateY(0px)', transition: 'none' }; }
                            else { this.casesReelStyle = { transform: 'translateX(0px) translateY(-2450.5px)', transition: 'none' }; }

                            this.casesReels[index + 1][60].item = this.casesGetOutcomeItem(game);

                            setTimeout(() => {
                                const timeEnding = new Date(game.updatedAt).getTime() + 5000;
                                let timeLeft = timeEnding - (new Date().getTime() + (game.demo !== true ? this.generalTimeDiff : 0));
                                timeLeft = timeLeft > 0 ? timeLeft : 0;

                                if(this.casesCount === 1) { 
                                    this.casesReelStyle = { transform: 'translateX(-' + (2612.5 + (105 / 8) * Math.floor(Math.random() * (7 - 1 + 1)) + 1) + 'px) translateY(0px)', transition: 'transform ' + timeLeft / 1000 + 's cubic-bezier(0.1, 0, 0.2, 1)' }; 
                                } else { 
                                    this.casesReelStyle = { transform: 'translateX(0px) translateY(-' + (7364 + (105 / 8) * Math.floor(Math.random() * (7 - 1 + 1)) + 1) + 'px)', transition: 'transform ' + timeLeft / 1000 + 's cubic-bezier(0.1, 0, 0.2, 1)' };
                                }
                                
                                this.casesReelsSpinTimeout = setTimeout(() => {
                                    if(this.casesCount === 1) { 
                                        this.casesReelStyle = { transform: 'translateX(-2665px) translateY(0px)', transition: 'transform 0.25s cubic-bezier(0.1, 0, 0.2, 1)' }; 
                                    } else { 
                                        this.casesReelStyle = { transform: 'translateX(0px) translateY(-7450.5px)', transition: 'transform 0.25s cubic-bezier(0.1, 0, 0.2, 1)' };
                                    }

                                    cancelAnimationFrame(this.casesReelsPosRepeater); 
                                    setTimeout(() => { 
                                        this.casesSetRunnning(false);
                                        this.soundPlay(this.soundVolume, this.soundCash);
                                    }, 250);
                                }, timeLeft + 100);
                            }, 250);
                        }
                    }
                }
            }
        },
        created() {
            this.casesAddReels();
        },
        beforeDestroy() {
            this.casesSetRunnning(false);
            clearTimeout(this.casesReelsSpinTimeout);
            cancelAnimationFrame(this.casesReelsPosRepeater);
        }
    }
</script>

<style scoped>
    .cases-spinner {
        width: 100%;
        height: 220px;
        position: relative;
        display: flex;
        margin-top: 26px;
        border-radius: 18px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
        border: 3px solid #212732;
    }

    .cases-spinner:before {
        content: '';
        width: 0; 
        height: 0; 
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 11px solid #212732;
    }

    .cases-spinner.spinner-2:before,
    .cases-spinner.spinner-3:before,
    .cases-spinner.spinner-4:before {
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 11px solid #212732;
    }

    .cases-spinner:after {
        content: '';
        width: 0; 
        height: 0; 
        position: absolute;
        bottom: 0;
        right: 50%;
        transform: translate(50%, 0);
        border-bottom: 11px solid #212732;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }

    .cases-spinner.spinner-2:after,
    .cases-spinner.spinner-3:after,
    .cases-spinner.spinner-4:after {
        bottom: 50%;
        right: 0;
        transform: translate(0, 50%);
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 11px solid #212732;
    }

    .cases-spinner .spinner-element {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #212732;
        overflow: hidden;
    }

    .cases-spinner .spinner-element:last-child {
        border-right: none;
    }

    .cases-spinner.spinner-2 .spinner-element {
        width: 50%;
    }

    .cases-spinner.spinner-3 .spinner-element {
        width: 33.33%;
    }

    .cases-spinner.spinner-4 .spinner-element {
        width: 25%;
    }
</style>