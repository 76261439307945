<template>
    <div class="battles-overview">
        <div class="overview-header">
            <div class="header-sort">
                <button v-on:click="battlesSetFilterSort('price')" v-bind:class="{ 'button-active': battlesFilterSort === 'price' }">PRICE</button>
                <button v-on:click="battlesSetFilterSort('date')" v-bind:class="{ 'button-active': battlesFilterSort === 'date' }">DATE</button>
            </div>
            <div class="header-active">
                ACTIVE BATTLES <span>{{ battlesData.games.length }}</span> 
            </div>
            <router-link to="/battles/create" class="link-create">CREATE BATTLE</router-link>
        </div>
        <div class="overview-content">
            <transition name="fade" mode="out-in">
                <div v-if="battlesData.loading === true" class="content-loading" key="loading">

                    <div class="loading-placeholder"></div>
                    <div class="loading-placeholder"></div>
                    <div class="loading-placeholder"></div>
                    <div class="loading-placeholder"></div>

                </div>
                <div v-else-if="battlesGetGames.length > 0" class="content-list" key="data">

                    <BattlesGameElement v-for="game of battlesGetGames" v-bind:key="game._id" v-bind:game="game" />

                </div>
                <div v-else class="content-empty" key="empty">There are no games.</div>
            </transition>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BattlesGameElement from '@/components/battles/BattlesGameElement';

    export default {
        name: 'BattlesOverview',
        components: {
            BattlesGameElement
        },
        data() {
            return {
                battlesFilterSort: 'price'
            }
        },
        methods: {
            battlesSetFilterSort(value) {
                this.battlesFilterSort = value;
            }
        },
        computed: {
            ...mapGetters([
                'battlesData'
            ]),
            battlesGetGames() {
                let games = this.battlesData.games;

                if(this.battlesFilterSort === 'price') { games.sort(function(a, b) { return b.amount - a.amount; }); }
                else { games.sort(function(a, b) { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(); }); }

                games = [...games, ...this.battlesData.history];

                return games;
            }
        }
    }
</script>

<style scoped>
    .battles-overview {
        width: 100%;
    }

    .battles-overview .overview-header {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .battles-overview .header-sort {
        display: flex;
        align-items: center;
    }

    .battles-overview .header-sort button {
        height: 44px;
        margin-right: 12px;
        padding: 0 16px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #191e27;
    }

    .battles-overview .header-sort button:last-of-type {
        margin-right: 0;
    }

    .battles-overview .header-sort button:hover {
        color: #ffffff;
    }

    .battles-overview .header-sort button.button-active {
        color: #fd3b31;
        background: #252129;
        box-shadow: 0px 0px 0px 1px #59272b;
    }

    .battles-overview .header-active {
        width: calc(100% - 308px);
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 600;
        color: #596076;
    }

    .battles-overview .header-active span {
        margin-left: 4px;
        color: #ffffff;
    }

    .battles-overview a.link-create {
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        box-shadow: 0px 2px 0px 0px #00732c;
    }

    .battles-overview .overview-content {
        width: 100%;
        margin-top: 26px;
    }

    .battles-overview .content-loading,
    .battles-overview .content-list {
        width: 100%;
    }

    .battles-overview .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .battles-overview .content-loading.fade-leave-to {
        opacity: 0;
    }

    .battles-overview .loading-placeholder {
        width: 100%;
        height: 120px;
        position: relative;
        margin-top: 16px;
        border-radius: 5px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
        overflow: hidden;
    }

    .battles-overview .loading-placeholder:after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        animation-name: loading_animation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        background: linear-gradient(to right, #ffffff00 0%, rgba(255, 255, 255, .1) 50%, #ffffff00 100%);
    }

    .battles-overview .loading-placeholder:first-child {
        margin-top: 0;
    }

    .battles-overview .content-empty {
        width: 100%;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #626c7e;
    }

    .battles-overview .content-list.fade-enter-active,
    .battles-overview .content-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .battles-overview .content-list.fade-enter-from,
    .battles-overview .content-empty.fade-enter-from {
        opacity: 0;
    }

    @keyframes loading_animation {
        0% { transform: translateX(-100%); }
        50% { transform: translateX(100%); }
        100% { transform: translateX(100%); }
    }

    @media only screen and (max-width: 600px) {

        .battles-overview .header-active {
            width: auto;
        }

        .battles-overview a.link-create {
           width: 100%;
           margin-left: 0;
           margin-top: 8px;
        }

    }
</style>
