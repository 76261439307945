<template>
    <div class="mines-game">
        <div class="game-result">
            <transition name="slide-fade">
                <div v-if="minesData.game !== null && minesData.game.state === 'completed' && minesData.game.payout > 0" class="result-inner">
                    <div class="inner-multiplier">x{{parseFloat(minesGetMultiplier(minesData.game)).toFixed(2)}}</div>
                    <div class="inner-won">
                        YOU WON
                        <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                        <div class="won-value">
                            <span>{{generalFormatAmount(minesData.game.amount * minesGetMultiplier(minesData.game)).split('.')[0]}}</span>.{{generalFormatAmount(minesData.game.amount * minesGetMultiplier(minesData.game)).split('.')[1]}}
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="game-inner">
            <div class="inner-tiles">

                <MinesTile v-for="tile in 25" v-bind:key="tile" v-bind:tile="tile - 1" />

            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters } from 'vuex';
    import MinesTile from '@/components/mines/MinesTile';

    export default {
        name: 'MinesGame',
        components: {
            MinesTile
        },
        mixins: [
            mixins
        ],
        computed: {
            ...mapGetters([
                'minesData'
            ])
        }
    }
</script>

<style scoped>
    .mines-game {
        width: calc(100% - 380px);
        position: relative;
        padding-left: 16px;
    }

    .mines-game .game-result {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    .mines-game .game-result .slide-fade-enter-active {
        transition: all 0.3s;
    }

    .mines-game .game-result .slide-fade-enter {
        transform: translateY(20px);
        opacity: 0;
    }

    .mines-game .result-inner {
        width: 230px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: #0f2624;
        border: 2px solid #00c74d;
    }

    .mines-game .inner-multiplier {
        font-size: 38px;
        font-weight: 700;
        color: #00C74d;
    }

    .mines-game .inner-won {
        display: flex;
        align-items: center;
        margin-top: 8px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
    }

    .mines-game .inner-won img {
        width: 24px;
        height: 24px;
        margin-left: 10px;
    }

    .mines-game .won-value {
        margin-left: 8px;
        font-size: 12px;
        font-weight: 700;
        color: #00C74d;
    }

    .mines-game .won-value span {
        font-size: 15px;
        font-weight: 700;
        color: #00C74d;
    }

    .mines-game .game-inner {
        width: 100%;
        height: 700px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        background: #11141f;
    }

    .mines-game .inner-tiles {
        width: 580px;
        height: 580px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    @media only screen and (max-width: 1100px) {

        .mines-game {
            width: 100%;
            padding-left: 0;
        }

        .mines-game .game-inner {
            height: 480px;
        }

        .mines-game .inner-tiles {
            width: 440px;
            height: 440px;
            display: flex;
            flex-wrap: wrap;
        }

    }

    @media only screen and (max-width: 550px) {

        .mines-game .result-inner {
            width: 200px;
            height: 120px;
        }

        .mines-game .inner-multiplier {
            font-size: 34px;
            font-weight: 700;
            color: #00C74d;
        }

        .mines-game .game-inner {
            height: 405px;
        }

        .mines-game .inner-tiles {
            width: 365px;
            height: 365px;
            display: flex;
            flex-wrap: wrap;
        }

    }

    @media only screen and (max-width: 425px) {

        .mines-game .result-inner {
            width: 180px;
            height: 110px;
        }

        .mines-game .inner-multiplier {
            font-size: 30px;
            font-weight: 700;
            color: #00C74d;
        }

        .mines-game .game-inner {
            height: 410px;
        }

        .mines-game .game-inner {
            height: 370px;
        }

        .mines-game .inner-tiles {
            width: 315px;
            height: 315px;
            display: flex;
            flex-wrap: wrap;
        }

    }

    @media only screen and (max-width: 375px) {

        .mines-game .game-inner {
            height: 330px;
        }

        .mines-game .inner-tiles {
            width: 290px;
            height: 290px;
            display: flex;
            flex-wrap: wrap;
        }

    }
</style>
