const state = {
    cryptoDepositData: {
        addresses: null,
        transactions: null,
        loading: false
    }
}

const getters = {
    cryptoDepositData: state => state.cryptoDepositData
}

const mutations = {
    crypto_set_deposit_data(state, data) {
        state.cryptoDepositData.addresses = data.addresses;
        state.cryptoDepositData.transactions = data.transactions;
    },
    crypto_set_deposit_data_loading(state, status) {
        state.cryptoDepositData.loading = status;
    },
    crypto_add_deposit_transaction(state, transaction) {
        state.cryptoDepositData.transactions.unshift(transaction);
    },
    crypto_remove_last_deposit_transaction(state, index) {
        state.cryptoDepositData.transactions.pop();
    }
}

const actions = {
    cryptoSocketTransaction({ getters, commit, dispatch }, data) {
        if(data.transaction.state === 'completed') {
            if(getters.cryptoDepositData.transactions !== null) {
                commit('crypto_add_deposit_transaction', data.transaction);
                if(getters.cryptoDepositData.transactions.length > 15) { commit('crypto_remove_last_deposit_transaction'); }
            }

            dispatch('notificationShow', { type: 'success', message: 'Your ' + data.transaction.data.currency + ' deposit has been confirmed. We have added ' + parseFloat(Math.floor(data.transaction.amount / 10) / 100).toFixed(2) + ' to your balance.' });
        } else {
            dispatch('notificationShow', { type: 'error', message: 'Your ' + data.transaction.data.currency + ' deposit has been failed. Your deposit amount was lower then the minimum required amount.' });
        }
    },
    cryptoGetDepositDataSocket({ getters, commit, dispatch }, data) {
        if(getters.cryptoDepositData.loading === true) { return; }
        commit('crypto_set_deposit_data_loading', true);

        getters.socketClient.emit('cashier:crypto:getDepositData', data, (res) => {
            if(res.success === true) {
                commit('crypto_set_deposit_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('crypto_set_deposit_data_loading', false);
        });
    }
}

const crypto = {
    state,
    mutations,
    actions,
    getters
}

export default crypto;
