<template>
    <div v-if="casesBoxData.box !== null && casesBoxData.loading === false" class="cases-box">
        <div class="box-header">
            <router-link class="link-back" to="/cases">
                GO BACK
            </router-link>
            <div class="header-info">
                <img v-bind:src="casesBoxData.box.image" />
                {{ casesBoxData.box.name }}
            </div>
            <router-link class="link-fair" to="/fair/cases">
                <IconFairness />
                FAIRNESS
            </router-link>
        </div>
        <CasesSpinner />
        <CasesControls />
        <div class="box-items">
            <div class="items-title">ITEMS IN THIS CASE</div>
            <div class="items-content">
                <CasesItemElement v-for="item of casesBoxData.box.items" v-bind:key="item._id" v-bind:item="item" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconFairness from '@/components/icons/IconFairness';
    import CasesSpinner from '@/components/cases/CasesSpinner';
    import CasesControls from '@/components/cases/CasesControls';
    import CasesItemElement from '@/components/cases/CasesItemElement';

    export default {
        name: 'CasesBox',
        components: {
            IconFairness,
            CasesSpinner,
            CasesControls,
            CasesItemElement
        },
        methods: {
            ...mapActions([
                'casesSetCount',
                'casesSetGames',
                'casesGetBoxDataSocket'
            ])
        },
        computed: {
            ...mapGetters([
                'socketCases',
                'casesBoxData'
            ])
        },
        created() {
            const boxId = this.$route.params.boxId;

            if((this.casesBoxData.box === null || this.casesBoxData.box._id !== boxId) && this.casesBoxData.loading === false) {
                this.casesGetBoxDataSocket({ boxId: this.$route.params.boxId });
            }
        },
        beforeDestroy() {
            this.casesSetCount(1);
            this.casesSetGames([]);
        }
    }
</script>

<style scoped>
    .cases-box {
        width: 100%;
    }

    .cases-box .box-header {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-content: space-between;
        align-items: flex-end;
    }

    .cases-box a.link-back,
    .cases-box a.link-fair {
        height: 44px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #191e27;
    }

    .cases-box a.link-back:hover,
    .cases-box a.link-fair:hover {
        color: #ffffff;
    }

    .cases-box a.link-fair svg {
        width: 16px;
        margin-right: 8px;
        fill: #767c8b;
        transition: fill 0.3s ease;
    }

    .cases-box a.link-fair:hover svg {
        fill: #ffffff;
    }

    .cases-box .header-info {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
    }

    .cases-box .header-info img {
        height: 60px;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .cases-box .box-items {
        width: 100%;
        margin-top: 35px;
    }

    .cases-box .items-title {
        font-size: 15px;
        font-weight: 600;
        color: #626c7e;
    }

    .items-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
    }

    @media only screen and (max-width: 700px) {

        .cases-box .box-header {
            grid-template-columns: repeat(2, auto);
        }

        .cases-box a.link-back {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
        }

        .cases-box a.link-fair {
            grid-column: 2 / 2;
            grid-row: 1 / 1;
        }

        .cases-box .header-info {
            grid-column: 1 / 3;
            grid-row: 2 / 2;
            margin-top: 25px;
            padding-top: 65px;
        }

    }
</style>