var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"towers-row",class:{
    'row-medium': _vm.towersGetRisk === 'medium',
    'row-active': _vm.towersData.game !== null && _vm.towersData.game.state === 'created' && _vm.towersData.game.revealed.length === _vm.row,
    'row-revealed': _vm.towersData.game !== null && _vm.towersData.game.revealed[_vm.row] !== undefined
}},_vm._l((_vm.towersGetTilesCount),function(tile){return _c('button',{key:tile,staticClass:"row-button",class:{
        'button-won': _vm.towersData.game !== null && _vm.towersData.game.revealed[_vm.row] !== undefined && _vm.towersData.game.revealed[_vm.row].tile === (tile - 1) && _vm.towersData.game.revealed[_vm.row].row[tile - 1] === 'coin',
        'button-lose': _vm.towersData.game !== null && _vm.towersData.game.revealed[_vm.row] !== undefined && _vm.towersData.game.revealed[_vm.row].tile === (tile - 1) && _vm.towersData.game.revealed[_vm.row].row[tile - 1] === 'lose'
    },attrs:{"disabled":_vm.towersData.game === null || _vm.towersData.game.state !== 'created' || _vm.towersData.game.revealed.length !== _vm.row},on:{"click":function($event){return _vm.towersRevealButton(tile - 1)}}},[(_vm.towersData.game === null || (_vm.towersData.game !== null && (_vm.towersData.game.state === 'created' || (_vm.towersData.game.state === 'completed' && _vm.towersData.game.deck[_vm.row][tile - 1] === 'coin'))))?_c('div',{staticClass:"button-won"},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_c('div',{staticClass:"won-amount"},[_c('span',[_vm._v(_vm._s(_vm.generalFormatAmount(_vm.towersGetRowCashout).split('.')[0]))]),_vm._v("."+_vm._s(_vm.generalFormatAmount(_vm.towersGetRowCashout).split('.')[1])+" ")])]):_c('div',{staticClass:"button-lose"},[_c('img',{attrs:{"src":require("@/assets/img/icons/bomb.svg"),"alt":"bomb"}})])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }