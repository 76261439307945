export default {
    methods: {
        soundPlay(volume, sound) {
            sound.volume = volume;
            sound.currentTime = 0;

            sound.play()
                    .then(() => {})
                    .catch((err) => {});    
        }
    }
};