<template>
    <div class="modal-battles-select">
        <div class="select-filters">

            <BattlesFilterSearch />

        </div>
        <div class="select-close">
            <button v-on:click="modalsSetShow(null)">
                <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0005 1.22222L9.77827 0L5.50019 4.27808L1.22222 0.000111474L0 1.22233L4.27797 5.5003L0.000501987 9.77776L1.22272 11L5.50019 6.72252L9.77776 11.0001L11 9.77788L6.72241 5.5003L11.0005 1.22222Z" />
                </svg>
                CLOSE
            </button>
        </div>
        <div class="select-content">
            <div class="content-list">
                <transition name="fade" mode="out-in">
                    <div v-if="battlesBoxesData.loading === true" class="list-loading" key="loading">

                        <LoadingAnimation />

                    </div>
                    <div v-else-if="battlesGetBoxes.length > 0" class="list-data" key="data">

                        <BattlesBoxElement v-for="box of battlesGetBoxes" v-bind:key="box._id" v-bind:box="box" />

                    </div>
                    <div v-else class="list-empty" key="empty">There are no cases.</div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import BattlesFilterSearch from '@/components/battles/BattlesFilterSearch.vue';
    import BattlesBoxElement from '@/components/battles/BattlesBoxElement';

    export default {
        name: 'ModalBattlesSelect',
        components: {
            LoadingAnimation,
            BattlesFilterSearch,
            BattlesBoxElement
        },
        methods: {
            ...mapActions([
                'modalsSetShow',
                'battlesGetBoxesDataSocket'
            ])
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'battlesFilterSearch',
                'battlesBoxesData'
            ]),
            battlesGetBoxes() {
                let boxes = [];

                for(const box of this.battlesBoxesData.boxes) {
                    if(box.name.toLowerCase().includes(this.battlesFilterSearch.toLowerCase().trim()) === true) {
                        boxes.push(box);
                    }
                }

                return boxes;
            },
        },
        created() {
            this.battlesGetBoxesDataSocket({});
        }
    }
</script>

<style scoped>
    .modal-battles-select {
        width: 1020px;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 60px 0 10px 0;
        border-radius: 8px;
        background: #222635;
    }

    .modal-battles-select .select-filters {
        width: calc(100% - 125px);
        position: absolute;
        top: 8px;
        left: 12px;
    }

    .modal-battles-select .select-close {
        position: absolute;
        top: 17px;
        right: 12px;
    }

    .modal-battles-select .select-close button {
        height: 27px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #191e27;
    }

    .modal-battles-select .select-close button:hover {
        color: #ffffff;
    }

    .modal-battles-select .select-close button svg {
        margin-right: 8px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .modal-battles-select .select-close button:hover svg {
        fill: #ffffff;
    }

    .modal-battles-select .select-content {
        width: 100%;
        padding: 0 12px;
        background: #1c202c;
    }

    .modal-battles-select .content-list {
        width: 100%;
        margin-top: 12px;
    }

    .modal-battles-select .list-loading,
    .modal-battles-select .list-data {
        width: 100%;
        height: 480px;
        display: flex;
        flex-wrap: wrap;
    }

    .modal-battles-select .list-data {
        overflow-y: scroll;
    }

    .modal-battles-select .list-loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-battles-select .list-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .modal-battles-select .list-loading.fade-leave-to {
        opacity: 0;
    }

    .modal-battles-select .list-empty {
        width: 100%;
        height: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        color: #626c7e;
    }

    .modal-battles-select .list-data.fade-enter-active,
    .modal-battles-select .list-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .modal-battles-select .list-data.fade-enter-from,
    .modal-battles-select .list-empty.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1040px) {

        .modal-battles-select {
            width: calc(100vw - 20px);
        }

    }

</style>