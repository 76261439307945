const authHandleToken = async(store, to, next) => {
  if(to.query.token) {
    await store.dispatch('authExchangeToken', { authToken: to.query.token, authType: to.query.type });

    if(to.query.type === 'link') { 
      return next({ name: 'ProfileSettings', query: {} }); 
    } else {
      return next({ name: to.name, query: {} });
    }
  }
}

const authHandleAuth = async(store, to) => {
  const { user, loading } = store.getters.authUser;

  if(store.getters.authToken.token && !user && !loading) {
    await store.dispatch('authGetUser');

    if(user && !user.agreed) {
      store.dispatch('modalsSetShow', 'Agree');
    }
  }
}

const authHandleCode = (store, to) => {
  const codeReferral = to.query.ref || localStorage.getItem('ref-code');
  const { user } = store.getters.authUser;

  if(codeReferral && user && user.agreed) {
    localStorage.removeItem('ref-code');

    store.dispatch('modalsSetData', { code: codeReferral });
    store.dispatch('modalsSetShow', 'Redeem');
  } else if(codeReferral) {
    localStorage.setItem('ref-code', codeReferral);
  }
}

const authRequiresUser = (store, to) => {
  return to.matched.some((record) => record.meta.auth) && !store.getters.authUser.user; 
}

const authRequiresAdmin = (store, to) => {
  return to.matched.some((record)=> record.meta.admin) && store.getters.authUser.user.rank !== 'admin';
}

export default {
  authHandleToken,
  authHandleAuth,
  authHandleCode,
  authRequiresUser,
  authRequiresAdmin
};