<template>
    <div class="cases-filter-search">
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.2895 15.7606L15.9977 12.4688C16.5962 11.2718 16.9952 9.87531 16.9952 8.4788C16.9952 3.79052 13.2047 0 8.51641 0C3.82812 0 0.0375977 3.79052 0.0375977 8.4788C0.0375977 13.1671 3.82812 16.9576 8.51641 16.9576C9.91292 16.9576 11.3094 16.5586 12.5064 15.9601L15.7982 19.2519C16.7957 20.2494 18.292 20.2494 19.2895 19.2519C20.1872 18.2544 20.1872 16.7581 19.2895 15.7606ZM8.51641 14.9626C4.92538 14.9626 2.03261 12.0698 2.03261 8.4788C2.03261 4.88778 4.92538 1.99501 8.51641 1.99501C12.1074 1.99501 15.0002 4.88778 15.0002 8.4788C15.0002 12.0698 12.1074 14.9626 8.51641 14.9626Z" />
        </svg>
        <input v-on:change="casesSetFilterSearch(casesSearch)" v-model="casesSearch" type="text" placeholder="Search for cases..." />
    </div>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'CasesFilterSearch',
        data() {
            return  {
                casesSearch: ''
            }
        },
        methods: {
            ...mapActions([
                'casesSetFilterSearch'
            ])
        },
        beforeDestroy() {
            this.casesSetFilterSearch('');
        }
    }
</script>

<style scoped>
    .cases-filter-search {
        width: 300px;
        position: relative;
    }

    .cases-filter-search svg {
        width: 17px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
        fill: #596076;
    }

    .cases-filter-search input {
        width: 100%;
        height: 44px;
        padding: 0 16px 0 49px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        background: #212732;
    }

    .cases-filter-search input::placeholder {
        font-size: 14px;
        font-weight: 500;
        color: #596076;
    }

    @media only screen and (max-width: 950px) {

        .cases-filter-search  {
            width: 100%;
        }

    }
</style>