const state = {
    blackjackDealing: false,
    blackjackData: {
        game: null,
        loading: false
    }
}

const getters = {
    blackjackDealing: state => state.blackjackDealing,
    blackjackData: state => state.blackjackData
}

const mutations = {
    blackjack_set_dealing(state, status) {
        state.blackjackDealing = status;
    },
    blackjack_set_data_game(state, game) {
        state.blackjackData.game = game;
    },
    blackjack_set_data_loading(state, status) {
        state.blackjackData.loading = status;
    }
}

const actions = {
    blackjackClearGame({ commit }) {
        commit('blackjack_set_data_game', null);
    },
    blackjackGetDataSocket({ getters, commit, dispatch }, data) {
        if(getters.blackjackData.loading === true) { return; }
        commit('blackjack_set_data_loading', true);

        getters.socketClient.emit('blackjack:getData', data, (res) => {
            if(res.success === true) {
                commit('blackjack_set_data_game', res.game);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('blackjack_set_data_loading', false);
        });
    },
    blackjackSendCreateSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'BlackjackCreate');

        getters.socketClient.emit('blackjack:sendCreate', data, (res) => {
            if(res.success === true) {
                const playerCards = res.game.playerStack.cards;
                const dealerCards = res.game.dealerStack.cards;

                res.game.playerStack.cards = [];
                res.game.dealerStack.cards = [];

                commit('blackjack_set_dealing', true);
                commit('blackjack_set_data_game', res.game);

                for(const index in playerCards) {
                    setTimeout(() => {
                        res.game.playerStack.cards.push(playerCards[index]);
                        commit('blackjack_set_data_game', res.game);

                        if(getters.soundBlackjack === 1) {
                            setTimeout(() => {
                                getters.soundBlackjackFlip.volume = getters.soundVolume;
                                getters.soundBlackjackFlip.play();
                            }, 300);
                        }

                        setTimeout(() => {
                            res.game.dealerStack.cards.push(dealerCards[index]);

                            commit('blackjack_set_data_game', res.game);

                            if(dealerCards[index].suit !== 'hidden') {
                                if(getters.soundBlackjack === 1) {
                                    setTimeout(() => {
                                        getters.soundBlackjackFlip.volume = getters.soundVolume;
                                        getters.soundBlackjackFlip.play();
                                    }, 300);
                                }
                            }
                        }, 500);
                    }, index * 1000);
                }

                if(res.game.state === 'completed') {
                    setTimeout(() => { commit('blackjack_set_dealing', false); }, (playerCards.length + dealerCards.length) * 500);
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    blackjackSendInsuranceSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'BlackjackInsurance');

        getters.socketClient.emit('blackjack:sendInsurance', data, (res) => {
            if(res.success === true) {
                commit('blackjack_set_data_game', res.game);

                if(res.game.state === 'completed') {
                    if(getters.soundBlackjack === 1) {
                        setTimeout(() => {
                            getters.soundBlackjackFlip.volume = getters.soundVolume;
                            getters.soundBlackjackFlip.play();
                        }, 300);
                    }

                    commit('blackjack_set_dealing', false);
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    blackjackSendHitSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'BlackjackHit');

        getters.socketClient.emit('blackjack:sendHit', data, (res) => {
            if(res.success === true) {
                const secondDealerCard = res.game.dealerStack.cards[1];
                const dealerCards = res.game.dealerStack.cards.slice(getters.blackjackData.game.dealerStack.cards.length, res.game.dealerStack.cards.length);
                res.game.dealerStack.cards[1] = { rank: 'hidden', suit: 'hidden' };
                res.game.dealerStack.cards.splice(getters.blackjackData.game.dealerStack.cards.length, res.game.dealerStack.cards.length);

                commit('blackjack_set_data_game', res.game);

                if(getters.soundBlackjack === 1) {
                    setTimeout(() => {
                        getters.soundBlackjackFlip.volume = getters.soundVolume;
                        getters.soundBlackjackFlip.play();
                    }, 300);
                }

                if(res.game.state === 'completed') {
                    setTimeout(() => {
                        res.game.dealerStack.cards.splice(1, 1);
                        res.game.dealerStack.cards.push(secondDealerCard);

                        commit('blackjack_set_data_game', res.game);

                        if(getters.soundBlackjack === 1) {
                            setTimeout(() => {
                                getters.soundBlackjackFlip.volume = getters.soundVolume;
                                getters.soundBlackjackFlip.play();
                            }, 300);
                        }

                        setTimeout(() => {
                            for(const index in dealerCards) {
                                setTimeout(() => {
                                    res.game.dealerStack.cards.push(dealerCards[index]);
                                    commit('blackjack_set_data_game', res.game);

                                    if(getters.soundBlackjack === 1) {
                                        setTimeout(() => {
                                            getters.soundBlackjackFlip.volume = getters.soundVolume;
                                            getters.soundBlackjackFlip.play();
                                        }, 300);
                                    }
                                }, index * 500);
                            }

                            setTimeout(() => { commit('blackjack_set_dealing', false); }, dealerCards.length * 500);
                        }, 500);
                    }, 500);
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    blackjackSendStandSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'BlackjackStand');

        getters.socketClient.emit('blackjack:sendStand', data, (res) => {
            if(res.success === true) {
                const dealerCards = res.game.dealerStack.cards.slice(getters.blackjackData.game.dealerStack.cards.length, res.game.dealerStack.cards.length);
                res.game.dealerStack.cards.splice(getters.blackjackData.game.dealerStack.cards.length, res.game.dealerStack.cards.length);

                commit('blackjack_set_data_game', res.game);

                if(res.game.state === 'completed') {
                    if(getters.soundBlackjack === 1) {
                        setTimeout(() => {
                            getters.soundBlackjackFlip.volume = getters.soundVolume;
                            getters.soundBlackjackFlip.play();
                        }, 300);
                    }

                    setTimeout(() => {
                        for(const index in dealerCards) {
                            setTimeout(() => {
                                res.game.dealerStack.cards.push(dealerCards[index]);
                                commit('blackjack_set_data_game', res.game);

                                if(getters.soundBlackjack === 1) {
                                    setTimeout(() => {
                                        getters.soundBlackjackFlip.volume = getters.soundVolume;
                                        getters.soundBlackjackFlip.play();
                                    }, 300);
                                }
                            }, index * 500);
                        }

                        setTimeout(() => { commit('blackjack_set_dealing', false); }, dealerCards.length * 500);
                    }, 500);
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    blackjackSendSplitSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'BlackjackSplit');

        getters.socketClient.emit('blackjack:sendSplit', data, (res) => {
            if(res.success === true) {
                const playerLeftCard = res.game.playerStack.leftCards.pop();
                const playerRightCard = res.game.playerStack.rightCards.pop();

                const dealerCards = res.game.dealerStack.cards.slice(getters.blackjackData.game.dealerStack.cards.length, res.game.dealerStack.cards.length);
                res.game.dealerStack.cards.splice(getters.blackjackData.game.dealerStack.cards.length, res.game.dealerStack.cards.length);

                commit('blackjack_set_data_game', res.game);

                setTimeout(() => {
                    res.game.playerStack.leftCards.push(playerLeftCard);
                    commit('blackjack_set_data_game', res.game);

                    if(getters.soundBlackjack === 1) {
                        setTimeout(() => {
                            getters.soundBlackjackFlip.volume = getters.soundVolume;
                            getters.soundBlackjackFlip.play();
                        }, 300);
                    }

                    setTimeout(() => {
                        res.game.playerStack.rightCards.push(playerRightCard);
                        commit('blackjack_set_data_game', res.game);

                        if(getters.soundBlackjack === 1) {
                            setTimeout(() => {
                                getters.soundBlackjackFlip.volume = getters.soundVolume;
                                getters.soundBlackjackFlip.play();
                            }, 300);
                        }
                    }, 500);
                }, 500);

                if(res.game.state === 'completed') {
                    if(getters.soundBlackjack === 1) {
                        setTimeout(() => {
                            getters.soundBlackjackFlip.volume = getters.soundVolume;
                            getters.soundBlackjackFlip.play();
                        }, 300);
                    }

                    setTimeout(() => {
                        for(const index in dealerCards) {
                            setTimeout(() => {
                                res.game.dealerStack.cards.push(dealerCards[index]);
                                commit('blackjack_set_data_game', res.game);
                            }, index * 500);
                        }

                        setTimeout(() => { commit('blackjack_set_dealing', false); }, dealerCards.length * 500);
                    }, 1500);
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    blackjackSendDoubleSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'BlackjackDouble');

        getters.socketClient.emit('blackjack:sendDouble', data, (res) => {
            if(res.success === true) {
                const secondDealerCard = res.game.dealerStack.cards[1];
                const dealerCards = res.game.dealerStack.cards.slice(getters.blackjackData.game.dealerStack.cards.length, res.game.dealerStack.cards.length);
                res.game.dealerStack.cards[1] = { rank: 'hidden', suit: 'hidden' };
                res.game.dealerStack.cards.splice(getters.blackjackData.game.dealerStack.cards.length, res.game.dealerStack.cards.length);

                commit('blackjack_set_data_game', res.game);

                if(getters.soundBlackjack === 1) {
                    setTimeout(() => {
                        getters.soundBlackjackFlip.volume = getters.soundVolume;
                        getters.soundBlackjackFlip.play();
                    }, 300);
                }

                if(res.game.state === 'completed') {
                    setTimeout(() => {
                        res.game.dealerStack.cards.splice(1, 1);
                        res.game.dealerStack.cards.push(secondDealerCard);
                        commit('blackjack_set_data_game', res.game);

                        if(getters.soundBlackjack === 1) {
                            setTimeout(() => {
                                getters.soundBlackjackFlip.volume = getters.soundVolume;
                                getters.soundBlackjackFlip.play();
                            }, 300);
                        }

                        setTimeout(() => {
                            for(const index in dealerCards) {
                                setTimeout(() => {
                                    res.game.dealerStack.cards.push(dealerCards[index]);
                                    if(index === dealerCards.length - 1) { res.game.dealing = false; }
                                    commit('blackjack_set_data_game', res.game);

                                    if(getters.soundBlackjack === 1) {
                                        setTimeout(() => {
                                            getters.soundBlackjackFlip.volume = getters.soundVolume;
                                            getters.soundBlackjackFlip.play();
                                        }, 300);
                                    }
                                }, index * 500);
                            }

                            setTimeout(() => { commit('blackjack_set_dealing', false); }, dealerCards.length * 500);
                        }, 500);
                    }, 500);
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const blackjack = {
    state,
    mutations,
    actions,
    getters
}

export default blackjack;
