<template>
    <div class="battles">
        <transition name="slide-fade" mode="out-in">
            <router-view/>
        </transition>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'Cases',
        metaInfo: {
            title: 'Battles - RustyRocket.GG'
        },
        methods: {
            ...mapActions([
                'socketClientJoin', 
                'socketClientLeave',
                'battlesGetDataSocket'
            ])
        },
        created() {
            this.socketClientJoin({ room: 'battles' });
            this.battlesGetDataSocket({});
        },
        beforeRouteLeave(to, from, next) {
            this.socketClientLeave({ room: 'battles' });
            next();
        }
    }
</script>

<style scoped>
    .battles {
        width: 1300px;
        min-height: calc(100vh - 112px);
        padding: 70px 0;
    }

    .battles .slide-fade-enter-active {
        transition: all .3s ease-out;
    }

    .battles .slide-fade-enter {
        opacity: 0;
    }

    @media only screen and (max-width: 1650px) {

        .battles {
            width: 100%;
            padding: 30px 10px;
        }

    }
</style>