import axios from 'axios';

const state = {
    fairFilterMethod: 'blackjack',
    fairUserSeedData: {
        seed: null,
        loading: false
    },
    fairVerifyBetData: {
        bet: null,
        loading: false
    }
}

const getters = {
    fairFilterMethod: state => state.fairFilterMethod,
    fairUserSeedData: state => state.fairUserSeedData,
    fairVerifyBetData: state => state.fairVerifyBetData
}

const mutations = {
    fair_set_filter_method(state, method) {
        state.fairFilterMethod = method;
    },
    fair_set_user_seed_data(state, data) {
        state.fairUserSeedData.seed = data.userSeed;
    },
    fair_set_user_seed_data_loading(state, status) {
        state.fairUserSeedData.loading = status;
    },
    fair_set_verify_bet_data_bet(state, bet) {
        state.fairVerifyBetData.bet = bet;
    },
    fair_set_verify_bet_data_loading(state, status) {
        state.fairVerifyBetData.loading = status;
    }
}

const actions = {
    fairSetFilterMethod({ getters, commit }, method) {
       if(getters.fairFilterMethod === method) { return; }
       commit('fair_set_filter_method', method);
   },
    fairEmptyVerifyBet({ commit }) {
        commit('fair_set_verify_bet_data_bet', null);
    },
    fairGetUserSeedSocket({ getters, commit, dispatch }, data) {
        if(getters.fairUserSeedData.loading === true) { return; }
        commit('fair_set_user_seed_data_loading', true);

        getters.socketClient.emit('general:fair:getUserSeed', data, (res) => {
            if(res.success === true) {
                commit('fair_set_user_seed_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('fair_set_user_seed_data_loading', false);
        });
    },
    fairGetVerifyBetSocket({ getters, commit, dispatch }, data) {
        if(getters.fairVerifyBetData.loading === true) { return; }
        commit('fair_set_verify_bet_data_loading', true);

        getters.socketClient.emit('general:fair:getVerifyBet', data, (res) => {
            if(res.success === true) {
                commit('fair_set_verify_bet_data_bet', res.bet);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('fair_set_verify_bet_data_loading', false);
        });
    },
    fairSendClientSeedSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'FairClientSeed');

        getters.socketClient.emit('general:fair:sendClientSeed', data, (res) => {
            if(res.success === true) {
                commit('auth_set_user_client_seed', res.clientSeed);
                dispatch('notificationShow', { type: 'success', message: 'You have successfully updated your client seed.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    fairSendUserSeedSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'FairUserSeed');

        getters.socketClient.emit('general:fair:sendUserSeed', data, (res) => {
            if(res.success === true) {
                commit('fair_set_user_seed_data', res);
                dispatch('notificationShow', { type: 'success', message: 'You have successfully generated a server seed.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const fair = {
    state,
    mutations,
    actions,
    getters
}

export default fair;
