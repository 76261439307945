export default {
    methods: {
        generalFormatAmount(amount) {
            return parseFloat(Math.floor(amount / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        generalFormatInputAmount(amount) {
            amount = amount.replace(',', '.').replace(/[^\d.]/g, '');
            return amount.indexOf('.') >= 0 ? amount.substr(0, amount.indexOf('.')) + '.' + amount.substr((amount.indexOf('.') + 1), 2).replace('.', '') : amount;
        },
        generalCopyValue(value) {
            const el = document.createElement('textarea');
            el.value = value;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
    }
};