const state = {
    casesFilterSelect: 'featured',
    casesFilterSearch: '',
    casesCount: 1,
    casesRunning: false,
    casesGames: [],
    casesData: {
        boxes: [],
        loading: false
    },
    casesBoxData: {
        box: null,
        loading: false
    }
}

const getters = {
    casesFilterSelect: state => state.casesFilterSelect,
    casesFilterSearch: state => state.casesFilterSearch,
    casesCount: state => state.casesCount,
    casesRunning: state => state.casesRunning,
    casesGames: state => state.casesGames,
    casesData: state => state.casesData,
    casesBoxData: state => state.casesBoxData
}

const mutations = {
    cases_set_filter_select(state, value) {
        state.casesFilterSelect = value;
    },
    cases_set_filter_search(state, value) {
        state.casesFilterSearch = value;
    },
    cases_set_count(state, value) {
        state.casesCount = value;
    },
    cases_set_running(state, value) {
        state.casesRunning = value;
    },
    cases_set_games(state, games) {
        state.casesGames = games;
    },
    cases_set_data_boxes(state, boxes) {
        state.casesData.boxes = boxes;
    },
    cases_set_data_loading(state, status) {
        state.casesData.loading = status;
    },
    cases_set_box_data_box(state, box) {
        state.casesBoxData.box = box;
    },
    cases_set_box_data_loading(state, status) {
        state.casesBoxData.loading = status;
    }
}

const actions = {
    casesSetFilterSelect({ commit }, value) {
        commit('cases_set_filter_select', value);
    },
    casesSetFilterSearch({ commit }, value) {
        commit('cases_set_filter_search', value);
    },
    casesSetCount({ commit }, value) {
        commit('cases_set_count', value);
    },
    casesSetRunnning({ commit }, value) {
        commit('cases_set_running', value);
    },
    casesSetGames({ commit }, games) {
        commit('cases_set_games', games);
    },
    casesSocketInit({ commit }, data) {
        commit('cases_set_boxes', data.boxes);
    },
    casesGetDataSocket({ getters, commit, dispatch }, data) {
        if(getters.casesData.loading === true) { return; }
        commit('cases_set_data_loading', true);

        getters.socketClient.emit('cases:getData', data, (res) => {
            if(res.success === true) {
                commit('cases_set_data_boxes', res.boxes);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('cases_set_data_loading', false);
        });
    },
    casesGetBoxDataSocket({ getters, commit, dispatch }, data) {
        if(getters.casesBoxData.loading === true) { return; }
        commit('cases_set_box_data_loading', true);

        getters.socketClient.emit('cases:getBoxData', data, (res) => {
            if(res.success === true) {
                commit('cases_set_box_data_box', res.box);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('cases_set_box_data_loading', false);
        });
    },
    casesSendCreateSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'CasesCreate');

        getters.socketClient.emit('cases:sendCreate', data, (res) => {
            if(res.success === true) {
                commit('cases_set_running', true);
                commit('cases_set_games', res.games);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const cases = {
    state,
    mutations,
    actions,
    getters
}

export default cases;