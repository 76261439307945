<template>
    <div class="fair-seeds">
        <div class="seeds-client">
            <div class="client-title">The Client Seed</div>
            <div class="client-text">
                <p>This is a string that is determined by the player. The client seed can be edited and changed at any time regularly by yourself.</p>
            </div>
            <div class="client-input">
                <input v-model="fairClientSeed" type="text" placeholder="Enter personal client seed..." v-bind:disabled="authUser.user === null" />
                <button v-on:click="fairClientSeedButton()" v-bind:disabled="socketSendLoading !== null">
                    <transition name="fade" mode="out-in">
                        <div class="button-loading" v-if="socketSendLoading === 'FairClientSeed'" key="loading">
                            <LoadingAnimation />
                        </div>
                        <div class="button-content" v-else key="content">SET CLIENT SEED</div>
                    </transition>
                </button>
            </div>
        </div>
        <div class="seeds-server">
            <div class="server-title">The Server Seed</div>
            <div class="server-text">
                <p>This is a string that is cryptographically secure pseudo-randomly generated. For every user a personal server seed will be generated. The sha256 hashed version of the current server seed will be displayed at the provably fair page. When you generate a new server seed the, the old one will be replaced with the new generated one and the nonce will be reseted to one. After that you can verify all bets made with the old server seed.</p>
            </div>
            <div class="server-input">
                <input v-model="fairServerSeed" type="text" disabled />
                <button v-on:click="fairUserSeedButton()" v-bind:disabled="socketSendLoading !== null">
                    <transition name="fade" mode="out-in">
                        <div class="button-loading" v-if="socketSendLoading === 'FairUserSeed'" key="loading">
                            <LoadingAnimation />
                        </div>
                        <div class="button-content" v-else key="content">REGENERATE</div>
                    </transition>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';

    export default {
        name: 'FairSeeds',
        components: {
            LoadingAnimation
        },
        data() {
            return {
                fairClientSeed: null,
                fairServerSeed: null
            }
        },
        methods: {
            ...mapActions([
                'notificationShow', 
                'fairGetUserSeedSocket', 
                'fairSendClientSeedSocket', 
                'fairSendUserSeedSocket'
            ]),
            fairClientSeedButton() {
                if(this.authUser.user === null) {
                    this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                    return;
                }

                if(this.fairClientSeed === null || this.fairClientSeed.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered client seed is invalid.' });
                    return;
                }

                this.fairSendClientSeedSocket({ clientSeed: this.fairClientSeed });
            },
            fairUserSeedButton() {
                if(this.authUser.user === null) {
                    this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                    return;
                }

                this.fairSendUserSeedSocket({});
            }
        },
        computed: {
            ...mapGetters([
                'authUser', 
                'socketSendLoading', 
                'fairUserSeedData'
            ])
        },
        watch: {
            'fairUserSeedData.seed': {
                handler(data, oldData) {
                    this.fairServerSeed = this.authUser.user !== null && this.fairUserSeedData.seed !== null ? this.fairUserSeedData.seed.hash : 'N/A';
                },
                deep: true
            }
        },
        created() {
            if(this.authUser.user !== null && this.fairUserSeedData.loading === false) { this.fairGetUserSeedSocket({}); }

            this.fairClientSeed = this.authUser.user !== null ? this.authUser.user.fair.clientSeed : 'N/A';
            this.fairServerSeed = this.authUser.user !== null && this.fairUserSeedData.seed !== null ? this.fairUserSeedData.seed.hash : 'N/A';
        }
    }
</script>

<style scoped>
    .fair-seeds  {
        width: 600px;
    }

    .fair-seeds .seeds-client,
    .fair-seeds .seeds-server {
        width: 100%;
    }

    .fair-seeds .client-title,
    .fair-seeds .server-title {
        font-size: 18px;
        font-weight: 600;
        color: #596076;
    }

    .fair-seeds .server-title {
        margin-top: 35px;
    }

    .fair-seeds .client-text,
    .fair-seeds .server-text {
        width: 100%;
        margin-top: 8px;
    }

    .fair-seeds .client-text p,
    .fair-seeds .server-text p {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
    }

    .fair-seeds .client-input,
    .fair-seeds .server-input {
        width: 100%;
        position: relative;
        margin-top: 12px;
    }

    .fair-seeds .client-input input,
    .fair-seeds .server-input input {
        width: 100%;
        height: 54px;
        padding: 0 153px 0 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .fair-seeds .client-input input::placeholder,
    .fair-seeds .server-input input::placeholder {
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .fair-seeds .client-input button,
    .fair-seeds .server-input button {
        width: 136px;
        height: 41px;
        position: absolute;
        top: 50%;
        right: 6px;
        transform: translate(0, -50%);
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    .fair-seeds .client-input button .button-loading,
    .fair-seeds .server-input button .button-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fair-seeds .client-input button .button-loading svg,
    .fair-seeds .server-input button .button-loading svg {
        font-size: 16px;
    }

    .fair-seeds .client-input button .button-loading.fade-leave-active,
    .fair-seeds .server-input button .button-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .fair-seeds .client-input button .button-loading.fade-leave-to,
    .fair-seeds .server-input button .button-loading.fade-leave-to {
        opacity: 0;
    }

    .fair-seeds .client-input button .button-content.fade-enter-active,
    .fair-seeds .server-input button .button-content.fade-enter-active {
        transition: opacity 0.5s;
    }

    .fair-seeds .client-input button .button-content.fade-enter-from,
    .fair-seeds .server-input button .button-content.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1200px) {

        .fair-seeds {
            width: 100%;
            margin-top: 50px;
        }

    }
</style>
