<template>
    <div class="home">
        <div class="home-banner">

            <HomeBannerUser />
            <HomeBannerRewards />

        </div>
        <div class="home-games">
            <div class="games-title">OUR GAMES</div>
            <div class="games-list">

                <HomeGameElement game="battles" type="hot" />
                <HomeGameElement game="roulette" type="new" />
                <HomeGameElement game="cases" type="new" />
                <HomeGameElement game="blackjack" type="" />
                <HomeGameElement game="mines" type="" />
                <HomeGameElement game="towers" type="" />
                <HomeGameElement game="upgrader" type="" />

            </div>
        </div>
        <div class="home-methods">
            <div class="methods-title">OUR DEPOSIT METHODS</div>
            <div class="methods-list">

                <HomeMethodElement method="rust" />
                <HomeMethodElement method="btc" />
                <HomeMethodElement method="eth" />
                <HomeMethodElement method="ltc" />
                <HomeMethodElement method="visa" />
                <HomeMethodElement method="mastercard" />
                <HomeMethodElement method="paypal" />
                <HomeMethodElement method="paysafecard" />


            </div>
        </div>

        <Bets />
    </div>
</template>

<script>
    import Bets from '@/components/bets/Bets';
    import HomeBannerUser from '@/components/home/HomeBannerUser';
    import HomeBannerRewards from '@/components/home/HomeBannerRewards';
    import HomeGameElement from '@/components/home/HomeGameElement';
    import HomeMethodElement from '@/components/home/HomeMethodElement';

    export default {
        name: 'Home',
        metaInfo: {
            title: 'RustyRocket.GG'
        },
        components: {
            Bets,
            HomeBannerUser,
            HomeBannerRewards,
            HomeGameElement,
            HomeMethodElement
        }
    }
</script>

<style scoped>
    .home {
        width: 1300px;
        min-height: 100vh;
        padding: 70px 0;
    }

    .home .home-banner {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .home .home-games {
        width: 100%;
        margin-top: 30px;
        padding-top: 20px;
        border-top: 1px solid #20252d;
    }

    .home .games-title {
        font-size: 15px;
        font-weight: 600;
        color: #ffffff;
    }

    .home .games-list {
        display: flex;
        flex-wrap: wrap;
    }

    .home .home-methods {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        padding-top: 20px;
        border-top: 1px solid #20252d;
    }

    .home .methods-title {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
    }

    .home .methods-list {
        margin-top: 15px;
        padding: 12px 25px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    @media only screen and (max-width: 1330px) {

        .home {
            width: 100%;
            padding: 20px 10px;
        }

    }

    @media only screen and (max-width: 1025px) {

        .home .home-games a.link-game {
            width: calc(33.33% - 13.33px);
        }

        .home .home-games a.link-game:nth-child(4) {
            margin-right: 20px;
        }

        .home .home-games a.link-game:nth-child(3n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 775px) {

        .home .home-games a.link-game {
            width: calc(50% - 10px);
        }

        .home .home-games a.link-game:nth-child(3n) {
            margin-right: 20px;
        }

        .home .home-games a.link-game:nth-child(2n) {
            margin-right: 0;
        }

    }
</style>
