<template>
    <div v-if="battlesGameData.game !== null && battlesGameData.loading === false" v-bind:class="[
        'battles-game',
        'game-' + battlesGetPlayerCount,
        'game-' + battlesGameData.game.state,
        { 'game-crazy': battlesGameData.game.options.crazy === true }
    ]">
        <div class="game-header">
            <div class="header-left">
                <router-link to="/battles" class="link-back">GO BACK</router-link>
            </div>
            <div class="header-actions">
                <button v-on:click="battlesCopyButton(battlesGetLink)" class="button-copy">
                    <IconShare />
                </button>
                <button v-on:click="battlesGameButton()" class="button-fair">
                    <IconFairness />
                    FAIRNESS
                </button>
            </div>
        </div>
        <div class="game-info">
            <div class="info-amount">
                AMOUNT
                <img src="@/assets/img/icons/coins.webp" alt="icons-image" />
                <div class="amount-value">
                    <span>{{ generalFormatAmount(battlesGetAmount).split('.')[0] }}</span>.{{ generalFormatAmount(battlesGetAmount).split('.')[1] }}
                </div> 
            </div>
            <div class="info-cases">
                <div class="cases-list">

                    <img v-for="(box, index) in battlesGetBoxes" v-bind:key="index" v-bind:src="box.image" alt="box" />

                </div>
            </div>
            <div class="info-right">
                <div v-if="battlesGameData.game.state === 'rolling'">
                    Round <span>{{ this.battlesGameData.game.bets[0].outcomes.length }}</span> of <span>{{ battlesGetBoxes.length }}</span>
                </div>
                <div v-else-if="battlesGameData.game.state === 'completed'">Completed</div>
                <div v-else>
                    <span>{{ battlesGetBoxes.length + (battlesGameData.game.options.crazy ? ' Crazy' : '') }}</span> Rounds
                </div>
            </div>
        </div>
        <div class="game-content">
            <BattlesSpinner />

            <div class="content-bets">

                <BattlesBetElement v-for="(bet, index) in battlesGetBets" v-bind:key="index" v-bind:pos="index" v-bind:bet="bet" />

            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';
    import IconShare from '@/components/icons/IconShare';
    import IconFairness from '@/components/icons/IconFairness';
    import BattlesSpinner from '@/components/battles/BattlesSpinner';
    import BattlesBetElement from '@/components/battles/BattlesBetElement';

    export default {
        name: 'BattlesGame',
        components: {
            IconShare,
            IconFairness,
            BattlesSpinner,
            BattlesBetElement
        },
        mixins: [
            mixins
        ],
        methods: {
            ...mapActions([
                'notificationShow',
                'modalsSetShow',
                'modalsSetData',
                'battlesSetGameData',
                'battlesGetGameDataSocket'
            ]),
            battlesCopyButton(value) {
                this.generalCopyValue(value);
                this.notificationShow({ type: 'success', message: 'Copied to your clipboard.' });
            },
            battlesGameButton() {
                this.modalsSetData({ method: 'battles', game: this.battlesGameData.game });
                this.modalsSetShow('FairInfo');
            }
        },
        computed: {
            ...mapGetters([
                'battlesGameData'
            ]),
            battlesGetAmount() {
                let amount = 0;

                if(this.battlesGameData.game !== null) { 
                    amount = this.battlesGameData.game.amount; 
                }

                return amount;
            },
            battlesGetPlayerCount() {
                let count = 2;

                if(this.battlesGameData.game !== null) { count = this.battlesGameData.game.playerCount; }

                return count;
            },
            battlesGetBets() {
                let bets = [];

                for(let bet = 0; bet < this.battlesGameData.game.playerCount; bet++) {
                    const index = this.battlesGameData.game.bets.findIndex((element) => element.slot === bet);
                    bets.push(index !== -1 ? this.battlesGameData.game.bets[index] : null);
                }

                return bets;
            },
            battlesGetLink() {
                let link = 'rustyrocket.gg/battles/';

                if(this.battlesGameData.game !== null) { link = link + this.battlesGameData.game._id; }

                return link;
            },
            battlesGetBoxes() {
                let boxes = [];

                if(this.battlesGameData.game !== null) {
                    for(const box of this.battlesGameData.game.boxes) {
                        for(let i = 0; i < box.count; i++) { boxes.push(box.box); }
                    }
                }

                return boxes;
            }
        },
        created() {
            this.battlesGetGameDataSocket({ gameId: this.$route.params.gameId });
        },
        beforeRouteUpdate(to, from) {
            this.battlesGetGameDataSocket({ gameId: to.params.gameId });
        }
    }
</script>

<style scoped>
    .battles-game {
        width: 100%;
    }

    .battles-game .game-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .battles-game .header-left,
    .battles-game .header-actions {
        display: flex;
        align-items: center;
    }

    .battles-game a.link-back,
    .battles-game button.button-fair,
    .battles-game button.button-copy {
        height: 44px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #191e27;
    }

    .battles-game button.button-copy {
        margin-right: 8px;
        padding: 0 12px;
    }

    .battles-game a.link-back:hover,
    .battles-game button.button-fair:hover {
        color: #ffffff;
    }

    .battles-game button.button-fair svg,
    .battles-game button.button-copy svg {
        width: 21px;
        fill: #767c8b;
        transition: fill 0.3s ease;
    }

    .battles-game button.button-fair svg {
        width: 16px;
        margin-right: 8px;
    }

    .battles-game button.button-fair:hover svg,
    .battles-game button.button-copy:hover svg {
        fill: #ffffff;
    }

    .battles-game .game-info {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: calc(50% - 150px) 300px calc(50% - 150px);
        margin-top: 16px;
        padding: 7px 15px;
        border-radius: 8px;
        background: #1c202c;
    }

    .battles-game .info-amount {
        width: 100%;
        grid-column: 1 / 1;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #596076;
    }

    .battles-game .info-amount img {
        width: 21px;
        margin: 0 10px;
    }

    .battles-game .amount-value {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .battles-game .amount-value span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .battles-game .info-cases {
        width: 100%;
        height: 56px;
        grid-column: 2 / 2;
        border-radius: 8px;
        background: #12161d;
        overflow: hidden;
    }

    .battles-game .cases-list {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
    }

    .battles-game .cases-list img {
        height: 40px;
        margin-right: 12px;
    }

    .battles-game .info-right {
        width: 100%;
        grid-column: 3 / 3;
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 600;
        color: #596076;
    }

    .battles-game .info-right span {
        color: #ffffff;
    }

    .battles-game.game-crazy .info-right span {
        color: #fc3b30;
    }

    .battles-game .game-content {
        width: 100%;
        margin-top: 16px;
    }

    .battles-game .content-bets {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 35px;
    }

    @media only screen and (max-width: 850px) {

        .battles-game .game-info {
            grid-template-columns: 50% 50%;
            grid-template-rows: auto auto;
            padding: 12px 7px 7px 7px;
        }

        .battles-game .info-amount {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
        }

        .battles-game .info-cases {
            grid-column: 1 / 3;
            grid-row: 2 / 2;
            margin-top: 12px;
        }

        .battles-game .info-right {
            grid-column: 2 / 2;
            grid-row: 1 / 1;
        }

    }
</style>