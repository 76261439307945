export default {
    methods: {
        minesGetFactorialNumber(number) {
            let value = number;
            for (let i = number; i > 1; i--) {
                value = value * (i - 1);
            }
            return value;
        },
        minesGetMultiplier(game) {
            let multiplier = 0;

            const first = 25 === game.revealed.length ? 1 : this.minesGetFactorialNumber(25) / (this.minesGetFactorialNumber(game.revealed.length) * this.minesGetFactorialNumber(25 - game.revealed.length));
            const second = (25 - game.minesCount) === game.revealed.length ? 1 : this.minesGetFactorialNumber(25 - game.minesCount) / (this.minesGetFactorialNumber(game.revealed.length) * this.minesGetFactorialNumber((25 - game.minesCount) - game.revealed.length));

            multiplier = 0.93 * (first / second);
            multiplier = multiplier < 1 ? 1.01 : multiplier;
            multiplier = Math.round(multiplier * 100) / 100;

            return multiplier;
        }
    }
};