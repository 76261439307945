<template>
    <div class="bets">
        <div class="bets-nav">
            <button v-if="authUser.user !== null" v-on:click="betsSetTab('my')" class="nav-link" v-bind:class="{ 'link-active': betsTab === 'my' }">My Bets</button>
            <button v-on:click="betsSetTab('all')" class="nav-link" v-bind:class="{ 'link-active': betsTab === 'all' }">All Bets</button>
            <button v-on:click="betsSetTab('high')" class="nav-link" v-bind:class="{ 'link-active': betsTab === 'high' }">High Rollers</button>
        </div>
        <div class="bets-content">

            <BetsElement v-for="bet in betsGetList" v-bind:key="bet._id + betsTab" v-bind:bet="bet" />

        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import BetsElement from '@/components/bets/BetsElement';

    export default {
        name: 'Bets',
        components: {
            BetsElement
        },
        data() {
            return {
                betsTab: 'all'
            }
        },
        methods: {
            ...mapActions([
                'generalGetBetsDataSocket'
            ]),
            betsSetTab(tab) {
                this.betsTab = tab;
            }
        },
        computed: {
            ...mapGetters([
                'authUser', 
                'generalBets'
            ]),
            betsGetList() {
                let bets = [];

                if(this.generalBets.bets !== null && this.generalBets.bets[this.betsTab] !== undefined) {
                    bets = this.generalBets.bets[this.betsTab];
                }

                return bets;
            }
        },
        created() {
            if(this.generalBets.bets === null && this.generalBets.loading === false) {
                this.generalGetBetsDataSocket({});
            }
        }
    }
</script>

<style scoped>
    .bets {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 90px;
    }

    .bets .bets-nav {
        position: relative;
    }

    .bets .bets-nav::after {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 1px;
        background: #191d29;
    }

    .bets button.nav-link {
        position: relative;
        margin-right: 32px;
        padding: 0 0 16px 0;
        font-size: 16px;
        font-weight: 600;
        color: #616d8b;
        transition: all 0.3s ease;
        z-index: 1;
    }

    .bets button.nav-link:last-of-type {
        margin-right: 0;
    }

    .bets button.nav-link::after {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 1px;
        background: #fd3b31;
        transition: all 0.3s ease;
        opacity: 0;
    }

    .bets button.nav-link.link-active {
        color: #fd3b31;
    }

    .bets button.nav-link.link-active::after {
        opacity: 1;
    }

    .bets .bets-content {
        width: 100%;
        margin-top: 23px;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .bets .bets-content::-webkit-scrollbar-thumb {
        background: #212732;
        border-radius: 4px;
    }

    .bets .bets-content::-webkit-scrollbar {
        width: 0;
        height: 8px;
    }

    .bets .bets-content::-webkit-scrollbar-track {
        background: transparent;
    }
</style>
