<template>
    <div v-bind:class="['filter-item-sort', { 
        'sort-ascending': filterValue === 'ascending' 
    }]">
        <button class="sort-toggle" v-on:click="$emit('setFilterValue', filterValue === 'ascending' ? 'descending' : 'ascending')">
            {{filterValue === 'ascending' ? 'PRICE ASCENDING' : 'PRICE DESCENDING'}}
            <IconCaretDown />
        </button>
    </div>
</template>

<script>
    import IconCaretDown from '@/components/icons/IconCaretDown';

    export default {
        components: {
            IconCaretDown
        },
        props: [
            'filterValue'
        ]
    }
</script>

<style scoped>
    .filter-item-sort {
         position: relative;
    }

    .filter-item-sort button.sort-toggle {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        color: #fd3b31;
    }

    .filter-item-sort button.sort-toggle svg {
        height: 9px;
        margin-left: 6px;
        fill: #fd3b31;
        transition: all 0.3s ease;
    }

    .filter-item-sort.sort-ascending button.sort-toggle svg {
        transform: rotate(180deg);
    }

    @media only screen and (max-width: 475px) {

        .filter-item-sort {
            margin-top: 20px;
        }

    }
</style>