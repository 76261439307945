const state = {
    affiliatesData: {
        data: null,
        loading: false
    },
    affiliatesUsers: {
        users: null,
        count: null,
        loading: false,
        page: 1
    }
}

const getters = {
    affiliatesData: state => state.affiliatesData,
    affiliatesUsers: state => state.affiliatesUsers
}

const mutations = {
    affiliates_set_data(state, data) {
        state.affiliatesData.data = data;
    },
    affiliates_set_data_loading(state, status) {
        state.affiliatesData.loading = status;
    },
    affiliates_set_users(state, data) {
        state.affiliatesUsers.users = data.users;
        state.affiliatesUsers.count = data.count;
    },
    affiliates_set_users_loading(state, status) {
        state.affiliatesUsers.loading = status;
    },
    affiliates_set_users_page(state, page) {
        state.affiliatesUsers.page = page;
    }
}

const actions = {
    affiliatesSetUsersPage({ commit }, page) {
        commit('affiliates_set_users_page', page);
    },
    affiliatesGetDataSocket({ getters, commit, dispatch }, data) {
        if(getters.affiliatesData.loading === true) { return; }
        commit('affiliates_set_data_loading', true);

        getters.socketClient.emit('general:affiliate:getData', data, (res) => {
            if(res.success === true) {
                commit('affiliates_set_data', res.data);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('affiliates_set_data_loading', false);
        });
    },
    affiliatesGetUsersSocket({ getters, commit, dispatch }, data) {
        if(getters.affiliatesUsers.loading === true) { return; }
        commit('affiliates_set_users_loading', true);

        getters.socketClient.emit('general:affiliate:getUsers', data, (res) => {
            if(res.success === true) {
                commit('affiliates_set_users', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('affiliates_set_users_loading', false);
        });
    },
    affiliatesSendCodeSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AffiliatesCode');

        getters.socketClient.emit('general:affiliate:sendCode', data, (res) => {
            if(res.success === true) {
                commit('affiliates_set_data', res.data);
                dispatch('notificationShow', { type: 'success', message: 'You have successfully updated your affiliate code.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    affiliatesSendClaimCodeSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AffiliatesClaimCode');

        getters.socketClient.emit('general:affiliate:sendClaimCode', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);

                dispatch('modalsSetShow', null);
                dispatch('notificationShow', { type: 'success', message: 'You have successfully redeemed a affiliate code.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    affiliatesSendClaimEarningsSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AffiliatesClaim');

        getters.socketClient.emit('general:affiliate:sendClaimEarnings', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                commit('affiliates_set_data', res.user.affiliates);

                dispatch('notificationShow', { type: 'success', message: 'You have successfully claimed your affiliate earnings.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const affiliates = {
    state,
    mutations,
    actions,
    getters
}

export default affiliates;
