const state = {
    userTransactionsData: {
        transactions: null,
        count: null,
        loading: false,
        page: 1
    },
    userBetsData: {
        bets: null,
        count: null,
        loading: false,
        page: 1
    }
}

const getters = {
    userTransactionsData: state => state.userTransactionsData,
    userBetsData: state => state.userBetsData
}

const mutations = {
    user_set_transactions_data(state, data) {
        state.userTransactionsData.transactions = data.transactions;
        state.userTransactionsData.count = data.count;
    },
    user_set_transactions_data_loading(state, status) {
        state.userTransactionsData.loading = status;
    },
    user_set_transactions_data_page(state, page) {
        state.userTransactionsData.page = page;
    },
    user_set_bets_data(state, data) {
        state.userBetsData.bets = data.bets;
        state.userBetsData.count = data.count;
    },
    user_set_bets_data_loading(state, status) {
        state.userBetsData.loading = status;
    },
    user_set_bets_data_page(state, page) {
        state.userBetsData.page = page;
    }
}

const actions = {
    userSetTransactionsDataPage({ commit }, page) {
        commit('user_set_transactions_data_page', page);
    },
    userSetBetsDataPage({ commit }, page) {
        commit('user_set_bets_data_page', page);
    },
    userGetTransactionsSocket({ getters, commit, dispatch }, data) {
        if(getters.userTransactionsData.loading === true) { return; }
        commit('user_set_transactions_data_loading', true);

        getters.socketClient.emit('general:user:getTransactions', data, (res) => {
            if(res.success === true) {
                commit('user_set_transactions_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('user_set_transactions_data_loading', false);
        });
    },
    userGetBetsSocket({ getters, commit, dispatch }, data) {
        if(getters.userBetsData.loading === true) { return; }
        commit('user_set_bets_data_loading', true);

        getters.socketClient.emit('general:user:getBets', data, (res) => {
            if(res.success === true) {
                commit('user_set_bets_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('user_set_bets_data_loading', false);
        });
    },
    userSendSettingSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'UserSetting');

        getters.socketClient.emit('general:user:sendSetting', data, (res) => {
            if(res.success === true) {
                commit('auth_set_user', { ...getters.authUser.user, ...res.user });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const user = {
    state,
    mutations,
    actions,
    getters
}

export default user;
