<template>
    <div class="roulette">
        <RouletteHeader />
        <RouletteGame />
        <RouletteControls />
        <RouletteBets />
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import RouletteHeader from '@/components/roulette/RouletteHeader';
    import RouletteGame from '@/components/roulette/RouletteGame';
    import RouletteControls from '@/components/roulette/RouletteControls';
    import RouletteBets from '@/components/roulette/RouletteBets';

    export default {
        name: 'Roulette',
        metaInfo: {
            title: 'Roulette - RustyRocket.GG'
        },
        components: {
            RouletteHeader,
            RouletteGame,
            RouletteControls,
            RouletteBets
        },
        methods: {
            ...mapActions([
                'socketClientJoin',
                'socketClientLeave',
                'rouletteGetDataSocket'
            ])
        },
        created() {
            this.socketClientJoin({ room: 'roulette' });
            this.rouletteGetDataSocket({});
        },
        beforeRouteLeave(to, from, next) {
            this.socketClientLeave({ room: 'roulette' });
            next();
        }
    }
</script>

<style scoped>
    .roulette {
        width: 1300px;
        min-height: calc(100vh - 112px);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 70px 0;
    }

    @media only screen and (max-width: 1330px) {

        .roulette {
            width: 100%;
            padding: 20px 10px;
        }   

    }
</style>