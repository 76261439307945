var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roulette-bets"},[_c('div',{class:['bets-section', 'section-red',
        'section-' + (_vm.rouletteIsCompleted === true ? _vm.rouletteGetOutcomeColor(_vm.rouletteData.game) === 'red' ? 'won' : 'lose' : '')
    ]},[_c('div',{staticClass:"section-info"},[_c('div',{staticClass:"info-left"},[_c('img',{attrs:{"src":require("@/assets/img/roulette/red.webp")}}),_vm._v(" "+_vm._s(_vm.rouletteGetStats.red.bets)+" Bets Total ")]),_c('div',{staticClass:"info-amount"},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"icons-image"}}),_c('AmountNumber',{attrs:{"amount":_vm.rouletteGetStats.red.amount}})],1)]),_c('transition-group',{staticClass:"section-content",attrs:{"name":"bets","tag":"div"}},_vm._l((_vm.rouletteGetBets.red),function(bet){return _c('RouletteBetElement',{key:bet._id,attrs:{"bet":bet}})}),1)],1),_c('div',{class:['bets-section', 'section-black',
        'section-' + (_vm.rouletteIsCompleted === true ? _vm.rouletteGetOutcomeColor(_vm.rouletteData.game) === 'black' ? 'won' : 'lose' : '')
    ]},[_c('div',{staticClass:"section-info"},[_c('div',{staticClass:"info-left"},[_c('img',{attrs:{"src":require("@/assets/img/roulette/black.webp")}}),_vm._v(" "+_vm._s(_vm.rouletteGetStats.black.bets)+" Bets Total ")]),_c('div',{staticClass:"info-amount"},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"icons-image"}}),_c('AmountNumber',{attrs:{"amount":_vm.rouletteGetStats.black.amount}})],1)]),_c('transition-group',{staticClass:"section-content",attrs:{"name":"bets","tag":"div"}},_vm._l((_vm.rouletteGetBets.black),function(bet){return _c('RouletteBetElement',{key:bet._id,attrs:{"bet":bet}})}),1)],1),_c('div',{class:['bets-section', 'section-green',
        'section-' + (_vm.rouletteIsCompleted === true ? _vm.rouletteGetOutcomeColor(_vm.rouletteData.game) === 'green' ? 'won' : 'lose' : '')
    ]},[_c('div',{staticClass:"section-info"},[_c('div',{staticClass:"info-left"},[_c('img',{attrs:{"src":require("@/assets/img/roulette/green.webp")}}),_vm._v(" "+_vm._s(_vm.rouletteGetStats.green.bets)+" Bets Total ")]),_c('div',{staticClass:"info-amount"},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"icons-image"}}),_c('AmountNumber',{attrs:{"amount":_vm.rouletteGetStats.green.amount}})],1)]),_c('transition-group',{staticClass:"section-content",attrs:{"name":"bets","tag":"div"}},_vm._l((_vm.rouletteGetBets.green),function(bet){return _c('RouletteBetElement',{key:bet._id,attrs:{"bet":bet}})}),1)],1),_c('div',{class:['bets-section', 'section-bait',
        'section-' + (_vm.rouletteIsCompleted === true ? _vm.rouletteGetOutcomeColor(_vm.rouletteData.game) === 'bait' ? 'won' : 'lose' : '')
    ]},[_c('div',{staticClass:"section-info"},[_c('div',{staticClass:"info-left"},[_c('img',{attrs:{"src":require("@/assets/img/roulette/bait.webp")}}),_vm._v(" "+_vm._s(_vm.rouletteGetStats.bait.bets)+" Bets Total ")]),_c('div',{staticClass:"info-amount"},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"icons-image"}}),_c('AmountNumber',{attrs:{"amount":_vm.rouletteGetStats.bait.amount}})],1)]),_c('transition-group',{staticClass:"section-content",attrs:{"name":"bets","tag":"div"}},_vm._l((_vm.rouletteGetBets.bait),function(bet){return _c('RouletteBetElement',{key:bet._id,attrs:{"bet":bet}})}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }