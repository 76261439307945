<template>
    <div class="navbar-filter-games" v-bind:class="{ 'games-open': navbarDropdown === true }">
        <button v-on:click="navbarToggleDropdown(false)" class="button-toggle">
            <svg class="icon-games" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 640 512">
                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path d="M128 0C92.7 0 64 28.7 64 64H448c0-35.3-28.7-64-64-64H128zM64 96C28.7 96 0 124.7 0 160V352c0 35.3 28.7 64 64 64v32c0 35.3 28.7 64 64 64H416h32 80c53 0 96-43 96-96V211.8c9.8-8.8 16-21.6 16-35.8c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 14.2 6.2 27 16 35.8V416c0 17.7-14.3 32-32 32H448V416c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H64zM56 200c0-13.3 10.7-24 24-24h59.9c23 0 38.5 23.5 29.4 44.6L126.1 321.5c-5.2 12.2-19.3 17.8-31.5 12.6s-17.8-19.3-12.6-31.5L115.6 224H80c-13.3 0-24-10.7-24-24zm168-24h59.9c23 0 38.5 23.5 29.4 44.6L270.1 321.5c-5.2 12.2-19.3 17.8-31.5 12.6s-17.8-19.3-12.6-31.5L259.6 224H224c-13.3 0-24-10.7-24-24s10.7-24 24-24zm120 24c0-13.3 10.7-24 24-24h59.9c23 0 38.5 23.5 29.4 44.6L414.1 321.5c-5.2 12.2-19.3 17.8-31.5 12.6s-17.8-19.3-12.6-31.5L403.6 224H368c-13.3 0-24-10.7-24-24z"/>
            </svg>
            GAMES
            <svg class="icon-state" width="13" height="9" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.2142 0.361328L6.5 5.07555L1.78577 0.361328L0 2.1471L6.5 8.6471L13 2.1471L11.2142 0.361328Z" />
            </svg>
        </button>
        <div class="games-menu">
            <div class="menu-inner">
                <router-link v-on:click.native="navbarToggleDropdown(true)" class="link-game" to="/battles">
                    <IconBattles />
                    BATTLES
                </router-link>
                <router-link v-on:click.native="navbarToggleDropdown(true)" class="link-game" to="/roulette">
                    <IconRoulette />
                    ROULETTE
                </router-link>
                <router-link v-on:click.native="navbarToggleDropdown(true)" class="link-game" to="/cases">
                    <IconCases />
                    CASES
                </router-link>
                <router-link v-on:click.native="navbarToggleDropdown(true)" class="link-game" to="/blackjack">
                    <IconBlackjack />
                    BLACKJACK
                </router-link>
                <router-link v-on:click.native="navbarToggleDropdown(true)" class="link-game" to="/mines">
                    <IconMines />
                    MINES
                </router-link>
                <router-link v-on:click.native="navbarToggleDropdown(true)" class="link-game" to="/towers">
                    <IconTowers />
                    TOWERS
                </router-link>
                <router-link v-on:click.native="navbarToggleDropdown(true)" class="link-game" to="/upgrader">
                    <IconUpgrader />
                    UPGRADER
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import IconBattles from '@/components/icons/IconBattles';
    import IconRoulette from '@/components/icons/IconRoulette';
    import IconCases from '@/components/icons/IconCases';
    import IconBlackjack from '@/components/icons/IconBlackjack';
    import IconMines from '@/components/icons/IconMines';
    import IconTowers from '@/components/icons/IconTowers';
    import IconUpgrader from '@/components/icons/IconUpgrader';

    export default {
        name: 'NavbarFilterGames',
        components: {
            IconBattles,
            IconRoulette,
            IconCases,
            IconBlackjack,
            IconMines,
            IconTowers,
            IconUpgrader
        },
        data: function() {
            return {
                navbarDropdown: false
            }
        },
        methods: {
            navbarToggleDropdown(navbar) {
                if(navbar === true) { this.$parent.navbarSetMobile(false); }
                this.navbarDropdown = !this.navbarDropdown;
            }
        },
        mounted() {
            let self = this;
            document.addEventListener('click', function(event) {
                if(!self.$el.contains(event.target) && self.navbarDropdown == true) {
                    self.navbarToggleDropdown(false);
                }
            });
        }
    }
</script>

<style scoped>
    .navbar-filter-games {
        width: 210px;
        position: relative;
        z-index: 10;
    }

    .navbar-filter-games button.button-toggle {
        width: 100%;
        height: 39px;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #191e27;
        transition: all 0.3s ease;
    }

    .navbar-filter-games button.button-toggle:hover {
        color: #ffffff;
    }

    .navbar-filter-games.games-open button.button-toggle {
        color: #fd3b31;
        background: #252129;
        box-shadow: 0px 0px 0px 2px #59272b;
    }

    .navbar-filter-games button.button-toggle svg {
        fill: #626c7e;
        transition: all 0.3s ease;
    }

    .navbar-filter-games button.button-toggle svg.icon-games {
        margin-right: 10px;
    }

    .navbar-filter-games button.button-toggle svg.icon-state {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translate(0, -50%);
    }

    .navbar-filter-games.games-open button.button-toggle svg {
        fill: #fd3b31;
    }

    .navbar-filter-games.games-open button.button-toggle svg.icon-state {
        transform: translate(0, -50%) rotate(180deg);
    }

    .navbar-filter-games .games-menu {
        width: 100%;
        height: 0;
        position: absolute;
        top: 52px;
        left: 0;
        overflow: hidden;
        transition: height 0.2s ease;
    }

    .navbar-filter-games.games-open .games-menu {
        height: 337px;
    }

    .navbar-filter-games .menu-inner {
        width: 100%;
        padding: 3px;
        border-radius: 5px;
        background: #212732;
        border: 1px solid #191e27;
    }

    .navbar-filter-games .menu-inner a {
        width: 100%;
        height: 47px;
        display: flex;
        align-items: center;
        padding: 0 14px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        transition: 0.3s ease;
    }

    .navbar-filter-games .menu-inner a:hover {
        background: #191d26;
    }

    .navbar-filter-games .menu-inner a svg {
        margin-right: 12px;
        fill: #767c8b;
    }

    @media only screen and (max-width: 1300px) {

        .navbar-filter-games  {
            width: 100%;
            margin-top: 10px;
        }

        .content-links .navbar-filter-games {
            display: none;
        }

    }
</style>