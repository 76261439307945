<template>
    <div class="faq-question" v-bind:class="{ 'question-open': questionOpen === true }">
        <button v-on:click="questionToggle" class="button-toggle">
            <span>{{question.title}}</span>
            <IconArrowDown />
        </button>
        <div class="question-content" v-bind:style="{ 'max-height': questionContentHeight+'px' }">
            <div class="content-inner" ref="questionContent">
                {{question.answer}}
                <a v-if="question.link !== undefined" v-bind:href="question.link" target="_blank">{{question.link}}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import IconArrowDown from '@/components/icons/IconArrowDown';

    export default {
        name: 'FaqQuestion',
        components: {
            IconArrowDown
        },
        props: [
            'question'
        ],
        data() {
            return {
                questionOpen: false,
                questionContentHeight: 0
            }
        },
        methods: {
            questionToggle() {
                this.questionOpen = !this.questionOpen;
                this.questionContentHeight = this.questionOpen === true ? this.$refs.questionContent.clientHeight : 0;
            }
        }
    }
</script>

<style scoped>
    .faq-question {
        width: 100%;
        margin-top: 12px;
        border-radius: 5px;
        overflow: hidden;
        background: #212732;
        transition: all 0.3s ease;
    }

    .faq-question:first-child {
        margin-top: 0;
    }

    .faq-question button.button-toggle {
        width: 100%;
        height: 52px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
    }

    .faq-question button.button-toggle span {
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
        color: #767c8b;
        transition: all 0.3s ease;
    }

    .faq-question.question-open button.button-toggle span {
        color: #ffffff;
    }

    .faq-question button.button-toggle svg {
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .faq-question.question-open button.button-toggle svg {
        transform: rotate(180deg);
    }

    .faq-question .question-content {
        width: 100%;
        padding: 0 16px;
        overflow: hidden;
        transition: all 0.3s ease;
    }

    .faq-question .content-inner {
        padding: 16px 0;
        font-size: 14px;
        font-weight: 500;
        color: #767c8b;
        border-top: 1px solid #2c3443;
    }

    .faq-question .content-inner a {
        color: #fd3b31;
    }
</style>
