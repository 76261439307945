const state = {
    upgraderFilterAnimation: localStorage.getItem('upgraderAnimation') === null ? 'normal' : localStorage.getItem('upgraderAnimation'),
    upgraderGame: null,
    upgraderItemsData: {
        items: null,
        count: null,
        selected: [],
        loading: false,
        page: 1
    }
}

const getters = {
    upgraderFilterAnimation: state => state.upgraderFilterAnimation,
    upgraderGame: state => state.upgraderGame,
    upgraderItemsData: state => state.upgraderItemsData
}

const mutations = {
    upgrader_set_filter_animation(state, value) {
        state.upgraderFilterAnimation = value;
    },
    upgrader_set_game(state, game) {
        state.upgraderGame = game;
    },
    upgrader_set_items_data(state, data) {
        state.upgraderItemsData.items = data.items;
        state.upgraderItemsData.count = data.count;
    },
    upgrader_add_items_data_selected(state, item) {
        state.upgraderItemsData.selected.push(item);
    },
    upgrader_remove_items_data_selected(state, index) {
        state.upgraderItemsData.selected.splice(index, 1);
    },
    upgrader_empty_items_data_selected(state) {
        state.upgraderItemsData.selected = [];
    },
    upgrader_set_items_data_loading(state, status) {
        state.upgraderItemsData.loading = status;
    },
    upgrader_set_items_data_page(state, page) {
        state.upgraderItemsData.page = page;
    }
}


const actions = {
    upgraderSetFilterAnimation({ commit }, value) {
        localStorage.setItem('upgraderAnimation', value);
        commit('upgrader_set_filter_animation', value);
    },
    upgraderSetItemsDataPage({ commit }, page) {
        commit('upgrader_set_items_data_page', page);
    },
    upgraderSetGame({ commit }, game) {
        commit('upgrader_set_game', game);
    },
    upgraderAddItemsDataSelected({ commit }, item) {
        commit('upgrader_add_items_data_selected', item);
    },
    upgraderRemoveItemsDataSelected({ getters, commit }, item) {
        const index = getters.upgraderItemsData.selected.findIndex((element) => element._id === item._id);
        if(index !== -1) { commit('upgrader_remove_items_data_selected', index); }
    },
    upgraderEmptyItemsDataSelected({ commit }) {
        commit('upgrader_empty_items_data_selected');
    },
    upgraderGetItemsDataSocket({ getters, commit, dispatch }, data) {
        if(getters.upgraderItemsData.loading === true) { return; }
        commit('upgrader_set_items_data_loading', true);

        getters.socketClient.emit('upgrader:getItemsData', data, (res) => {
            if(res.success === true) {
                commit('upgrader_set_items_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('upgrader_set_items_data_loading', false);
        });
    },
    upgraderSendCreateSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'UpgraderCreate');

        getters.socketClient.emit('upgrader:sendCreate', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                commit('upgrader_set_game', res.game);

                setTimeout(() => { commit('upgrader_set_game', null); }, getters.upgraderFilterAnimation === 'fast' ? 4000 : 7000);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const upgrader = {
    state,
    mutations,
    actions,
    getters
}

export default upgrader;
