<template>
    <div class="modal-verify">
        <div class="verify-close">
            <button v-on:click="modalsSetShow(null)">
                <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0005 1.22222L9.77827 0L5.50019 4.27808L1.22222 0.000111474L0 1.22233L4.27797 5.5003L0.000501987 9.77776L1.22272 11L5.50019 6.72252L9.77776 11.0001L11 9.77788L6.72241 5.5003L11.0005 1.22222Z" />
                </svg>
                CLOSE
            </button>
        </div>
        <div class="verify-content">
            <div class="content-title">VERIFY BET</div>
            <div class="content-bet">
                <div class="bet-title">BET ID</div>
                <div class="bet-content">{{modalsData.bet._id}}</div>
            </div>
            <div class="content-system" v-bind:class="{ 'system-old': modalsData.bet.fair.seedOld !== undefined }">
                <div class="system-title">FAIRNESS SYSTEM</div>
                <div class="system-content">{{modalsData.bet.fair.seedOld !== undefined ? 'V1' : 'V2'}}</div>
            </div>
            <div class="content-seeds">
                <div class="seeds-element">
                    <div class="element-title">SERVER SEED</div>
                    <div class="element-content">{{modalGetServerSeed}}</div>
                </div>
                <div v-if="modalGetPublicSeed !== null" class="seeds-element element-public">
                    <div class="element-title">PUBLIC SEED</div>
                    <div class="element-content">{{modalGetPublicSeed}}</div>
                </div>
                <div v-else class="seeds-element element-nonce">
                    <div class="element-title">NONCE</div>
                    <div class="element-content">{{modalGetNonce}}</div>
                </div>
                <div class="seeds-element element-client">
                    <div class="element-title">CLIENT SEED</div>
                    <div class="element-content">{{modalsData.bet.fair.clientSeed}}</div>
                </div>
            </div>
            <div class="content-button">
                <button v-on:click="modalClose('Verify')" class="button-okay">OKAY</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'ModalVerify',
        methods: {
            ...mapActions([
                'modalsSetShow'
            ])
        },
        computed: {
            ...mapGetters([
                'modalsData'
            ]),
            modalGetServerSeed() {
                let seed = null;

                if(this.modalsData.bet.fair.seedOld !== undefined) {
                    seed = this.modalsData.bet.fair.seedOld.serverSeed;
                } else if(this.modalsData.bet.fair.seed.serverSeed !== undefined) {
                    seed = this.modalsData.bet.fair.seed.serverSeed;
                } else {
                    seed = this.modalsData.bet.fair.seed.hash;
                }

                return seed;
            },
            modalGetPublicSeed() {
                let seed = null;

                if(this.modalsData.bet.fair.seedOld !== undefined) {
                    seed = this.modalsData.bet.fair.seedOld.publicSeed;
                }

                return seed;
            },
            modalGetNonce() {
                let nonce = null;

                if(this.modalVerifyData.bet.fair.seed !== undefined) {
                    nonce = this.modalVerifyData.bet.fair.nonce;
                }

                return nonce;
            }
        }
    }
</script>

<style scoped>
    .modal-verify {
        width: 620px;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 25px 0 44px 0;
        border-radius: 18px;
        background: linear-gradient(180deg, #1A1E29 0%, #1C202C 100%);
        border-top: 32px solid #1e212e;
        border-bottom: 32px solid #1e212e;
    }

    .modal-verify .verify-close {
        position: absolute;
        top: -14px;
        right: 12px;
    }

    .modal-verify .verify-close button {
        height: 27px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
    }

    .modal-verify .verify-close button:hover {
        color: #ffffff;
    }

    .modal-verify .verify-close button svg {
        margin-right: 8px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .modal-verify .verify-close button:hover svg {
        fill: #ffffff;
    }

    .modal-verify .verify-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 44px;
    }

    .modal-verify .content-title {
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 800;
        color: #323743;
    }

    .modal-verify .content-bet {
        width: 100%;
        margin-top: 18px;
    }

    .modal-verify .content-system {
        width: 100%;
        margin-top: 12px;
    }

    .modal-verify .bet-title,
    .modal-verify .system-title {
        width: 100%;
        padding: 0 12px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .modal-verify .bet-content,
    .modal-verify .system-content {
        width: 100%;
        height: 54px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        padding: 0 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
        background: #212732;
    }

    .modal-verify .system-content {
        color: #00c74d;
    }

    .modal-verify .content-system.system-old .system-content {
        color: #fd3b31;
    }

    .modal-verify .content-seeds {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 12px;
    }

    .modal-verify .seeds-element {
        width: 100%;
    }

    .modal-verify .seeds-element.element-public,
    .modal-verify .seeds-element.element-nonce {
        width: calc(50% - 8px);
        margin-top: 12px;
        margin-right: 16px;
    }

    .modal-verify .seeds-element.element-client {
        width: calc(50% - 8px);
        margin-top: 12px;
    }

    .modal-verify .element-title {
        width: 100%;
        padding: 0 12px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .modal-verify .element-content {
        width: 100%;
        height: 54px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        padding: 0 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
        background: #212732;
    }

    .modal-verify .content-button {
        width: 370px;
        margin-top: 24px;
    }

    .modal-verify .content-button button.button-okay {
        width: 100%;
        height: 55px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .modal-verify .content-button button.button-okay:hover {
        background: #00de56;
    }

    @media only screen and (max-width: 650px) {

        .modal-verify {
            width: calc(100vw - 20px);
        }

        .modal-verify .validate-content {
            padding: 0 20px;
        }

    }

    @media only screen and (max-width: 450px) {

        .modal-verify .content-button {
            width: 100%;
        }

    }
</style>
