<template>
    <div  class="cases-reel">
        <div v-for="(item, index) in reel" v-bind:key="index" class="reel-element" v-bind:class="[{ 'element-active': index === pos }]">
            <img v-bind:src="item.item.image" />
            <Transition name="slide-fade">
                <div v-if="index === 60 && running === false" class="element-info">
                    <span>{{item.item.name}}</span>
                    <div class="info-amount">
                        <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                        <div class="amount-value">
                            <span>{{ generalFormatAmount(item.item.amountFixed).split('.')[0] }}</span>.{{ generalFormatAmount(item.item.amountFixed).split('.')[1] }}
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';

    export default {
        name: 'CasesReel',
        mixins: [
            mixins
        ],
        props: [
            'reel', 
            'pos', 
            'running'
        ]
    }
</script>

<style scoped>
    .cases-reel {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .cases-spinner.spinner-2 .cases-reel,
    .cases-spinner.spinner-3 .cases-reel,
    .cases-spinner.spinner-4 .cases-reel {
        width: 100%;
        flex-direction: column;
    }

    .cases-reel .reel-element {
        width: 105px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 25px;
        opacity: 0.25;
    }

    .cases-spinner.spinner-2 .reel-element,
    .cases-spinner.spinner-3 .reel-element,
    .cases-spinner.spinner-4 .reel-element {
        width: 100%;
        flex-direction: row;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .cases-reel .reel-element:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }

    .cases-reel .reel-element.element-active {
        opacity: 1;
    }

    .cases-reel .reel-element img {
        width: 105px;
        transition: all 0.2s ease;
    }

    .cases-reel .reel-element.element-active img {
        transform: scale(1.15);
    }

    .cases-reel .element-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }

    .cases-spinner.spinner-2 .element-info,
    .cases-spinner.spinner-3 .element-info,
    .cases-spinner.spinner-4 .element-info {
        width: auto;
        max-width: calc(100% - 115px);
        align-items: flex-start;
        margin-left: 15px;
    }

    .cases-reel .element-info.slide-fade-enter-active {
        transition: all 0.3s ease;
    }

    .cases-reel .element-info.slide-fade-enter-from {
        transform: translateY(-15px);
        opacity: 0;
    }

    .cases-spinner.spinner-2 .element-info.slide-fade-enter-from,
    .cases-spinner.spinner-3 .element-info.slide-fade-enter-from,
    .cases-spinner.spinner-4 .element-info.slide-fade-enter-from {
        transform: translateX(-15px);
    }

    .cases-reel .element-info span {
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        color: #596076;
    }

    .cases-spinner.spinner-2 .element-info span,
    .cases-spinner.spinner-3 .element-info span,
    .cases-spinner.spinner-4 .element-info span {
        width: 100%;
    }

    .cases-reel .info-amount {
        display: flex;
        align-items: center;
        margin-top: 3px;
    }

    .cases-reel .info-amount img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }

    .cases-reel .amount-value {
        font-size: 11px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .cases-reel .amount-value span {
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }
</style>