<template>
    <div class="mines-tile" v-bind:class="{ 'tile-revealed': minesData.game !== null && minesData.game.revealed.some((element) => element.tile === tile), 'tile-completed': minesData.game !== null && minesData.game.state === 'completed' }">
        <div class="tile-inner">
            <div class="inner-front" v-if="minesData.game !== null && minesGetRevealedTile !== null">
                <div v-if="minesGetRevealedTile === 'coin'" class="front-coin">
                    <img src="@/assets/img/icons/coin_big.svg" alt="coin-icon" />
                    <div v-if="minesData.game.revealed.some((element) => element.tile === tile)" class="coin-multiplier">
                        <div class="multiplier-amount">
                            <span>x{{minesGetMultiplierTile.split('.')[0]}}</span>.{{minesGetMultiplierTile.split('.')[1]}}
                        </div>
                    </div>
                </div>
                <div v-else class="front-mine">
                    <img src="@/assets/img/icons/bomb.svg" alt="bomb" />
                    <div v-if="minesData.game.revealed.some((element) => element.tile === tile)" class="mine-text">BOOM</div>
                </div>
            </div>
            <div class="inner-back">
                <button v-on:click="minesRevealTile()" class="button-reveal" v-bind:class="{ 'button-active': minesData.game !== null &&  minesData.game.state === 'created' }">
                    <img src="@/assets/img/logo_small.webp" alt="tiles-logo" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'MinesTile',
        mixins: [
            mixins
        ],
        props: [
            'tile'
        ],
        methods: {
            ...mapActions([
                'notificationShow', 
                'minesSendRevealSocket'
            ]),
            minesRevealTile() {
                if(this.socketSendLoading !== null) { return; }

                if(this.authUser.user === null) {
                    this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                    return;
                }

                if(this.minesData.game === null) {
                    this.notificationShow({type: 'error', message: 'You have no open mines game at the moment.' });
                    return;
                }

                this.minesSendRevealSocket({ tile: this.tile });
            }
        },
        computed: {
            ...mapGetters([
                'authUser', 
                'minesData', 
                'socketSendLoading'
            ]),
            minesGetRevealedTile() {
                if(this.minesData.game.state === 'completed') {
                    return this.minesData.game.deck[this.tile];
                } else {
                    const index = this.minesData.game.revealed.findIndex(element => element.tile === this.tile);
                    if(index !== -1) {
                        return this.minesData.game.revealed[index].value;
                    } else { return null; }
                }
            },
            minesGetMultiplierTile() {
                const index = this.minesData.game.revealed.findIndex((element) => element.tile === this.tile);

                const first = 25 === (index + 1) ? 1 : this.minesGetFactorialNumber(25) / (this.minesGetFactorialNumber(index + 1) * this.minesGetFactorialNumber(25 - (index + 1)));
                const second = (25 - this.minesData.game.minesCount) === (index + 1) ? 1 : this.minesGetFactorialNumber(25 - this.minesData.game.minesCount) / (this.minesGetFactorialNumber(index + 1) * this.minesGetFactorialNumber((25 - this.minesData.game.minesCount) - (index + 1)));
                let multiplier = 0.93 * (first / second);
                multiplier = multiplier < 1 ? 1.01 : multiplier;

                return parseFloat(Math.round(multiplier * 100) / 100).toFixed(2).toString();
            }
        }
    }
</script>

<style scoped>
    .mines-tile {
        width: 100px;
        height: 100px;
        perspective: 1000px;
    }

    .mines-tile .tile-inner {
        width: 100%;
        height: 100%;
        position: relative;
        transform-style: preserve-3d;
        transform: rotateY(-180deg);
    }

    .mines-tile.tile-revealed .tile-inner,
    .mines-tile.tile-completed .tile-inner {
        transform: rotateY(0deg);
        animation: tile-reveal 0.4s linear forwards;
    }

    @keyframes tile-reveal {
        0% {
            transform: rotateY(-180deg);
        }
        50% {
            transform: rotateY(-180deg);
        }
        100% {
            transform: rotateY(0deg);
        }
    }

    .mines-tile .inner-front,
    .mines-tile .inner-back {
        width: 100%;
        height: 100%;
        position: absolute;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .mines-tile .inner-front {
        width: 100%;
        height: 100%;
        opacity: 0.2;
        transition: all 0.3s ease;
    }

    .mines-tile.tile-revealed .inner-front {
        opacity: 1;
    }

    .mines-tile .front-coin {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: #0f2624;
        border: 1px solid #00c74d;
    }

    .mines-tile .front-coin img {
        width: 80px;
    }

    .mines-tile .coin-multiplier {
        width: 62px;
        height: 29px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        background: #3d645f;
    }

    .mines-tile .multiplier-amount {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .mines-tile .multiplier-amount span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .mines-tile .front-mine {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: #4a1d24;
    }

    .mines-tile .front-mine img {
        width: 90px;
    }

    .mines-tile .mine-text {
        width: 72px;
        height: 29px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-15deg);
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 700;
        color: #ff879a;
        background: #7d464f;
    }

    .mines-tile .inner-back {
        width: 100%;
        height: 100%;
        transform: rotateY(-180deg);
    }

    .mines-tile .inner-back button.button-reveal {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: #212732;
        border-bottom: 3px solid #1d222a;
        opacity: 0.5;
        cursor: not-allowed;
    }

    .mines-tile .inner-back button.button-reveal.button-active {
        opacity: 1;
        cursor: pointer;
    }

    .mines-tile .inner-back button.button-reveal img {
        width: 64px;
        height: 64px;
        mix-blend-mode: luminosity;
        transition: all 0.3s ease;
        opacity: 0.4;
    }

    .mines-tile .inner-back button.button-reveal.button-active:hover img {
        opacity: 0.30;
    }

    @media only screen and (max-width: 1100px) {

        .mines-tile {
            width: 80px;
            height: 80px;
            margin-right: 10px;
        }

        .mines-tile:nth-child(5n) {
            margin-right: 0;
        }

        .mines-tile .inner-back button.button-reveal img {
            width: 50px;
            height: 50px;
        }

        .mines-tile .front-coin img {
            width: 70px;
        }

        .mines-tile .front-mine img {
            width: 75px;
        }

    }

    @media only screen and (max-width: 550px) {

        .mines-tile {
            width: 65px;
            height: 65px;
            margin-right: 10px;
        }

        .mines-tile:nth-child(5n) {
            margin-right: 0;
        }

        .mines-tile .inner-back button.button-reveal img {
            width: 40px;
            height: 40px;
        }

        .mines-tile .front-coin img {
            width: 60px;
        }

        .mines-tile .front-mine img {
            width: 65px;
        }

        .mines-tile .coin-multiplier {
            width: 52px;
            height: 26px;
        }

        .mines-tile .multiplier-amount {
            font-size: 10px;
        }

        .mines-tile .multiplier-amount span {
            font-size: 13px;
        }

        .mines-tile .mine-text {
            width: 52px;
            height: 26px;
            font-size: 13px;
        }

    }

    @media only screen and (max-width: 425px) {

        .mines-tile {
            width: 55px;
            height: 55px;
            margin-right: 10px;
        }

        .mines-tile:nth-child(5n) {
            margin-right: 0;
        }

        .mines-tile .inner-back button.button-reveal img {
            width: 35px;
            height: 35px;
        }

        .mines-tile .front-coin img {
            width: 50px;
        }

        .mines-tile .front-mine img {
            width: 55px;
        }

        .mines-tile .coin-multiplier {
            width: 45px;
            height: 22px;
        }

        .mines-tile .multiplier-amount {
            font-size: 9px;
        }

        .mines-tile .multiplier-amount span {
            font-size: 12px;
        }

        .mines-tile .mine-text {
            width: 45px;
            height: 22px;
            font-size: 12px;
        }

    }

    @media only screen and (max-width: 375px) {

        .mines-tile {
            width: 50px;
            height: 50px;
            margin-right: 10px;
        }

        .mines-tile:nth-child(5n) {
            margin-right: 0;
        }

        .mines-tile .inner-back button.button-reveal img {
            width: 30px;
            height: 30px;
        }

        .mines-tile .front-coin img {
            width: 45px;
        }

        .mines-tile .front-mine img {
            width: 50px;
        }

        .mines-tile .coin-multiplier {
            width: 40px;
            height: 20px;
        }

        .mines-tile .multiplier-amount {
            font-size: 8px;
        }

        .mines-tile .multiplier-amount span {
            font-size: 10px;
        }

        .mines-tile .mine-text {
            width: 40px;
            height: 20px;
            font-size: 10px;
        }

    }
</style>
