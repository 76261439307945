<template>
    <div class="upgrader-items">
        <div class="items-header">
            <FilterSearch v-bind:filterValue="upgraderFilterSearch" v-on:setFilterValue="upgraderSetFilterSearch" />
            <div class="header-filter">
                <FilterItemPrice v-bind:filterValue="upgraderFilterPrice" v-on:setFilterValue="upgraderSetFilterPrice" />
                <FilterItemSort v-bind:filterValue="upgraderFilterSort" v-on:setFilterValue="upgraderSetFilterSort" />
            </div>
        </div>
        <div class="items-content">
            <transition name="fade" mode="out-in">
                <div v-if="upgraderItemsData.loading === true" class="content-loading" key="loading">
                    <LoadingAnimation />
                </div>
                <div v-else-if="upgraderItemsData.items !== null && upgraderItemsData.items.length > 0" class="content-items" key="data">

                    <SteamItemElement v-for="item of upgraderGetItems" v-bind:key="item.name" v-bind:item="item" />

                </div>
                <div v-else class="content-empty" key="empty">NO ITEMS FOUND</div>
            </transition>
        </div>

        <Pagination v-on:setPage="upgraderSetPage" v-bind:page="upgraderItemsData.page" v-bind:count="upgraderItemsData.count" countPage="90" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import Pagination from '@/components/Pagination';
    import FilterSearch from '@/components/filters/FilterSearch';
    import FilterItemPrice from '@/components/filters/FilterItemPrice';
    import FilterItemSort from '@/components/filters/FilterItemSort';
    import SteamItemElement from '@/components/steam/SteamItemElement';

    export default {
        name: 'UpgraderItems',
        components: {
            LoadingAnimation,
            Pagination,
            FilterSearch,
            FilterItemPrice,
            FilterItemSort,
            SteamItemElement
        },
        data() {
            return {
                upgraderFilterSearch: '',
                upgraderFilterPrice: 'all',
                upgraderFilterSort: 'descending'
            }
        },
        methods: {
            ...mapActions([
                'upgraderSetItemsDataPage',
                'upgraderGetItemsDataSocket'
            ]),
            upgraderSetFilterSearch(value) {
                this.upgraderFilterSearch = value;
                this.upgraderSetPage(1);
            },
            upgraderSetFilterPrice(value) {
                this.upgraderFilterPrice = value;
                this.upgraderSetPage(1);
            },
            upgraderSetFilterSort(value) {
                this.upgraderFilterSort = value;
                this.upgraderSetPage(1);
            },
            upgraderSetPage(page) {
                this.upgraderSetItemsDataPage(page);

                this.upgraderGetItemsDataSocket({ 
                    page: this.upgraderItemsData.page, 
                    search: this.upgraderFilterSearch, 
                    select: this.upgraderFilterPrice,
                    sort: this.upgraderFilterSort 
                });
            }
        },
        computed: {
            ...mapGetters([
                'upgraderItemsData'
            ]),
            upgraderGetItems() {
                let items = [];

                for(let item of this.upgraderItemsData.items) {
                    items.push({
                        _id: item._id,
                        name: item.name,
                        image: item.image,
                        amount: item.amount,
                        count: 1,
                        accepted: true
                    });
                }

                return items;
            }
        },
        created() {
            this.upgraderGetItemsDataSocket({ 
                page: this.upgraderItemsData.page, 
                search: this.upgraderFilterSearch,
                select: this.upgraderFilterPrice, 
                sort: this.upgraderFilterSort 
            });
        }
    }
</script>

<style scoped>
    .upgrader-items {
        width: 100%;
        margin-top: 35px;
    }

    .upgrader-items .items-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    .upgrader-items .header-filter {
        display: flex;
        align-items: center;
    }

    .upgrader-items .items-content {
        width: 100%;
        margin-top: 26px;
    }

    .upgrader-items .content-loading {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .upgrader-items .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .upgrader-items .content-loading.fade-leave-to {
        opacity: 0;
    }

    .upgrader-items .content-items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .upgrader-items .content-empty {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #626c7e;
    }

    .upgrader-items .content-items.fade-enter-active,
    .upgrader-items .content-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .upgrader-items .content-items.fade-enter-from,
    .upgrader-items .content-empty.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1100px) {

        .upgrader-items .items-header {
            flex-direction: column;
            align-items: flex-start;
        }

        .upgrader-items .header-search {
            width: 100%;
        }

        .upgrader-items .header-search input {
            width: 100%;
        }

        .upgrader-items  .header-filter {
            margin-top: 26px;
        }

    }

    @media only screen and (max-width: 475px) {

        .upgrader-items .header-filter {
            flex-direction: column;
            align-items: flex-start;
        }

    }
</style>
