<template>
    <div class="upgrader-game">
        <div class="game-actions">
            <div class="actions-item">
                <div class="item-image">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                </div>
                <div class="item-amount">{{parseFloat(upgraderAmount).toFixed(2)}}</div>
            </div>
            <div class="actions-controls">
                <div class="controls-input">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <input 
                        v-model="upgraderAmount" 
                        v-on:input="upgraderAmount = generalFormatInputAmount(upgraderAmount)" 
                        v-bind:disabled="upgraderGame !== null" type="text" />
                    <div class="content-buttons">
                        <button v-on:click="upgraderSetAmount('1/2')" class="button-amount" v-bind:disabled="upgraderGame !== null">1/2</button>
                        <button v-on:click="upgraderSetAmount('x2')" class="button-amount" v-bind:disabled="upgraderGame !== null">x2</button>
                        <button v-on:click="upgraderSetAmount('max')" class="button-amount" v-bind:disabled="upgraderGame !== null">MAX</button>
                    </div>
                </div>
                <div class="controls-slider">
                    <input 
                        v-model="upgraderAmount" 
                        type="range" 
                        v-bind:min="upgraderGetAmountMin" 
                        v-bind:max="upgraderGetAmountMax" 
                        step="0.01" 
                        v-bind:style="{ 'background-image': '-webkit-gradient(linear, left top, right top, color-stop('+upgraderGetPercentageAmount+'%, #00c74d), color-stop('+upgraderGetPercentageAmount+'%, #212732))' }"
                        v-bind:disabled="upgraderGame !== null"
                    >
                </div>
            </div>
        </div>
        <div class="game-mid">
            <div class="mid-background">
                <div class="background-inner">
                    <div class="inner-border"></div>
                    <div class="inner-border"></div>
                </div>
            </div>
            <div class="mid-graph" v-bind:class="{ 'graph-over': upgraderMode === 'over' }">
                <svg width="550px" height="550px" viewBox="0 0 33.7 33.7" xmlns="http://www.w3.org/2000/svg">
                    <circle class="graph-ring" cx="16.85" cy="16.85" r="15.91549430918954"></circle>
                    <circle class="graph-progress" cx="16.85" cy="16.85" r="15.91549430918954" v-bind:stroke-dasharray="upgraderGetChance+' '+ (100 - upgraderGetChance)"></circle>
                </svg>
            </div>
            <div class="mid-arrow" v-bind:style="upgraderArrowStyle">
                <svg width="24" height="21" viewBox="0 0 24 21" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 12 L12 0 L24 12 L24 21 L12 9 L0 21 Z" fill="#ffffff"></path>
                </svg>
            </div>
            <div class="mid-content">
                <div class="content-percentage">{{upgraderGetChance}}%</div>
                <div class="content-actions">
                    <div class="actions-mode">
                        <button 
                            v-on:click="upgraderSetMode('under')" 
                            class="button-mode" 
                            v-bind:class="{ 'button-active': upgraderMode === 'under' }"
                            v-bind:disabled="upgraderGame !== null"
                        >ROLL UNDER</button>
                        <button 
                            v-on:click="upgraderSetMode('over')" 
                            class="button-mode" 
                            v-bind:class="{ 'button-active': upgraderMode === 'over' }"
                            v-bind:disabled="upgraderGame !== null"
                        >ROLL OVER</button>
                    </div>
                    <button 
                        v-on:click="upgraderSetFilterAnimation(upgraderFilterAnimation === 'fast' ? 'normal' : 'fast')" 
                        class="button-animation" 
                        v-bind:class="{ 'button-active': upgraderFilterAnimation === 'fast' }"
                        v-bind:disabled="upgraderGame !== null"
                    >FAST ANIMATION</button>
                    <button 
                        v-on:click="upgraderCreateButton()" 
                        class="button-upgrade" 
                        v-bind:disabled="socketSendLoading !== null || upgraderGame !== null"
                    >UPGRADE</button>
                </div>
            </div>
        </div>
        <div class="game-target">
            <div class="target-item" v-bind:class="upgraderItemClass">
                <div v-if="upgraderItemsData.selected.length > 0" class="item-image" v-bind:class="{ 'image-multi': upgraderItemsData.selected.length > 1 }">
                    <img v-for="(item, index) in upgraderItemsData.selected" v-bind:key="index" v-bind:src="item.image" alt="item-image" />
                </div>
                <div v-if="upgraderItemsData.selected.length > 0" class="item-amount">{{(Math.floor(upgraderGetAmountPayout / 10) / 100).toFixed(2)}}</div>
            </div>
            <button 
                v-on:click="upgraderEmptyItemsDataSelected()" 
                class="button-clear" 
                v-bind:disabled="upgraderGame !== null"
            >CLEAR SELECTION</button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import mixins from '@/mixins';

    export default {
        name: 'UpgraderGame',
        mixins: [
            mixins
        ],
        data() {
            return {
                upgraderAmount: '0.00',
                upgraderMode: 'under',
                upgraderItemClass: '',
                upgraderArrowStyle: { 'transform': 'translate(-50%, -50%)' }
            }
        },
        methods: {
            ...mapActions([
                'notificationShow', 
                'upgraderSetFilterAnimation',
                'upgraderEmptyItemsDataSelected', 
                'upgraderSendCreateSocket'
            ]),
            upgraderSetAmount(action) {
                let amount = Math.floor(this.upgraderAmount * 1000);

                if(action === '1/2') {
                    amount = Math.floor(amount / 2);
                } else if(action === 'x2') {
                    amount = Math.floor(amount * 2);
                } else if(action === 'max') {
                    amount = this.authUser.user.balance;
                }

                amount = amount >= Math.floor(this.upgraderGetAmountMax * 1000) ? Math.floor(this.upgraderGetAmountMax * 1000) : amount;
                this.upgraderAmount = (Math.floor(amount / 10) / 100).toFixed(2);
            },
            upgraderSetMode(mode) {
                this.upgraderMode = mode;
            },
            upgraderCreateButton() {
                if(this.authUser.user === null) {
                    this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                    return;
                }

                if(isNaN(this.upgraderAmount) === true || Number(this.upgraderAmount) <= 0) {
                    this.notificationShow({type: 'error', message: 'Your provided bet amount is invalid.'});
                    return;
                }

                this.upgraderSendCreateSocket({ 
                    amount: Math.floor(this.upgraderAmount * 1000),
                    amountPayout: this.upgraderGetAmountPayout, 
                    mode: this.upgraderMode
                });
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'authUser', 
                'upgraderFilterAnimation', 
                'upgraderItemsData',
                'upgraderGame'
            ]),
            upgraderGetAmountPayout() {
                let amount = 0;

                for(let item of this.upgraderItemsData.selected) {
                    amount = amount + item.amount;
                }

                return amount;
            },
            upgraderGetAmountMin() {
                let amount = '0.00';

                if(this.authUser.user !== null && this.authUser.user.balance >= 10 && this.upgraderItemsData.selected.length >= 1) {
                    amount = '0.01';
                }

                return amount;
            },
            upgraderGetAmountMax() {
                let amount = '0.00';

                if(this.authUser.user !== null && this.authUser.user.balance < this.upgraderGetAmountPayout) {
                    amount = parseFloat(Math.floor(this.authUser.user.balance / 10) / 100).toFixed(2);
                } else if(this.upgraderItemsData.selected.length >= 1) {
                    amount = parseFloat(Math.floor((this.upgraderGetAmountPayout - 10) / 10) / 100).toFixed(2)
                }

                return amount;
            },
            upgraderGetPercentageAmount() {
                let amount = 0;

                if(this.upgraderItemsData.selected.length >= 1) {
                     amount = (100 / this.upgraderGetAmountMax) * this.upgraderAmount;
                }

                return amount;
            },
            upgraderGetMultiplier() {
                return  Math.floor((this.upgraderGetAmountPayout / Math.floor(this.upgraderAmount * 1000)) * 1000000) / 1000000;
            },
            upgraderGetChance() {
                return this.upgraderItemsData.selected.length === 0 ? '0.00' : parseFloat(Math.floor(9000 / this.upgraderGetMultiplier) / 100).toFixed(2);
            }
        },
        watch: {
            'upgraderItemsData': {
                handler() {
                    if(this.upgraderItemsData.selected.length >= 1 && this.upgraderAmount >= parseFloat(Math.floor(this.upgraderGetAmountPayout / 10) / 100)) {
                        this.upgraderAmount = parseFloat(Math.floor((this.upgraderGetAmountPayout - 10) / 10) / 100).toFixed(2);
                    } else if(this.upgraderItemsData.selected.length === 0) {
                        this.upgraderAmount = '0.00';
                    }
                },
                deep: true
            },
            'upgraderGame': {
                handler() {
                    if(this.upgraderGame === null) {
                        this.upgraderItemClass = '';
                        this.upgraderArrowStyle = { 'transform': 'translate(-50%, -50%) rotate(0deg)' };
                    } else if(this.upgraderGame.outcome !== undefined && (this.upgraderGame.state === 'created' || this.upgraderGame.state === 'completed')) {
                        const time = this.upgraderFilterAnimation === 'fast' ? 2 : 5;

                        this.upgraderArrowStyle = {
                            'transform': 'translate(-50%, -50%) rotate('+ ((360 * 3) + (360 / 100) * this.upgraderGame.outcome) +'deg)',
                            'transition': 'transform '+ time +'s cubic-bezier(0.15,0.75,0.1,1)'
                        };

                        setTimeout(() => {
                            this.upgraderItemClass = this.upgraderGame.payout > 0 ? 'item-won' : 'item-lost';

                            setTimeout(() => {
                                this.upgraderArrowStyle = {
                                    'transform': 'translate(-50%, -50%) rotate('+(360 * 3)+'deg)' ,
                                    'transition': 'transform 0.5s ease'
                                };
                            }, 1500);
                        }, 1000 * time);
                    }
                },
                deep: true
            }
        }
    }
</script>

<style scoped>
    .upgrader-game {
        width: 100%;
        height: 430px;
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .upgrader-game .game-actions,
    .upgrader-game .game-target {
        width: 50%;
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #11141f;
    }

    .upgrader-game .game-actions {
        padding-right: 230px;
        border-radius: 18px 0 0 18px;
    }

    .upgrader-game .game-target {
        padding-left: 230px;
        border-radius: 0 18px 18px 0;
    }

    .upgrader-game .actions-item,
    .upgrader-game .target-item {
        width: 221px;
        height: 221px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        background: linear-gradient(180deg, #1a1d29 0%, #182b2d 100%);
        border: 1px solid #00c74d;
        transition: all 0.5s ease;
    }

    .upgrader-game .actions-item::before {
        content: '';
        width: 100px;
        height: 3px;
        position: absolute;
        top: 50%;
        right: -101px;
        transform: translate(0, -50%);
        background: #00c74d;
        box-shadow: 0px 0px 12px #00C74d;
    }

    .upgrader-game .target-item {
        border: 1px solid #077f3a;
    }

    .upgrader-game .target-item.item-won {
        background: linear-gradient(180deg, #1a1d29 0%, #182b2d 100%);
        border: 1px solid #00c74d;
        box-shadow: 0px 0px 12px #00C74d;
    }

    .upgrader-game .target-item.item-lost {
        background: linear-gradient(180deg, #1a1d29 0%, #2d1818 100%);
        border: 1px solid #fd3b31;
        box-shadow: 0px 0px 12px #fd3b31;
    }

    .upgrader-game .target-item::before {
        content: '';
        width: 100px;
        height: 3px;
        position: absolute;
        top: 50%;
        left: -101px;
        transform: translate(0, -50%);
        background: #262c3a;
    }

    .upgrader-game .target-item::after {
        content: '';
        width: 0;
        height: 3px;
        position: absolute;
        top: 50%;
        left: -101px;
        transform: translate(0, -50%);
        transition: width 0.5s ease;
    }

    .upgrader-game .target-item.item-won::after {
        width: 100px;
        background: #00c74d;
        box-shadow: 0px 0px 12px #00C74d;
    }

    .upgrader .target-item.item-lost::after {
        width: 100px;
        background: #fd3b31;
        box-shadow: 0px 0px 12px #fd3b31;
    }

    .upgrader-game .item-image {
        width: 100px;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
    }

    .upgrader-game .item-image img {
        width: 100%;
    }

    .upgrader-game .item-image.image-multi img {
        width: 40%;
        height: 40%;
    }

    .upgrader-game .item-amount {
        margin-top: 8px;
        font-size: 21px;
        font-weight: 700;
        color: #ffffff;
    }

    .upgrader-game .actions-controls {
        width: 330px;
        margin-top: 21px;
    }

    .upgrader-game .controls-input {
        width: 100%;
        position: relative;
        border-radius: 8px;
        background: #191e27;
        border: 1px solid #262C3A;
    }

    .upgrader-game .controls-input img {
        width: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
    }

    .upgrader-game .controls-input input {
        width: 100%;
        height: 58px;
        padding: 0 160px 0 54px;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .upgrader-game .bet-input input:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .upgrader-game .content-buttons {
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
    }

    .upgrader-game  button.button-amount {
        margin: 0 15px;
        padding: 0;
        font-size: 14px;
        font-weight: 700;
        color: #626c7e;
    }

    .upgrader-game button.button-amount:not([disabled]):hover {
        color: #ffffff;
    }

    .upgrader-game .controls-slider {
        width: 100%;
        margin-top: 16px;
        padding: 0 8px;
    }

    .upgrader-game .controls-slider input {
        width: 100%;
        height: 4px;
        border-radius: 2px;
        outline: none;
        -webkit-appearance: none;
        -moz-apperance: none;
        background: #212732;
        transition: all 0.03s ease;
    }

    .upgrader-game .controls-slider input::-webkit-slider-thumb {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background: #ffffff;
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
    }

    .upgrader-game .controls-slider input::-moz-range-thumb {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background: #ffffff;
        cursor: pointer;
    }

    .upgrader-game button.button-clear {
        height: 41px;
        margin-top: 47px;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    .upgrader-game  button.button-clear:not([disabled]):hover {
        color: #ffffff;
    }

    .upgrader-game .game-mid {
        width: 480px;
        height: 480px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background: #181b23;
        z-index: 1;
    }

    .upgrader-game .mid-background {
        width: 440px;
        height: 440px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
        box-shadow: 0px 0px 20px 5px rgba(0 ,0 ,0 , 0.25);
    }

    .upgrader-game .background-inner {
        width: 390px;
        height: 390px;
        border-radius: 50%;
        box-shadow: inset 0px 0px 100px 10px rgba(0 ,0 ,0 , 0.25);
    }

    .upgrader-game .inner-border:nth-child(1) {
        width: 340px;
        height: 340px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        border: 1px solid #1a1e29;
    }

    .upgrader-game .inner-border:nth-child(2) {
        width: 290px;
        height: 290px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        border: 1px solid #212429;
    }

    .upgrader-game .mid-graph {
        width: 440px;
        height: 440px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .upgrader-game .mid-graph.graph-over {
        transform: translate(-50%, -50%) scaleX(-1);
    }

    .upgrader-game .graph-ring {
        fill: none;
        stroke-width: 0.5;
        stroke: #212732;
    }

    .upgrader-game .graph-progress {
        fill: none;
        stroke-width: 0.5;
        stroke-dashoffset: 25;
        stroke: #00C74d;
    }

    .upgrader-game .mid-arrow {
        height: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
    }

    .upgrader-game .mid-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .upgrader-game .content-percentage {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 26px;
        font-weight: 700;
        color: #ffffff;
    }

    .upgrader-game .content-actions {
        width: 240px;
        margin-top: 12px;
    }

    .upgrader-game button.button-animation {
        width: 100%;
        height: 30px;
        margin-top: 8px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #1d222a;
    }

    .upgrader-game button.button-animation.button-active {
        color: #fd3b31;
        background: #252129;
        box-shadow: 0px 0px 0px 1px #59272b;
    }

    .upgrader-game button.button-upgrade {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        margin-top: 12px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .upgrader-game button.button-upgrade:not([disabled]):hover {
        background: #00de56;
    }

    .upgrader-game .actions-mode {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .upgrader-game button.button-mode {
        width: calc(50% - 5px);
        height: 30px;
        margin-top: 8px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #1d222a;
    }

    .upgrader-game button.button-mode.button-active {
        color: #fd3b31;
        background: #252129;
        box-shadow: 0px 0px 0px 1px #59272b;
    }

    @media only screen and (max-width: 1200px) {

        .upgrader-game {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .upgrader-game .game-actions,
        .upgrader-game .game-target {
            width: 100%;
            height: 573px;
            border-radius: 18px;
        }

        .upgrader-game .game-actions {
            margin-bottom: 100px;
            padding: 50px 0 190px 0;
        }

        .upgrader-game .game-target {
            margin-top: 100px;
            padding: 190px 0 50px 0;
        }

        .upgrader-game .actions-item::before {
            display: none;
        }

        .upgrader-game .target-item::before {
            display: none;
        }

    }
</style>
