export default {
    methods: {
        userGetLevelColor(level) {
            let color = 'bronze';

            if(level > 80) { color = 'pink'; }
            else if(level > 60) { color = 'platin'; }
            else if(level > 40) { color = 'gold'; }
            else if(level > 20) { color = 'silver'; }

            return color;
        }
    }
};