const state = {
    leaderboardData: {
        leaderboard: null,
        loading: false
    }
}

const getters = {
    leaderboardData: state => state.leaderboardData
}

const mutations = {
    leaderboard_set_data_leaderboard(state, leaderboard) {
        state.leaderboardData.leaderboard = leaderboard;
    },
    leaderboard_set_data_loading(state, status) {
        state.leaderboardData.loading = status;
    }
}

const actions = {
    leaderboardGetDataSocket({ getters, commit, dispatch }, data) {
        if(getters.leaderboardData.loading === true) { return; }
        commit('leaderboard_set_data_loading', true);

        getters.socketClient.emit('general:leaderboard:getData', data, (res) => {
            if(res.success === true) {
                commit('leaderboard_set_data_leaderboard', res.leaderboard);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('leaderboard_set_data_loading', false);
        });
    }
}

const giveaways = {
    state,
    mutations,
    actions,
    getters
}

export default giveaways;
