<template>
  <div class="battles-create">
    <div class="create-header">
      <router-link to="/battles" class="link-back">GO BACK</router-link>
      <button @click="battlesCreateButton()" class="button-create" :disabled="socketSendLoading !== null">
        CREATE BATTLE
        <img src="@/assets/img/icons/coins.webp" alt="icons-image" />
        <div class="button-value">
          <span>{{ generalFormatAmount(battlesGetCost).split('.')[0] }}</span>.{{ generalFormatAmount(battlesGetCost).split('.')[1] }}
        </div>
      </button>
    </div>
    <div class="create-filters">
      <BattlesFilterMode
        @setMode="battlesSetMode"
        :mode="battlesMode"
      />
      <div class="filters-actions">
        <button @click="battlesSetType('standard')" :class="['button-type', { 
          'button-active': battlesType === 'standard' 
        }]">STANDARD</button>
        <button @click="battlesSetType('group')" :class="['button-type', { 
          'button-active': battlesType === 'group' 
        }]">GROUP</button>
      </div>
      <div class="filters-cases">
        TOTAL CASES <span>{{ this.battlesSelected.length }}</span> 
      </div>
    </div>
    <div class="create-cases">
      <button @click="modalsSetShow('BattlesSelect')" class="button-add">
        <div class="button-inner">
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
            <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
          </svg>
        </div>
        <span>ADD CASE</span>
      </button>

      <BattlesBoxElement v-for="box of battlesGetBoxes" :key="box._id" :box="box" />
    </div>
    <div class="create-options">
        <div class="options-element element-crazy">
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path d="M416 398.9c58.5-41.1 96-104.1 96-174.9C512 100.3 397.4 0 256 0S0 100.3 0 224c0 70.7 37.5 133.8 96 174.9c0 .4 0 .7 0 1.1v64c0 26.5 21.5 48 48 48h48V464c0-8.8 7.2-16 16-16s16 7.2 16 16v48h64V464c0-8.8 7.2-16 16-16s16 7.2 16 16v48h48c26.5 0 48-21.5 48-48V400c0-.4 0-.7 0-1.1zM96 256a64 64 0 1 1 128 0A64 64 0 1 1 96 256zm256-64a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
          </svg>
          <div class="element-text">
            <span>Crazy Mode</span>
            The team with the lowest amount opened wins. Loser wins!
          </div>
          <button @click="battlesSetCrazy(!battlesCrazy)" :class="['button-toggle', { 
            'button-active': battlesCrazy === true 
          }]">
            <span class="toggle-slider"></span>
          </button>
        </div>
        <div class="options-element element-funding">
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
            <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path d="M344 24V34.5c6.4 1.2 12.6 2.7 18.2 4.2c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17c-10.9-2.9-21.1-4.9-30.2-5c-7.3-.1-14.7 1.7-19.4 4.4c-2.1 1.3-3.1 2.4-3.5 3c-.3 .5-.7 1.2-.7 2.8c0 .3 0 .5 0 .6c.2 .2 .9 1.2 3.3 2.6c5.8 3.5 14.4 6.2 27.4 10.1l.9 .3c11.1 3.3 25.9 7.8 37.9 15.3c13.7 8.6 26.1 22.9 26.4 44.9c.3 22.5-11.4 38.9-26.7 48.5c-6.7 4.1-13.9 7-21.3 8.8V232c0 13.3-10.7 24-24 24s-24-10.7-24-24V220.6c-9.5-2.3-18.2-5.3-25.6-7.8l0 0c-2.1-.7-4.1-1.4-5.9-2c-12.6-4.2-19.4-17.8-15.2-30.4s17.8-19.4 30.4-15.2c2.6 .9 5 1.7 7.3 2.5c13.6 4.6 23.4 7.9 33.9 8.3c8 .3 15.1-1.6 19.2-4.1c1.9-1.2 2.8-2.2 3.2-2.9c.4-.6 .9-1.8 .8-4.1l0-.2c0-1 0-2.1-4-4.6c-5.7-3.6-14.3-6.4-27.1-10.3l-1.9-.6c-10.8-3.2-25-7.5-36.4-14.4c-13.5-8.1-26.5-22-26.6-44.1c-.1-22.9 12.9-38.6 27.7-47.4c6.4-3.8 13.3-6.4 20.2-8.2V24c0-13.3 10.7-24 24-24s24 10.7 24 24zM40 64c22.1 0 40 17.9 40 40v40 80 40.2c0 17 6.7 33.3 18.7 45.3l51.1 51.1c8.3 8.3 21.3 9.6 31 3.1c12.9-8.6 14.7-26.9 3.7-37.8l-15.2-15.2-32-32c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l32 32 15.2 15.2 0 0 25.3 25.3c21 21 32.8 49.5 32.8 79.2V464c0 26.5-21.5 48-48 48H173.3c-17 0-33.3-6.7-45.3-18.7L28.1 393.4C10.1 375.4 0 351 0 325.5V224 160 104C0 81.9 17.9 64 40 64zm560 0c22.1 0 40 17.9 40 40v56 64V325.5c0 25.5-10.1 49.9-28.1 67.9L512 493.3c-12 12-28.3 18.7-45.3 18.7H400c-26.5 0-48-21.5-48-48V385.1c0-29.7 11.8-58.2 32.8-79.2l25.3-25.3 0 0 15.2-15.2 32-32c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-32 32-15.2 15.2c-11 11-9.2 29.2 3.7 37.8c9.7 6.5 22.7 5.2 31-3.1l51.1-51.1c12-12 18.7-28.3 18.7-45.3V224 144 104c0-22.1 17.9-40 40-40z"/>
          </svg>
          <div class="element-text">
            <span>Partial Slot Funding</span>
            Choose to pay a percent of other players slots.
          </div>
          <button @click="battlesSetFunding(!battlesFunding)" :class="['button-toggle', { 
            'button-active': battlesFunding === true 
          }]">
            <span class="toggle-slider"></span>
          </button>
        </div>
        <div class="options-element element-private">
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
            <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
          </svg>
          <div class="element-text">
            <span>Private Mode</span>
            Hide this game from the battles page. Only joinable via link.
          </div>
          <button @click="battlesSetPrivate(!battlesPrivate)" :class="['button-toggle', { 
            'button-active': battlesPrivate === true 
          }]">
            <span class="toggle-slider"></span>
          </button>
        </div>
    </div>
    <div v-show="battlesFunding === true" class="create-funding">
        Set Funding Percentage
        <div class="funding-content">
          {{ battlesFundingPercentage }}
          <input type="range" min="1" max="100" step="1" v-model="battlesFundingPercentage" :style="{ 'background-image': '-webkit-gradient(linear, left top, right top, color-stop('+battlesFundingPercentage / 100+', #00c74d), color-stop('+battlesFundingPercentage / 100+', #191e27))' }">
        </div>
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import { mapGetters, mapActions } from 'vuex';
  import BattlesFilterMode from '@/components/battles/BattlesFilterMode';
  import BattlesBoxElement from '@/components/battles/BattlesBoxElement';

  export default {
    components: {
      BattlesFilterMode,
      BattlesBoxElement
    },
    mixins: [
      mixins
    ],
    data() {
      return {
        battlesMode: '1v1',
        battlesType: 'standard',
        battlesCrazy: false,
        battlesFunding: false,
        battlesFundingPercentage: 50,
        battlesPrivate: false
      }
    },
    methods: {
      ...mapActions([
        'modalsSetShow',
        'battlesResetFilter',
        'battlesSendCreateSocket'
      ]),
      battlesSetMode(value) {
        this.battlesMode = value;
        if(value === '2v2' && this.battlesType === 'group') { this.battlesType = 'standard'; }
      },
      battlesSetType(value) {
        this.battlesType = value;
        if(value === 'group' && this.battlesMode === '2v2') { this.battlesMode = '1v1v1v1'; }
      },
      battlesSetCrazy(value) {
        this.battlesCrazy = value;
      },
      battlesSetFunding(value) {
        if(value === false) { this.battlesFundingPercentage = 50; }
        this.battlesFunding = value;
      },
      battlesSetPercentage(value) {
        this.battlesFundingPercentage = value;
      },
      battlesSetPrivate(value) {
        this.battlesPrivate = value;
      },
      battlesCreateButton() {
        let boxes = [];

        for(let selected of this.battlesSelected) {
          let index = boxes.findIndex((element) => element._id === selected._id);

          if(index !== -1) { boxes[index].count = boxes[index].count + 1; }
          else { boxes.push({ _id: selected._id, count: 1 }); }
        }

        const mode = this.battlesMode === '2v2' ? 'team' : this.battlesType;
        const funding = this.battlesFunding === true ? this.battlesFundingPercentage : 0;

        this.battlesSendCreateSocket({ playerCount: this.battlesGetCountPlayer, mode: mode, boxes: boxes, funding: funding, crazy: this.battlesCrazy, private: this.battlesPrivate });
      }
    },
    computed: {
      ...mapGetters([
        'socketSendLoading',
        'battlesSelected'
      ]),
      battlesGetBoxes() {
        let boxes = [];

        for(const box of this.battlesSelected) {
          if(boxes.some((element) => element._id === box._id) === false) { boxes.push(box); }
        }

        boxes.sort(function(a, b) { return b.amount - a.amount; });

        return boxes;
      },
      battlesGetCountPlayer() {
        let count = 2;

        if(this.battlesMode === '2v2' || this.battlesMode === '1v1v1v1') { count = 4; }
        else if(this.battlesMode === '1v1v1') { count = 3; }

        return count;
      },
      battlesGetCost() {
        let cost = 0;

        for(let box of this.battlesSelected) { cost = Math.floor(cost + box.amount); }
        if(this.battlesFunding === true) { cost = Math.floor(cost + (cost * (this.battlesGetCountPlayer - 1) * Math.floor(this.battlesFundingPercentage) / 100)); }

        return cost;
      }
    },
    beforeRouteLeave(to, from, next) {
      this.battlesResetFilter();
      next();
    }
  }
</script>

<style scoped>
  .battles-create {
    width: 100%;
  }

  .battles-create .create-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .battles-create a.link-back {
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #767c8b;
    background: #212732;
    box-shadow: 0px 2px 0px 0px #191e27;
  }

  .battles-create a.link-back:hover {
    color: #ffffff;
  }

  .battles-create button.button-create {
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    background: #00c74d;
    box-shadow: 0px 2px 0px 0px #00732c;
  }

  .battles-create button.button-create img {
    width: 21px;
    margin: 0 10px;
  }

  .battles-create button.button-create .button-value {
    font-size: 12px;
  }

  .battles-create button.button-create .button-value span {
    font-size: 15px;
  }

  .battles-create .create-filters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .battles-create .filters-actions {
    display: flex;
  }

  .battles-create .filters-cases {
    width: calc(100% - 425px);
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 600;
    color: #596076;
  }

  .battles-create .filters-cases span {
    margin-left: 4px;
    color: #ffffff;
  }

  .battles-create button.button-type {
    height: 44px;
    margin-right: 12px;
    padding: 0 16px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #767c8b;
    background: #212732;
    box-shadow: 0px 2px 0px 0px #191e27;
  }

  .battles-create button.button-type:last-of-type {
    margin-right: 0;
  }

  .battles-create button.button-type:hover {
    color: #ffffff;
  }

  .battles-create button.button-type.button-active {
    color: #fd3b31;
    background: #252129;
    box-shadow: 0px 0px 0px 1px #59272b;
  }

  .battles-create .filters-info {
    display: flex;
  }

  .battles-create .create-cases {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    padding: 8px 8px 0 8px;
    border-radius: 8px;
    background: #1c202c;
  }

  .battles-create button.button-add {
    width: calc(20% - 6.4px);
    height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    margin-right: 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 700;
    color: #767c8b;
    background: #191e27;
    border: 1px solid #262c3a;
  }

  .battles-create button.button-add span {
    margin-top: 10px;
  }

  .battles-create button.button-add .button-inner {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #262c3a;
  }

  .battles-create button.button-add .button-inner svg {
    fill: #767c8b;
    transition: fill 0.3s ease;
  }

  .battles-create button.button-add:hover .button-inner svg {
    fill: #ffffff;
  }

  .battles-create .create-options {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 7px;
  }

  .battles-create .options-element {
    width: calc(33.33% - 8px);
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-right: 12px;
    padding: 15px 20px;
    border-radius: 8px;
    background: #1c202c;
  }

  .battles-create .options-element:nth-child(3n) {
    margin-right: 0;
  }

  .battles-create .options-element svg {
    width: 28px;
    height: 28px;
    margin-right: 20px;
  }

  .battles-create .options-element.element-crazy svg {
    fill: #fd3b31;
  }

  .battles-create .options-element.element-funding svg {
    fill: #00c74d;
  }

  .battles-create .options-element.element-private svg {
    fill: #67c4f8;
  }

  .battles-create .element-text {
    width: calc(100% - 102px);
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 700;
    color: #767c8b;
  }

  .battles-create .element-text span {
    font-size: 15px;
    color: #ffffff;
  }

  .battles-create button.button-toggle {
    width: 44px;
    height: 24px;
    position: relative;
    border-radius: 34px;
    background: rgba(253, 59, 49, 0.4);
  }

  .battles-create button.button-toggle.button-active {
    background: rgba(0, 199, 77, 0.4);
  }

  .battles-create button.button-toggle .toggle-slider {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 3px;
    bottom: 3px;
    border-radius: 50%;
    background: #fd3b31;
    transition: all 0.3s ease;
  }

  .battles-create button.button-toggle.button-active .toggle-slider {
    transform: translateX(20px);
    background: #00c74d;
  }

  .battles-create .create-funding {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 15px 20px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    background: #1c202c;
  }

  .battles-create .funding-content {
    display: flex;
    align-items: center;
    font-size: 13px;
  }

  .battles-create .funding-content input {
    width: 350px;
    height: 6px;
    margin-left: 10px;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-apperance: none;
    background: #191e27;
    border: 1px solid #262c3a;
  }

  .battles-create .funding-content input::-webkit-slider-thumb {
    width: 16px;
    height: 16px;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 100%;
    background: #00c74d;
    cursor: pointer;
  }

  .battles-create .funding-content input::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #00c74d;
    cursor: pointer;
  }

  @media only screen and (max-width: 1100px) {

    .battles-create .options-element {
      width: calc(50% - 6px);   
    }

    .battles-create .options-element:nth-child(3n) {
      margin-right: 12px;
    }

    .battles-create .options-element:nth-child(2n) {
      margin-right: 0;
    }

  }

  @media only screen and (max-width: 950px) {

    .battles-create button.button-add {
      width: calc(25% - 6px);       
    }

    .battles-create .options-element {
      width: 100%; 
      margin-right: 0!important;  
    }

  }

  @media only screen and (max-width: 750px) {

    .battles-create button.button-add {
      width: calc(33.33% - 5.33px);       
    }

  }

  @media only screen and (max-width: 600px) {

    .battles-create .filters-cases {
      width: auto;
    }

    .battles-create button.button-add {
      width: calc(50% - 4px);       
    }

  }

  @media only screen and (max-width: 500px) {

    .battles-create .create-header {
      flex-direction: column;
    }

    .battles-create a.link-back,
    .battles-create button.button-create {
      width: 100%;
      justify-content: center;
    }

    .battles-create button.button-create {
      margin-top: 8px;
    }

  }
</style>