const state = {
    soundVolume: localStorage.getItem('soundVolume') === null ? 0.7 : Number(localStorage.getItem('soundVolume')),
    soundBlackjack: localStorage.getItem('soundBlackjack') === null ? 1 : Number(localStorage.getItem('soundBlackjack')),
    soundMines: localStorage.getItem('soundMines') === null ? 1 : Number(localStorage.getItem('soundMines')),
    soundTowers: localStorage.getItem('soundTowers') === null ? 1 : Number(localStorage.getItem('soundTowers')),
    soundPlinko: localStorage.getItem('soundPlinko') === null ? 1 : Number(localStorage.getItem('soundPlinko')),
    soundUpgrader: localStorage.getItem('soundUpgrader') === null ? 1 : Number(localStorage.getItem('soundUpgrader')),
    soundToggle: new Audio(require('@/assets/sounds/toggle.mp3')),
    soundCash: new Audio(require('@/assets/sounds/cash.mp3')),
    soundExplosion: new Audio(require('@/assets/sounds/mines_explosion.mp3')),
    soundTick: new Audio(require('@/assets/sounds/tick.mp3')),
    soundBlackjackDeal: new Audio(require('@/assets/sounds/blackjack_deal.mp3')),
    soundBlackjackFlip: new Audio(require('@/assets/sounds/blackjack_flip.mp3')),
    soundRouletteStart: new Audio(require('@/assets/sounds/roulette_start.mp3')),
    soundRouletteEnd: new Audio(require('@/assets/sounds/roulette_end.mp3'))
}

const getters = {
    soundVolume: state => state.soundVolume,
    soundBlackjack: state => state.soundBlackjack,
    soundMines: state => state.soundMines,
    soundTowers: state => state.soundTowers,
    soundPlinko: state => state.soundPlinko,
    soundUpgrader: state => state.soundUpgrader,
    soundToggle: state => state.soundToggle,
    soundCash: state => state.soundCash,
    soundExplosion: state => state.soundExplosion,
    soundTick: state => state.soundTick,
    soundBlackjackDeal: state => state.soundBlackjackDeal,
    soundBlackjackFlip: state => state.soundBlackjackFlip,
    soundRouletteStart: state => state.soundRouletteStart,
    soundRouletteEnd: state => state.soundRouletteEnd
}

const mutations = {
    sound_set_volume(state, value) {
        state.soundVolume = value;
    },
    sound_set_blackjack(state, value) {
        state.soundBlackjack = value;
    },
    sound_set_mines(state, value) {
        state.soundMines = value;
    },
    sound_set_towers(state, value) {
        state.soundTowers = value;
    },
    sound_set_plinko(state, value) {
        state.soundPlinko = value;
    },
    sound_set_upgrader(state, value) {
        state.soundUpgrader = value;
    }
}

const actions = {
    soundSetVolume({ commit }, value) {
        localStorage.setItem('soundVolume', value);
        commit('sound_set_volume', value);
    },
    soundSetBlackjack({ getters, commit }, value) {
        commit('sound_set_blackjack', value);
        localStorage.setItem('soundBlackjack', value);

        if(value === 1) {
            getters.soundToggle.volume = getters.soundVolume;
            getters.soundToggle.play();
        }
    },
    soundSetMines({ getters, commit }, value) {
        commit('sound_set_mines', value);
        localStorage.setItem('soundMines', value);

        if(value === 1) {
            getters.soundToggle.volume = getters.soundVolume;
            getters.soundToggle.play();
        }
    },
    soundSetTowers({ getters, commit }, value) {
        commit('sound_set_towers', value);
        localStorage.setItem('soundTowers', value);

        if(value === 1) {
            getters.soundToggle.volume = getters.soundVolume;
            getters.soundToggle.play();
        }
    },
    soundSetPlinko({ getters, commit }, value) {
        commit('sound_set_plinko', value);
        localStorage.setItem('soundPlinko', value);

        if(value === 1) {
            getters.soundToggle.volume = getters.soundVolume;
            getters.soundToggle.play();
        }
    },
    soundSetUpgrader({ getters, commit }, value) {
        commit('sound_set_upgrader', value);
        localStorage.setItem('soundUpgrader', value);

        if(value === 1) {
            getters.soundToggle.volume = getters.soundVolume;
            getters.soundToggle.play();
        }
    }
}

const sound = {
    state,
    mutations,
    actions,
    getters
}

export default sound;
