<template>
    <div class="terms">
        <div class="terms-header">
            <div class="header-title">TERMS OF SERVICE</div>
            <div class="header-support">
                Cant find anything related to you problem?
                <router-link to="/support">Contact Support</router-link>
            </div>
        </div>
        <div class="terms-content">

            <div class="content-section">
                <div class="section-text">By using or accessing RustyRocket you acknowledge and accept all Terms of Service in full and without reservation. You are responsible for the compliance of all applicable laws.<br />
                    <br />
                    No individual under the age of eighteen (18) may use RustyRocket, regardless of any consent from a parent or guardian.<br />
                    <br />
                    A supported Web browser is required to access RustyRocket. You acknowledge that RustyRocket may cease to support a given Web browser and your continuous use of the website requires that you download a supported browser. You also acknowledge and agree that the performance of RustyRocket is incumbent on the performance of your computer equipment and your Internet connection.<br />
                    <br />
                    You agree to sign on and register for RustyRocket through your Steam account provided by Valve Corporation. You are solely responsible for managing your account and password and for keeping your password confidential. You are also solely responsible for restricting access to your account.
                    You agree that you are responsible for all activities that occur on your account or through the use of your password by yourself or by other persons.<br />
                    <br />
                    As a condition to your use of RustyRocket, you agree not to:<br />
                    (a) impersonate or misrepresent your affiliation with any person or entity;<br />
                    (b) access, tamper with, or use any non-public areas of RustyRocket or RustyRocket’s computer systems;<br />
                    (c) attempt to probe, scan, or test the vulnerability of RustyRocket or any related system or network or breach any security or authentication measures used in connection with RustyRocket and such systems and networks;
                    (d) attempt to decipher, decompile, disassemble, reverse engineer or otherwise investigate any of the software or components used to provide RustyRocket;<br />
                    (e) harm or threaten to harm other users in any way or interfere with, or attempt to interfere with, the access of any user, host or network, including without limitation, by sending a virus, overloading, flooding, spamming, or mail-bombing RustyRocket;<br />
                    (f) provide payment information belonging to a third party;<br />
                    (g) use RustyRocket in an abusive way contrary to its intended use, to RustyRocket policies and instructions and to any applicable law;<br />
                    (h) systematically retrieve data or other content from RustyRocket to create or compile, directly or indirectly, in single or multiple downloads, a collection, compilation, database, directory or the like, whether by manual methods, through the use of bots, crawlers, or spiders, or otherwise;<br />
                    (i) infringe third party intellectual property rights when using or accessing RustyRocket, including but not limited to in making available virtual items by using RustyRocket; and (j) make use of, promote, link to or provide access to materials deemed by RustyRocket at its sole discretion to be offensive or cause harm to RustyRocket’s reputation, including, but not limited to, illegal content and pornographic content and content deemed offensive or injurious to RustyRocket (such as Warez sites, IRC bots and bittorent sites).<br />
                    (k) the use of the RustyRocket onsite shop to trade skins for other skins without gambling.<br />
                </div>
            </div>

            <div class="content-section">
                <div class="section-title">Item Pricing</div>
                <div class="section-text">Item prices on RustyRocket do not represent any real world value. RustyRocket is for entertainment purposes only and does not provide the opportunity to win real money.</div>
            </div>

            <div class="content-section">
                <div class="section-title">Termination</div>
                <div class="section-text">We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms of Service.
                    All provisions of the Terms of Service which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                    Upon termination, your right to use RustyRocket will immediately cease. If you wish to terminate your account, you may simply do so by discontinuing your use of RustyRocket.</div>
            </div>

            <div class="content-section">
                <div class="section-title">Games</div>
                <div class="section-text">Some games may not pay out exact multipliers as values must be rounded down to the second decimal place.</div>
            </div>

            <div class="content-section">
                <div class="section-title">Affiliation</div>
                <div class="section-text">We are in NO WAY affiliated with or endorsed by the FacePunch, Rust, Steam or any other trademarks of the Valve corporation.</div>
            </div>

            <div class="content-section">
                <div class="section-title">RustyRocket Wallet</div>
                <div class="section-text">By topping up an amount to your RustyRocket  wallet, you accept that the credits received (labeled by the golden coin symbol) are not worth real money and not subject to any refunds.</div>
            </div>

            <div class="content-section">
                <div class="section-title">Returns and Refunds</div>
                <div class="section-text">We do not issue refunds for digital products once the order is confirmed and the product is sent. We recommend contacting support for assistance if you experience any issues receiving or downloading our products. If you have missing winnings/deposits, create a support ticket with the required evidence for assistance. We suggest reading the FAQ before contacting us. DO NOT use social media as support.</div>
            </div>

            <div class="content-section">
                <div class="section-title">UI/Interface Errors </div>
                <div class="section-text">Interface glitches/manipulation in no way entitles the user to a certain item. All outcomes are generated by our provably fair system and awarded as such. The user agrees that the outcome will be determined by the provably fair system outcome and any user interface errors do not entitle the user to that item.</div>
            </div>

            <div class="content-section">
                <div class="section-title">Multiple Accounts</div>
                <div class="section-text">The use of multiple accounts on RustyRocket is prohibited. If found out it can lead to a permanent ban of all accounts belonging to one person.</div>
            </div>

            <div class="content-section">
                <div class="section-title">Credit Card Deposit Terms</div>
                <div class="section-text">By depositing with a credit card, you acknowledge the fact that refunds are only given out for technical issues, which we have evidence of via our logs, or if a customer mistakenly deposits. For mistaken deposits, we can not refund after any of the balance is spent. These are the only reasons refunds are given out.<br />
                <br />
                In case of a chargeback or dispute, the account/accounts associated with the card number will be permanently banned from our service. Any further accounts used with this card will also be banned.</div>
            </div>

            <div class="content-section">
                <div class="section-title">No Warranties</div>
                <div class="section-text">This website is provided “as is” without any representations or warranties, express or implied. www.rustyrocket.gg makes no representations or warranties in relation to this website or the information and materials provided on this website. Without prejudice to the generality of the foregoing paragraph, www.rustyrocket.gg does not warrant that: this website will be constantly available, or available at all; or the information on this website is complete, true, accurate or non-misleading. Nothing on this website constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any legal, financial or medical matter you should consult an appropriate professional.</div>
            </div>

            <div class="content-section">
                <div class="section-title">Links To Other Websites</div>
                <div class="section-text">Our Service may contain links to third-party web sites or services that are not owned or controlled by RustyRocket.
                RustyRocket has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that RustyRocket shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
                We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</div>
            </div>

            <div class="content-section">
                <div class="section-title">Privacy Policy</div>
                <div class="section-text">By using the Site you acknowledge and consent to the Service provider processing your personal data for the purposes of allowing access and usage of the Site and in order to allow participating in activities provided at the Site. Your personal data will be used only to allow you to participate in the activities provided at the Site and for the purposes of carrying out verification procedures in relation to activities at the Site. The only personally identifiable information the Site collects is your Trade URL and Steam ID. Your personal data will not be disclosed to any third parties, unless such disclosure is necessary for processing your requests, to carry verification procedures or unless it is required by law. You have the right to access personal data held by the Service provider about you. You hereby consent and undertake to immediately inform the Service provider about changes of the personal data or other provided information. The Service provider collects a small piece of information sent from the browser (cookies) which might be turned off. However, turning off cookies may restrict the use of the Site. By accepting the Terms, you consent to the Service provider informing you from time to time about changes on the Site, new services and promotions.</div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'Terms',
        metaInfo: {
            title: 'Terms - RustyRocket.GG'
        }
    }
</script>

<style scoped>
    .terms {
        width: 100%;
        min-height: calc(100vh - 112px);
        padding: 40px;
    }

    .terms .terms-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;
        border-bottom: 1px solid #20252D;
    }

    .terms .header-title {
        font-size: 24px;
        font-weight: 700;
        color: #596076;
    }

    .terms .header-support {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        color: #596076;
    }

    .terms .header-support a {
        margin-left: 5px;
        font-size: 14px;
        font-weight: 600;
        color: #fd3b31;
    }

    .terms .terms-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
    }

    .terms .content-section {
        width: 100%;
        margin-top: 24px;
    }

    .terms .content-section:first-of-type {
        margin-top: 0;
    }

    .terms .section-title {
        font-size: 18px;
        font-weight: 600;
        color: #596076;
    }

    .terms .section-text {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
    }

    @media only screen and (max-width: 1650px) {

        .terms {
            padding: 40px 15px 40px 15px;
        }

    }

    @media only screen and (max-width: 1000px) {

        .terms .terms-header {
            flex-direction: column;
            align-items: flex-start;
        }

        .terms .header-support {
            margin-top: 12px;
        }

    }

    @media only screen and (max-width: 475px) {

        .terms .header-support {
            flex-direction: column;
        }

        .terms .header-support a {
            margin-left: 0;
        }

    }
</style>
