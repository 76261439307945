export default {
    methods: {
        blackjackGetCardValue(cards) {
            let value = 0;
            let aces = false;

            for(const card of cards) {
                if(card.rank === 'A') {
                    value = value + 1;
                    aces = true;
                } else if(card.rank == 'K' || card.rank == 'Q' || card.rank == 'J') {
                    value = value + 10;
                } else if(card.rank != 'hidden') {
                    value = value + Math.floor(card.rank);
                }
            }

            if(aces == true && value <= 11) {
                value = value + 10;
            }

            return value;
        }
    }
};