<template>
    <a class="link-auth" v-bind:href="authBackendUrl + '/api/auth/steam'">
        <IconSteam />
        LOG IN WITH STEAM
    </a>
</template>

<script>
    import IconSteam from '@/components/icons/IconSteam';

    export default {
        name: 'AuthButton',
        components: {
            IconSteam
        },
        data() {
            return {
                authBackendUrl: process.env.VUE_APP_BACKEND_URL
            }
        }
    }
</script>

<style scoped>
    a.link-auth {
        height: 41px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 0 16px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    a.link-auth:hover {
        background: #00de56;
    }

    a.link-auth svg {
        margin-right: 8px;
        fill: #ffffff;
    }
</style>
