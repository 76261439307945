<template>
    <div class="fair-overview">
        <div class="overview-info">
            <div class="info-title">How it works</div>
            <div class="info-text">
                <p>The game results have been generated before you even place your bet, and most importantly, we can prove it.</p>
                <p>Before each game, we actually give you the game result in a hashed format.</p>
                <p>Before you start the game, we give you the hash of the server seed and the public seed which the round result is based on. After the game is finished, we publish the server seed, which you can then compare to the hash to make sure that they match. With this system we can prove that the results were fair and pre-generated.</p>
            </div>
        </div>
        <FairSeeds />
    </div>
</template>

<script>
    import FairSeeds from '@/components/fair/FairSeeds';

    export default {
        name: 'FairOverview',
        components: {
            FairSeeds
        }
    }
</script>

<style scoped>
    .fair-overview {
        width: 100%;
        display: flex;
    }

    .fair .overview-info {
        width: calc(100% - 600px);
        padding-right: 75px;
    }

    .fair-overview .info-title {
        font-size: 18px;
        font-weight: 600;
        color: #596076;
    }

    .fair-overview .info-text {
        width: 100%;
        margin-top: 8px;
    }

    .fair-overview .info-text p {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
    }

    @media only screen and (max-width: 1200px) {

        .fair-overview {
            flex-direction: column;
        }

        .fair-overview .overview-info {
            width: 100%;
            padding-right: 0;
        }

    }
</style>
