<template>
    <div class="towers">
        <div class="towers-container">
            <div class="container-actions">
                <div class="actions-controls">
                    <div class="controls-input">
                        <div class="input-title">PLACE YOUR BET</div>
                        <div class="input-content">
                            <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                            <input v-model="towersAmount" v-on:input="towersAmount = generalFormatInputAmount(towersAmount)" v-bind:disabled="towersIsGameRunning === true" type="text" />
                            <div class="content-buttons">
                                <button v-on:click="towersSetAmount('1/2')" v-bind:disabled="towersIsGameRunning === true">1/2</button>
                                <button v-on:click="towersSetAmount('x2')" v-bind:disabled="towersIsGameRunning === true">x2</button>
                                <button v-on:click="towersSetAmount('max')" v-bind:disabled="towersIsGameRunning === true">MAX</button>
                            </div>
                        </div>
                    </div>
                    <div class="controls-risk">
                        <button v-on:click="towersSetRisk('easy')" v-bind:class="{ 'button-active': towersRisk === 'easy' }" v-bind:disabled="towersIsGameRunning === true">EASY</button>
                        <button v-on:click="towersSetRisk('medium')" v-bind:class="{ 'button-active': towersRisk === 'medium' }" v-bind:disabled="towersIsGameRunning === true">MEDIUM</button>
                        <button v-on:click="towersSetRisk('hard')" v-bind:class="{ 'button-active': towersRisk === 'hard' }" v-bind:disabled="towersIsGameRunning === true">HARD</button>
                    </div>
                    <div class="controls-bet">
                        <button v-if="towersIsGameRunning === false" v-on:click="towersCreateButton()" class="button-create" v-bind:disabled="socketSendLoading !== null">PLACE BET</button>
                        <button v-else v-on:click="towersCashoutButton()" class="button-cashout" v-bind:disabled="socketSendLoading !== null">
                            CASHOUT
                            <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                            <div class="button-amount">
                                <span>{{generalFormatAmount(towersGetCashoutAmount).split('.')[0]}}</span>.{{generalFormatAmount(towersGetCashoutAmount).split('.')[1]}}
                            </div>
                        </button>
                    </div>
                </div>
                <div class="actions-info">
                    <router-link to="/fair/towers" class="link-fair">
                        <IconFairness />
                        FAIRNESS
                    </router-link>
                    <button v-on:click="towersMuteButton()" class="button-sound">
                        <IconMute v-if="soundTowers === 1" />
                        <IconUnmute v-else />
                        {{ soundTowers === 1 ? 'MUTE' : 'UNMUTE' }}

                    </button>
                </div>
            </div>

            <TowersGame v-bind:amount="towersAmount" v-bind:risk="towersRisk" />
        </div>

        <Bets />
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';
    import IconFairness from '@/components/icons/IconFairness';
    import IconMute from '@/components/icons/IconMute';
    import IconUnmute from '@/components/icons/IconUnmute';
    import Bets from '@/components/bets/Bets';
    import TowersGame from '@/components/towers/TowersGame';

    export default {
        name: 'Towers',
        metaInfo: {
            title: 'Towers - RustyRocket.GG'
        },
        components: {
            IconFairness,
            IconMute,
            IconUnmute,
            TowersGame,
            Bets
        },
        mixins: [
            mixins
        ],
        data() {
            return {
                towersAmount: '0.00',
                towersRisk: 'easy'
            }
        },
        methods: {
            ...mapActions([
                'notificationShow', 
                'towersGetDataSocket',
                'towersSendCreateSocket', 
                'towersSendCashoutSocket', 
                'towersClearGame', 
                'soundSetTowers'
            ]),
            towersSetAmount(action) {
                let amount = Math.floor(this.towersAmount * 1000);

                if(action === '1/2') {
                    amount = Math.floor(amount / 2);
                } else if(action === 'x2') {
                    amount = Math.floor(amount * 2);
                } else if(action === 'max') {
                    amount = this.authUser.user.balance;
                }

                amount = amount >= this.authUser.user.balance ? this.authUser.user.balance : amount;
                this.towersAmount = (Math.floor(amount / 10) / 100).toFixed(2);
            },
            towersSetRisk(risk) {
                this.towersRisk = risk;

                if(this.towersData.game !== null && this.towersData.game.state === 'completed') {
                    this.towersClearGame();
                }
            },
            towersCreateButton() {
                if(this.authUser.user === null) {
                    this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                    return;
                }

                if(isNaN(this.towersAmount) === true || this.towersAmount < 0.1) {
                    this.notificationShow({type: 'error', message: 'Your provided bet amount is invalid.'});
                    return;
                }

                this.towersSendCreateSocket({ amount: Math.floor(this.towersAmount * 1000), risk: this.towersRisk });
            },
            towersCashoutButton() {
                this.towersSendCashoutSocket({});
            },
            towersMuteButton() {
                if(this.soundTowers === 1) {
                    this.soundSetTowers(0);
                } else {
                    this.soundSetTowers(1);
                }
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'authUser', 
                'towersData', 
                'soundTowers'
            ]),
            towersIsGameRunning() {
                return this.towersData.game !== null && this.towersData.game.state !== 'completed';
            },
            towersGetCashoutAmount() {
                return Math.floor(this.towersData.game.amount * Math.pow(this.towersGetMultiplierRow(this.towersData.game.risk), this.towersData.game.revealed.length));
            }
        },
        created() {
            if(this.authUser.user !== null) {this.towersGetDataSocket({}); }
        }
    }
</script>

<style scoped>
    .towers {
        width: 1300px;
        min-height: calc(100vh - 112px);
        padding: 70px 0 0 0;
    }

    .towers .towers-container {
        width: 100%;
        display: flex;
    }

    .towers .container-actions {
        width: 380px;
        height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px 24px;
        border-radius: 18px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .towers .actions-controls {
        width: 100%;
    }

    .towers .controls-input {
        width: 100%;
    }

    .towers .input-title {
        width: 100%;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .towers .input-content {
        width: 100%;
        position: relative;
        margin-top: 12px;
        border-radius: 8px;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .towers .input-content img {
        width: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
    }

    .towers .input-content input {
        width: 100%;
        height: 58px;
        padding: 0 160px 0 54px;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .towers .content-buttons {
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
    }

    .towers .content-buttons button {
        margin: 0 15px;
        font-size: 14px;
        font-weight: 700;
        color: #626c7e;
    }

    .towers .content-buttons button:not([disabled]):hover {
        color: #ffffff;
    }

    .towers .controls-risk {
        width: 100%;
        display: flex;
        margin-top: 18px;
    }

    .towers .controls-risk button{
        width: calc(33.33% - 10px);
        height: 39px;
        margin-right: 15px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #1d222a;
    }

    .towers .controls-risk button:last-of-type {
        margin-right: 0;
    }

    .towers .controls-risk button.button-active {
        color: #fd3b31;
        background: #252129;
        box-shadow: 0px 0px 0px 1px #59272b;
    }

    .towers .controls-bet {
        width: 100%;
        margin-top: 32px;
    }

    .towers .controls-bet button.button-create,
    .towers .controls-bet button.button-cashout {
        width: 100%;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
    }

    .towers .controls-bet button.button-create {
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .towers .controls-bet button.button-cashout {
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    .towers .controls-bet button.button-create:not([disabled]):hover {
        background: #00de56;
    }

    .towers .controls-bet button.button-cashout:not([disabled]):hover {
        background: #fe524a;
    }

    .towers .controls-bet button.button-cashout img {
        width: 21px;
        margin-left: 12px;
    }

    .towers .controls-bet button.button-cashout .button-amount {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
    }

    .towers .controls-bet button.button-cashout .button-amount span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .towers .actions-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
    }

    .towers .actions-info a.link-fair,
    .towers .actions-info button.button-sound {
        width: calc(50% - 8px);
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        border-bottom: 2px solid #1d222a;
    }

    .towers .actions-info a.link-fair:hover,
    .towers .actions-info button.button-sound:hover {
        color: #ffffff;
    }

    .towers .actions-info a.link-fair svg,
    .towers .actions-info button.button-sound svg {
        width: 16px;
        margin-right: 8px;
        fill: #767c8b;
        transition: fill 0.3s ease;
    }

    .towers .actions-info a.link-fair:hover svg,
    .towers .actions-info button.button-sound:hover svg {
        fill: #ffffff;
    }

    @media only screen and (max-width: 1330px) {

        .towers {
            width: 100%;
            padding: 20px 10px;
        }

    }

    @media only screen and (max-width: 1100px) {

        .towers .towers-container {
            flex-direction: column-reverse;
        }

        .towers .container-actions {
            width: 100%;
            height: auto;
            margin-top: 15px;
        }

    }
</style>
