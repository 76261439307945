<template>
    <div class="modals">
        <transition name="slide-fade">
            <div v-if="modalsShow !== null" v-on:click="modalsCloseButton" class="modals-inner">
                <div class="inner-holder">
                    <component v-bind:is="'Modal' + modalsCurrent" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ModalAgree from '@/components/modals/ModalAgree';
    import ModalCaptcha from '@/components/modals/ModalCaptcha';
    import ModalFairInfo from '@/components/modals/ModalFairInfo';
    import ModalVerify from '@/components/modals/ModalVerify';
    import ModalAffiliates from '@/components/modals/ModalAffiliates';
    import ModalRedeem from '@/components/modals/ModalRedeem';
    import ModalChatRules from '@/components/modals/ModalChatRules';
    import ModalChatUser from '@/components/modals/ModalChatUser';
    import ModalTip from '@/components/modals/ModalTip';
    import ModalMute from '@/components/modals/ModalMute';
    import ModalBan from '@/components/modals/ModalBan';
    import ModalRemove from '@/components/modals/ModalRemove';
    import ModalBattlesSelect from '@/components/modals/ModalBattlesSelect';
    import ModalBoxInfo from '@/components/modals/ModalBoxInfo';

    export default {
        components: {
            ModalAgree,
            ModalCaptcha,
            ModalFairInfo,
            ModalVerify,
            ModalAffiliates,
            ModalRedeem,
            ModalChatRules,
            ModalChatUser,
            ModalTip,
            ModalMute,
            ModalBan,
            ModalRemove,
            ModalBattlesSelect,
            ModalBoxInfo
        },
        data() {
            return {
                modalsCurrent: null
            }
        },
        methods: {
            ...mapActions([
                'modalsSetShow'
            ]),
            modalsCloseButton(e) {
                if(e.target.className === 'modals-inner' && this.modalsShow !== 'UpgraderWin') {
                    this.modalsSetShow(null);
                }
            }
        },
        computed: {
            ...mapGetters([
                'modalsShow'
            ])
        },
        watch: {
            'modalsShow': {
                handler() {
                    if(this.modalsShow !== null) { this.modalsCurrent = this.modalsShow; }
                }
            }
        },
        created() {
            if(this.modalsShow !== null) { this.modalsCurrent = this.modalsShow; }
        }
    }
</script>

<style scoped>
    .modals {
        position: relative;
    }

    .modals .modals-inner {
        width: 100%;
        height: 100%;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        top: 0;
        left: 0;
        padding: 100px 10px;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        scroll-behavior: smooth;
        user-select: none;
        background: rgba(20, 25, 37, 0.82);
        z-index: 51;
    }

    .modals .modals-inner::-webkit-scrollbar-track {
        background: transparent;
    }

    .modals .modals-inner::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .modals .modals-inner.slide-fade-enter-active,
    .modals .modals-inner.slide-fade-leave-active {
        transition: opacity 0.3s ease;
    }

    .modals .modals-inner.slide-fade-enter-active .inner-holder {
        transition: all 0.3s ease;
    }

    .modals .modals-inner.slide-fade-enter,
    .modals .modals-inner.slide-fade-leave-to {
        opacity: 0;
    }

    .modals .modals-inner.slide-fade-enter .inner-holder {
        transform: translate(0, -50px);
    }
</style>
