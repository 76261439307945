const state = {
    giftDepositData: {
        transactions: null,
        loading: false
    }
}

const getters = {
    giftDepositData: state => state.giftDepositData
}

const mutations = {
    gift_set_deposit_data_transactions(state, transactions) {
        state.giftDepositData.transactions = transactions;
    },
    gift_add_deposit_transaction(state, transaction) {
        state.giftDepositData.transactions.unshift(transaction);
        state.giftDepositData.transactions.sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt); });
    },
    gift_remove_deposit_transaction_last(state) {
        state.giftDepositData.transactions.pop();
    },
    gift_set_deposit_data_loading(state, status) {
        state.giftDepositData.loading = status;
    }
}

const actions = {
    giftGetDepositDataSocket({ getters, commit, dispatch }, data) {
        if(getters.giftDepositData.loading === true) { return; }
        commit('gift_set_deposit_data_loading', true);

        getters.socketClient.emit('cashier:gift:getDepositData', data, (res) => {
            if(res.success === true) {
                commit('gift_set_deposit_data_transactions', res.transactions);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('gift_set_deposit_data_loading', false);
        });
    },
    giftSendClaimSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'GiftClaim');

        getters.socketClient.emit('cashier:gift:sendClaim', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                dispatch('notificationShow', { type: 'success', message: 'You have successfully claimed a gift code. We have added ' + parseFloat(res.transaction.amount / 1000).toFixed(2) + ' to your balance.' });
                
                if(getters.giftDepositData.transactions !== null) {
                    commit('gift_add_deposit_transaction', res.transaction);
                    if(getters.giftDepositData.transactions.length >= 15) { commit('gift_remove_deposit_transaction_last'); }
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const gift = {
    state,
    mutations,
    actions,
    getters
}

export default gift;
