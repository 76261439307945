<template>
    <div class="cases">
        <transition name="slide-fade" mode="out-in">
            <router-view/>
        </transition>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'Cases',
        metaInfo: {
            title: 'Cases - RustyRocket.GG'
        },
        methods: {
            ...mapActions([
                'casesGetDataSocket'
            ])
        },
        created() {
            this.casesGetDataSocket({});
        }
    }
</script>

<style scoped>
    .cases {
        width: 1300px;
        min-height: calc(100vh - 112px);
        padding: 70px 0;
    }

    .cases .slide-fade-enter-active {
        transition: all .3s ease-out;
    }

    .cases .slide-fade-enter {
        opacity: 0;
    }

    @media only screen and (max-width: 1650px) {

        .cases {
            width: 100%;
            padding: 70px 10px;
        }

    }
</style>