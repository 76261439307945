const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
    rakebackSendRakebackClaimSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'RakebackClaim');

        getters.socketClient.emit('general:rakeback:sendClaim', data, (res) => {
            if(res.success === true) {
                dispatch('notificationShow', { type: 'success', message: 'You have successfully claimed your rakeback earnings.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const rakeback = {
    state,
    mutations,
    actions,
    getters
}

export default rakeback;