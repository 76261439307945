<template>
    <div class="amount-number" :style="{
        'width': (Math.abs(amountCurrent) < 1000 ? 27 : Math.floor(amountCurrent / 10).toString().length * 9) + 'px'
    }">
        <span>{{generalFormatAmount(amountCurrent).split('.')[0]}}</span>.{{generalFormatAmount(amountCurrent).split('.')[1]}}
    </div>
</template>

<script>
    import mixins from '@/mixins';

    export default {
        name: 'AmountNumber',
        mixins: [
            mixins
        ],
        props: {
            amount: Number
        },
        data() {
            return {
                amountInterval: null,
                amountCurrent: 0
            }
        },
        watch: {
            'amount': {
                handler() {
                    clearInterval(this.amountInterval);

                    const step = Math.floor(this.amount - this.amountCurrent) / 30;
                    this.amountInterval = setInterval(() => { 
                        this.amountCurrent = Math.floor(this.amountCurrent + step);

                        if((step >= 0 && this.amountCurrent >= this.amount) || (step <= 0 && this.amountCurrent <= this.amount)) {
                            clearInterval(this.amountInterval); 
                            this.amountCurrent = this.amount;
                        }
                     }, 500 / 30);
                }
            }
        },
        mounted() {
            this.amountCurrent = this.amount;
        },
        beforeDestroy() {
            clearInterval(this.amountInterval);
        }
    }
</script>