<template>
    <aside id="chat">
        <div class="chat-toggle">
            <button v-on:click="chatSetSidebar(true)">
                <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.214,0H2.786A2.786,2.786,0,0,0,0,2.788V17.645a2.786,2.786,0,0,0,2.786,2.786h3.3l-.505,4.541a.929.929,0,0,0,1.544.792l5.926-5.333H23.214A2.786,2.786,0,0,0,26,17.645V2.788A2.786,2.786,0,0,0,23.214,0Z" />
                </svg>
            </button>
        </div>
        <div class="chat-header">
            <div class="header-online">
                <div class="online-dot">
                    <div class="dot-wave"></div>
                </div>
                {{generalOnline}} Online
            </div>
            <div class="header-socials">
                <a class="socials-twitter" href="https://twitter.com/RustyRocketgg" target="_blank">
                    <svg width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 1.69446C15.405 1.97358 14.771 2.1586 14.11 2.24843C14.79 1.81424 15.309 1.13193 15.553 0.309528C14.919 0.713778 14.219 0.99932 13.473 1.15867C12.871 0.473154 12.013 0.048584 11.077 0.048584C9.261 0.048584 7.799 1.62495 7.799 3.55743C7.799 3.83549 7.821 4.10285 7.875 4.35738C5.148 4.21514 2.735 2.81738 1.114 0.688112C0.831 1.21321 0.665 1.81424 0.665 2.46125C0.665 3.67614 1.25 4.75307 2.122 5.37656C1.595 5.36586 1.078 5.20224 0.64 4.9445C0.64 4.95519 0.64 4.9691 0.64 4.983C0.64 6.68769 1.777 8.10364 3.268 8.42982C3.001 8.50789 2.71 8.54532 2.408 8.54532C2.198 8.54532 1.986 8.53249 1.787 8.48543C2.212 9.87464 3.418 10.896 4.852 10.9291C3.736 11.8627 2.319 12.4253 0.785 12.4253C0.516 12.4253 0.258 12.4124 0 12.3771C1.453 13.3792 3.175 13.9514 5.032 13.9514C11.068 13.9514 14.368 8.60414 14.368 3.96917C14.368 3.8141 14.363 3.66438 14.356 3.51572C15.007 3.02164 15.554 2.40457 16 1.69446Z" />
                    </svg>
                </a>
                <a class="socials-discord" href="https://discord.gg/wszdJwxXMR" target="_blank">
                    <svg width="16" height="12" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.9049 1.44856C13.9049 1.44856 12.7586 0.183144 10.1557 0V0.399561C10.1557 0.399561 11.5833 0.313087 13.3505 2.12683C12.0166 1.12621 10.1137 0.499383 8 0.499383C5.88633 0.499383 3.98342 1.12611 2.64951 2.12683C4.41668 0.313087 5.84428 0.399561 5.84428 0.399561V0C3.24144 0.183144 2.09509 1.44856 2.09509 1.44856C0.111737 4.11231 0 9.93965 0 9.93965C1.86824 12.2872 4.21561 11.9876 4.21561 11.9876L5.07786 10.689C3.96591 10.3754 3.17184 9.65529 2.69458 9.09455C4.02609 10.0758 5.90989 10.689 8 10.689C10.0901 10.689 11.9739 10.0759 13.3054 9.09455C12.8282 9.6552 12.0341 10.3754 10.9221 10.689L11.7844 11.9876C11.7844 11.9876 14.1318 12.2873 16 9.93965C16.0002 9.93956 15.8884 4.11231 13.9049 1.44856ZM5.50907 8.29135C4.75945 8.29135 4.15178 7.61818 4.15178 6.78764C4.15178 5.95719 4.75945 5.28393 5.50907 5.28393C6.2587 5.28393 6.86636 5.95719 6.86636 6.78764C6.86636 7.61809 6.2587 8.29135 5.50907 8.29135ZM10.4911 8.29135C9.74148 8.29135 9.13381 7.61818 9.13381 6.78764C9.13381 5.95719 9.74148 5.28393 10.4911 5.28393C11.2407 5.28393 11.8484 5.95719 11.8484 6.78764C11.8484 7.61809 11.2408 8.29135 10.4911 8.29135Z" />
                    </svg>
                </a>
            </div>
        </div>
        <div class="chat-room">
            <ChatRoomDropdown />
        </div>
        <div class="chat-content">
            <div v-on:scroll="chatHandleScroll" class="content-messages" ref="chatMessages">

                <ChatMessageElement v-for="message in chatGetMessages" v-bind:key="message._id" v-bind:message="message" />

            </div>
            <div class="content-info">
                <transition name="slide-fade" mode="out-in">
                    <button v-if="chatScroll === false" v-on:click="chatScrollToBottom" class="button-scroll">
                        SCROLL TO BOTTOM
                    </button>
                </transition>
            </div>
        </div>
        <div class="chat-footer">
            <div class="footer-input">
                <input v-model="chatMessage" v-on:keyup.enter="chatMessageButton()" type="text" placeholder="Type message here..." />
                <button v-on:click="chatMessageButton()">
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6874 9.0002C19.6874 9.06116 19.6696 9.12079 19.6361 9.17176C19.6027 9.22272 19.5551 9.26281 19.4991 9.28707L0.749144 17.4121C0.688818 17.4382 0.621773 17.4447 0.557552 17.4306C0.493331 17.4164 0.435208 17.3824 0.391457 17.3333C0.347706 17.2842 0.320557 17.2226 0.313874 17.1572C0.307191 17.0917 0.321316 17.0259 0.354237 16.9689L4.03861 10.5814C4.07409 10.52 4.12941 10.4724 4.19549 10.4465L7.89239 9.0002L4.19552 7.55398C4.12944 7.5281 4.07412 7.48053 4.03864 7.41907L0.354237 1.03145C0.321316 0.974522 0.307191 0.908662 0.313874 0.843243C0.320557 0.777824 0.347706 0.716181 0.391457 0.667087C0.435208 0.617994 0.493331 0.583954 0.557552 0.569812C0.621773 0.55567 0.688818 0.562148 0.749144 0.588323L19.4991 8.71332C19.5551 8.73759 19.6027 8.77767 19.6361 8.82864C19.6696 8.8796 19.6874 8.93923 19.6874 9.0002Z" />
                    </svg>
                </button>
            </div>
            <div class="footer-actions">
                <button v-on:click="modalsSetShow('ChatRules')">
                    <svg width="11" height="17" viewBox="0 0 11 17" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0H0V16.0827L5.5 12L11 16.0827V0Z" />
                    </svg>
                    CHAT RULES
                </button>
                <button v-on:click="chatSetSidebar(false)">
                    <IconClose />
                    HIDE CHAT
                </button>
            </div>
        </div>
    </aside>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconClose from '@/components/icons/IconClose';
    import ChatRoomDropdown from '@/components/chat/ChatRoomDropdown';
    import ChatMessageElement from '@/components/chat/ChatMessageElement';

    export default {
        name: 'Chat',
        components: {
            IconClose,
            ChatRoomDropdown,
            ChatMessageElement
        },
        data() {
            return {
                chatScroll: true,
                chatMessage: null
            }
        },
        methods: {
             ...mapActions([
                'chatSetSidebar', 
                'notificationShow', 
                'chatSendMessageSocket', 
                'modalsSetShow'
            ]),
             chatMessageButton() {
                if(this.authUser.user === null) {
                    this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                    return;
                }

                if(this.generalSettings.chat.enabled === false && this.authUser.user.rank !== 'admin') {
                    this.notificationShow({ type: 'error', message: 'You can not send a chat message because the action is not available at the moment.' });
                    return;
                }

                if(this.chatMessage === null || this.chatMessage.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Please enter a message.' });
                    return;
                }

                const data = { message: this.chatMessage, room: this.chatRoom };
                this.chatSendMessageSocket(data);

                this.chatMessage = '';
            },
            chatHandleScroll() {
                let container = this.$refs.chatMessages;
                if(container.scrollHeight - (container.scrollTop + container.clientHeight) >= 80) {
                    this.chatScroll = false;
                } else {
                    this.chatScroll = true;
                }
            },
            chatScrollToBottom() {
                this.$nextTick(function() {
                    let container = this.$refs.chatMessages;
                    container.scrollTop = container.scrollHeight;
                });
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading', 
                'authUser', 
                'generalSettings', 
                'generalOnline', 
                'chatSidebar',
                'chatRoom', 
                'chatMessages'
            ]),
            chatGetMessages() {
                return this.chatMessages[this.chatRoom];
            }
        },
        watch: {
            'chatMessages': {
                handler(state, oldState)  {
                    if(this.chatScroll === true || oldState[this.chatRoom].length === 0) {
                        this.chatScrollToBottom();
                    }
                },
                deep: true
            },
            'chatRoom': {
                handler(state, oldState) {
                    setTimeout(() => { this.chatScrollToBottom(); }, 100);
                }
            },
            'chatSidebar': {
                handler(state, oldState) {
                    setTimeout(() => { this.chatScrollToBottom(); }, 100);
                }
            }
        },
        created() {
            this.chatScrollToBottom();

            if(window.innerWidth <= 1700 && this.chatSidebar === true) {
                this.chatSetSidebar(false);
            }
        }
    }
</script>

<style scoped>
    aside#chat {
        width: 300px;
        height: calc(100% - 67px);
        position: fixed;
        top: 67px;
        left: 0px;
        background: #151920;
        transition: all 0.3s ease;
        z-index: 20;
    }

    .app-page.page-full aside#chat {
        left: -300px;
    }

    aside#chat .chat-toggle {
        position: absolute;
        display: none;
        right: -56px;
        bottom: 43px;
    }

    .app-page.page-full aside#chat .chat-toggle {
        display: block;
    }

    aside#chat .chat-toggle button {
        width: 56px;
        height: 56px;
        border-radius: 0 5px 5px 0;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    aside#chat .chat-toggle button svg {
        width: 23px;
        margin-top: 6px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    aside#chat .chat-toggle button:hover svg {
        fill: #ffffff;
    }

    aside#chat .chat-header {
        width: 100%;
        padding: 15px 15px 0 15px;
        background: #151820;
    }

    aside#chat .chat-header {
        width: 100%;
        height: 45px;
        padding: 0 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #191d26;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    }

    aside#chat .header-online {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        color: #00c74d;
    }

    aside#chat .online-dot {
        width: 15px;
        height: 15px;
        position: relative;
        margin-right: 6px;
    }

    aside#chat .online-dot::before {
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 50%;
        background: #00c74d;
    }

    aside#chat .dot-wave {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        animation: online-counter 2s linear forwards infinite;
    }

    aside#chat .header-socials {
        display: flex;
    }

    aside#chat .header-socials a {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }

    aside#chat .header-socials a.socials-twitter {
        background: #234965;
    }

    aside#chat .header-socials a.socials-twitter svg {
        fill: #53b5ff;
    }

    aside#chat .header-socials a.socials-discord {
        margin-left: 10px;
        background: #2f3762;
    }

    aside#chat .header-socials a.socials-discord svg {
        fill: #a4b1ff;
    }

    aside#chat .chat-room {
        width: 100%;
        padding: 15px 15px 0 15px;
        background: #151820;
    }

    aside#chat .chat-content {
        width: 100%;
        height: calc(100% - 202px);
        position: relative;
    }

    aside#chat .chat-content::before {
        content: '';
        width: 100%;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, #151820 0%, rgba(21, 24, 32, 0) 100%);
        z-index: 11;
    }

    aside#chat .content-messages {
        height: 100%;
        padding: 0 10px 12px 10px;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        scroll-behavior: smooth;
    }

    aside#chat.chat-rain .content-messages {
        height: calc(100% - 111px);
    }

    aside#chat .content-messages::-webkit-scrollbar-track {
        background: transparent;
    }

    aside#chat .content-messages::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    aside#chat .content-info {
        width: calc(100% - 40px);
        position: absolute;
        display: flex;
        justify-content: center;
        bottom: 12px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    aside#chat.chat-rain .content-info {
        bottom: 123px;
    }

    aside#chat .content-rain .slide-fade-enter,
    aside#chat .content-info .slide-fade-enter {
        opacity: 0;
    }

    aside#chat .content-rain .slide-fade-leave-to,
    aside#chat .content-info .slide-fade-leave-to {
        transform: translate(0, 10px);
        opacity: 0;
    }

    aside#chat .chat-footer {
        width: 100%;
    }

    aside#chat .content-info button.button-scroll {
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 600;
        color: #626c7e;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    aside#chat .content-info button.button-scroll:hover {
        color: #ffffff;
    }

    aside#chat .footer-input {
        width: 100%;
        height: 56px;
        background: #1a1e27;
    }

    aside#chat .footer-input {
        width: 100%;
        height: 56px;
        position: relative;
        background: #1a1e27;
    }

    aside#chat .footer-input input {
        width: 100%;
        height: 56px;
        padding: 0 56px 0 18px;
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
        background: transparent;
    }

    aside#chat .footer-input input::placeholder {
        color: #717687;
    }

    aside#chat .footer-input button {
        position: absolute;
        top: 50%;
        right: 18px;
        transform: translate(0, -50%);
        padding: 0;
        margin: 0;
    }

    aside#chat .footer-input button svg {
        fill: #00C74D
    }

    aside#chat .footer-actions {
        width: 100%;
        height: 43px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 18px;
        background: #1a1e27;
        border-top: 1px solid #151920;
    }

    aside#chat .footer-actions button {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #5f6779;
        background: transparent;
    }

    aside#chat .footer-actions button:hover {
        color: #ffffff;
    }

    aside#chat .footer-actions button svg {
        margin-right: 6px;
        fill: #5f6779;
        transition: all 0.3s ease;
    }

    aside#chat .footer-actions button:hover svg {
        fill: #ffffff;
    }

    @keyframes online-counter {
        0% {
            transform: scale(0.2, 0.2);
            background: rgba(0, 199, 77, 0.2);
        }
        50% {
            transform: scale(0.7, 0.7);
            background: rgba(0, 199, 77, 0.2);
        }
        100% {
            transform: scale(1.2, 1.2);
            background: rgba(0, 199, 77, 0);
        }
    }

    @media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {

        aside#chat .footer-input input {
            font-size: 16px;
        }

    }

    @media only screen and (max-width: 300px) {

        aside#chat {
            width: 100%;
        }

        .app-page.page-full aside#chat {
            width: 300px;
        }

    }
</style>
